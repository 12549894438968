import { ResponseWrapper } from 'entities/types/ResponseWrapper'
import { toast } from 'react-toastify'

export function showToast(response: ResponseWrapper) {
  switch (response.responseType) {
    case 'Success':
      toast.success(response.message)
      break
    case 'Error':
      toast.error(response.message)
      break
    case 'Warning':
      toast.warn(response.message)
      break
    case 'Information':
      toast.info(response.message)
      break
  }
}
