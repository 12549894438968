import { InformationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { states } from 'assets/states'
import { Button } from 'components/atoms/Button/Button'
import Select from 'components/atoms/Select/Select'
import TextInput from 'components/atoms/TextInput/TextInput'
import { Modal } from 'components/molecules'
import { ContactUpdateRequest } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NewContactFormValidator } from 'validators/contactFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const EditContactModal = () => {
  const { currentModal, personTypes, regions, selectedLawyer } = useAppState()
  const { displayModalAction, updateContactAction } = useActions()
  const [savingContact, setSavingContact] = useState<boolean>(false)

  const { formState: { errors }, handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      ...selectedLawyer,
      personTypeId: selectedLawyer?.personType?.id,
      regionId: selectedLawyer?.region?.id,
    } as ContactUpdateRequest,
    resolver: yupResolver(NewContactFormValidator),
  })

  const onClose = async () => displayModalAction('None')

  const handleSave = async (payload: ContactUpdateRequest) => {
    setSavingContact(true)
    await updateContactAction(payload)
    setSavingContact(false)
    displayModalAction('None')
  }
  const universeContact = (selectedLawyer?.universeEID !== '')
  return (
    <Modal
      isOpen={currentModal === 'EditContactModal'}
      onClose={onClose}
      shouldCloseOnEsc
      contentStyle={{
        width: '646px',
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <div className='text-primary-300 overflow-x-hidden'>
          <div className='pt-2 font-extrabold text-primary-300 text-xl font-semibold mb-2 text-left'>
            Edit person details
          </div>
          <div className='mt-6 grid grid-cols-2 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>Name</p>
              <TextInput
                {...register('fullName')}
                disabled={universeContact}
                placeholder='Name'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.firstName
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.firstName.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Person type</p>
              <Select
                label='Person type'
                disabled={universeContact}
                data={personTypes.map(item => {
                  return { key: item.itemId!, value: item.itemText! }
                })}
                onChange={(value) => {
                  const personType = personTypes.find(f => f.itemId === Number(value.key))
                  setValue('personTypeId', Number(personType?.itemId), { shouldDirty: true, shouldValidate: true })
                }}
                default={{
                  key: personTypes.find(f => f.itemId === getValues('personTypeId'))?.itemId ?? '',
                  value: personTypes.find(f => f.itemId === getValues('personTypeId'))?.itemText ?? '',
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.personTypeId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.personTypeId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Bar number</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                {...register('barNumber')}
                disabled={universeContact}
                placeholder='Bar number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.barNumber
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.barNumber.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Law firm</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                {...register('lawFirm')}
                disabled={universeContact}
                placeholder='Law firm'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.lawFirm
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.lawFirm.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Address 1</p>
              <TextInput
                {...register('address1')}
                disabled={universeContact}
                placeholder='Address 1'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.address1
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.address1.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Address 2</p>
              <TextInput
                {...register('address2')}
                disabled={universeContact}
                placeholder='Address 2'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.address2
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.address2.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='mt-6 grid grid-cols-3 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>City</p>
              <TextInput
                {...register('city')}
                disabled={universeContact}
                placeholder='City'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.city
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.city.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>State</p>
              <Select
                disabled={universeContact}
                label='State'
                data={states.map((item, idx) => {
                  return { key: idx.toString(), value: item }
                })}
                onChange={(value) => {
                  const selectedState = states.find(f => f === value.value)
                  setValue('state', selectedState, { shouldDirty: true, shouldValidate: true })
                }}
                default={{
                  key: states.findIndex(f => f === getValues('state')?.toString()).toString() ?? '',
                  value: states.find(f => f === getValues('state')?.toString()) ?? '',
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.state
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.state.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>ZIP</p>
              <TextInput
                {...register('zipCode')}
                disabled={universeContact}
                placeholder='ZIP'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.zipCode
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.zipCode.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div>
            <p className='font-bold text-sm mb-2'>Region</p>
            <Select
              disabled={universeContact}
              label='Region'
              data={regions.map((item, idx) => {
                return { key: item.itemId ?? idx, value: item.itemText ?? '' }
              })}
              onChange={(value) => {
                const selectedRegion = regions.find(f => f.itemId === Number(value.key))
                setValue('regionId', Number(selectedRegion?.itemId), { shouldDirty: true, shouldValidate: true })
              }}
              default={{
                key: regions.find(f => f.itemId === getValues('regionId'))?.itemId ?? '',
                value: regions.find(f => f.itemId === getValues('regionId'))?.itemText ?? '',
              }}
            />
            <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
              {errors.regionId
                && (
                  <>
                    <InformationCircleIcon className='h-4 w-4 mr-1' />
                    {errors.regionId.message}
                  </>
                )}
            </span>
          </div>
          <div className='mt-6 grid grid-cols-2 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>Email address</p>
              <TextInput
                {...register('email')}
                disabled={universeContact}
                placeholder='Email'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.email
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.email.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Phone number</p>
              <TextInput
                {...register('phoneNumber')}
                disabled={universeContact}
                placeholder='Phone number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.phoneNumber
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.phoneNumber.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='flex justify-end'>
            <Button className='mt-6 mr-2 h-10 w-1/2' type='submit' disabled={savingContact || universeContact}>
              {savingContact
                ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                : (
                  <span>
                    Save
                  </span>
                )}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
