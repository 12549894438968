import { InformationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import Select from 'components/atoms/Select/Select'
import TextArea from 'components/atoms/TextArea/TextArea'
import TextInput from 'components/atoms/TextInput/TextInput'
import { Modal } from 'components/molecules'
import { SearchInput } from 'components/molecules/SearchInput/SearchInput'
import { ComboBoxItem, InteractionUpdateRequest } from 'generated/iTypes'
import { formatDate } from 'helpers/tasksHelper'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { EditInteractionFormValidator } from 'validators/interactionFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const EditInteractionDetailsModal = () => {
  const {
    contacts,
    loadingContacts,
    areasOfAssistance,
    interactionTypes,
    methodsOfContact,
    authenticatedUser,
    newContactCreation,
    resultCount,
    currentModal,
    interactionState,
  } = useAppState()
  const {
    displayModalAction,
    updateContacts,
    showMoreContacts,
    updateInteractionAction,
    loadInteractionDetailsAction,
  } = useActions()
  const [saving, setSaving] = useState<boolean>(false)
  const [fetchNewDate, setFetchNewData] = useState<boolean>(false)
  const [showMoreCount, setShowMoreCount] = useState<number>(resultCount)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const showMore = contacts ? showMoreCount < (contacts[0]?.totalCount ?? 0) : false

  const findItemByText = (arr: ComboBoxItem[], text?: string) => {
    return arr.find(f => f.itemText === text) ?? { code: '', itemText: '' }
  }

  const [areaOfAssistance, setAreaOfAssistance] = useState(
    findItemByText(areasOfAssistance, interactionState.areaOfAssistance),
  )
  const [methodOfContact, setMethodOfContact] = useState(
    findItemByText(methodsOfContact, interactionState.methodOfContact),
  )
  const [interactionType, setInteractionType] = useState(
    findItemByText(interactionTypes, interactionState.interactionType),
  )

  const { formState: { errors }, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      interactionId: interactionState.interactionId,
      areaOfAssistanceId: areaOfAssistance.itemId,
      cellNumber: interactionState.cellNumber,
      contactId: interactionState.contact?.contactId,
      otherEmailAddress: interactionState.otherEmailAddress,
      notes: interactionState.notes,
      interactionTypeId: interactionType.itemId,
      methodOfContactId: methodOfContact.itemId,
      updatedBy: authenticatedUser.userId,
      createInteractionTask: false,
    } as InteractionUpdateRequest,
    resolver: yupResolver(EditInteractionFormValidator),
  })

  const onClose = async () => {
    displayModalAction('InteractionDetailsModal')
  }

  const onSearchInputChange = (value: string) => {
    if (value.length > 1) {
      setShowMoreCount(resultCount)
      setSearchTerm(value)
      updateContacts(value)
    }
  }
  const onShowMore = async () => {
    setFetchNewData(true)
    setShowMoreCount((prevCount) => prevCount + 25)
    await showMoreContacts({ term: searchTerm, resultCount: showMoreCount + 25 })
    setFetchNewData(false)
  }
  return (
    <Modal
      isOpen={currentModal === 'EditInteractionDetailsModal'}
      onClose={onClose}
      contentStyle={{
        width: '646px',
      }}
    >
      <form
        onSubmit={handleSubmit(async (data) => {
          setSaving(true)
          const upt = await updateInteractionAction(data)
          setSaving(false)
          if (upt) loadInteractionDetailsAction(upt)
        })}
      >
        <div className='text-primary-300 '>
          <div className='mb-4 mt-4'>
            <div className='pt-2 font-extrabold text-primary-300 text-xl font-semibold mb-2 text-left'>
              Edit Interaction
            </div>
            <p className='font-bold text-sm mb-1'>Name</p>
            <div>
              <SearchInput
                addNewPerson
                fetchingData={loadingContacts}
                value={contacts.find(f => Number(f.itemId) === newContactCreation?.contactId)
                  || contacts.find(f => Number(f.itemId) === interactionState?.contact?.contactId)}
                data={contacts}
                onChange={(value) => {
                  if (value) {
                    setValue('contactId', Number(value.itemId), { shouldDirty: true, shouldValidate: true })
                  }
                }}
                onInputChange={(value) => onSearchInputChange(value)}
                fetchingNewData={fetchNewDate}
                showMore={showMore}
                onShowMore={onShowMore}
              />
            </div>
            <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
              {errors.contactId
                && (
                  <>
                    <InformationCircleIcon className='h-4 w-4 mr-1' />
                    {errors.contactId.message}
                  </>
                )}
            </span>
          </div>
          <div className='grid grid-cols-2 gap-x-4 gap-y-6'>
            <div>
              <p className='font-bold text-sm mb-1'>Date</p>
              <div className='relative'>
                <TextInput
                  type='date'
                  defaultValue={formatDate(interactionState.interactionDate?.toString() ?? '', 'YYYY-MM-DD')}
                  {...register('interactionDate')}
                />
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.interactionDate
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.interactionDate.message}
                      </>
                    )}
                </span>
              </div>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Method of contact</p>
              <Select
                label='Method of contact'
                default={{
                  key: methodOfContact.itemId ?? '',
                  value: methodOfContact.itemText ?? '',
                }}
                data={methodsOfContact.map((item, idx) => {
                  return { key: item.itemId ?? idx, value: item.itemText ?? '' }
                })}
                onChange={(value) => {
                  setValue('methodOfContactId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                  setMethodOfContact(
                    methodsOfContact.find(f => Number(f.itemId) === Number(value.key))
                      ?? { code: '', itemText: '' },
                  )
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.methodOfContactId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.methodOfContactId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Interaction type</p>
              <Select
                label='Interaction type'
                default={{
                  key: interactionType.itemId ?? '',
                  value: interactionType.itemText ?? '',
                }}
                data={interactionTypes.map((item, idx) => {
                  return { key: item.itemId ?? idx, value: item.itemText ?? '' }
                })}
                onChange={(value) => {
                  setValue('interactionTypeId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                  setInteractionType(
                    interactionTypes.find(f => Number(f.itemId) === Number(value.key))
                      ?? { code: '', itemText: '' },
                  )
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.interactionTypeId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.interactionTypeId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Area of assistance</p>
              <Select
                label='Area of assistance'
                default={{
                  key: areaOfAssistance.itemId ?? '',
                  value: areaOfAssistance.itemText ?? '',
                }}
                data={areasOfAssistance.map((item, idx) => {
                  return { key: item.itemId ?? idx, value: item.itemText ?? '' }
                })}
                onChange={(value) => {
                  setValue('areaOfAssistanceId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                  setAreaOfAssistance(
                    areasOfAssistance.find(f => Number(f.itemId) === Number(value.key))
                      ?? { code: '', itemText: '' },
                  )
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.areaOfAssistanceId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.areaOfAssistanceId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Cell number</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                {...register('cellNumber')}
                placeholder='Cell number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.cellNumber
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.cellNumber.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Other email address</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                placeholder='Other email'
                {...register('otherEmailAddress')}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.otherEmailAddress
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.otherEmailAddress.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4 mt-4'>
            <div>
              <p className='font-bold text-sm'>Notes</p>
              <TextArea
                {...register('notes')}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.notes
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.notes.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='flex justify-end py-4 bottom-0 right-0 w-full bg-white'>
            <Button className='h-10 w-1/2' type='submit' disabled={saving}>
              {saving
                ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                : (
                  <span>
                    Save
                  </span>
                )}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
