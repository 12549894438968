import { DashboardAlerts, DashboardAlertsIcon } from 'components/molecules'
import { EmptyTable } from 'components/molecules/Table/EmptyTable'
import Table, { TableColumn } from 'components/molecules/Table/Table'
import { TablePaginationComponent } from 'components/molecules/Table/TablePagination'
import { TaskCard } from 'components/molecules/TaskCard/TaskCard'
import { InteractionResponse, TaskResponse } from 'generated/iTypes'
import { formatDate, getMappedAvatarColor, getMappedStatus } from 'helpers/tasksHelper'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { Loading } from './Loading'

export function Dashboard() {
  const { authenticatedUser, tasksPaged, interactionsPaged, taskStatistics, taskStatus } = useAppState()
  const {
    displayCreateTaskAction,
    displayNewInteractionAction,
    loadInteractionDetailsAction,
    setInteractionsPage,
    setTasksPage,
    displayLawyerProfileAction,
    displayTaskDetailsModal,
  } = useActions()
  const [interactionsRowsPerPage] = useState<number>(6)
  const [tasksRowsPerPage] = useState<number>(3)
  const [loadingInteraction, setLoadingInteraction] = useState(false)
  const [loadingTasks, setLoadingTasks] = useState(false)

  const interactionsTableColumns: TableColumn<InteractionResponse>[] = [
    {
      cell: (interaction) => (
        <button
          key={`dashboardInteractionBtn-${interaction.interactionId}`}
          className='underline font-semibold text-primary-100'
          onClick={() => loadInteractionDetailsAction(interaction ?? 0)}
        >
          {interaction.interactionDate
            ? formatDate(interaction.interactionDate.toString() ?? '', 'MM/DD/YY')
            : ''}
        </button>
      ),
      name: 'Date',
      selector: ({ interactionDate }) => interactionDate?.getDate() ?? '',
    },
    {
      cell: (interaction) => (
        <button
          key={`dashboardInteractionBtn-${interaction.interactionId}`}
          className='underline font-semibold text-primary-100'
          onClick={() => displayLawyerProfileAction(interaction.contact?.contactId!)}
        >
          {interaction.contact?.firstName + ' ' + interaction.contact?.lastName ?? ''}
        </button>
      ),
      name: 'Contact name',
      selector: ({ contact }) => contact?.firstName + ' ' + contact?.lastName ?? '',
    },
    {
      name: 'Contact method',
      selector: ({ methodOfContact }) => methodOfContact ?? '',
    },
  ]

  const tasksTableColumns: TableColumn<TaskResponse>[] = [
    {
      cell: (task, index) => {
        const mappedStatus = getMappedStatus(task.taskStatus?.code ?? '')
        const mappedColor = getMappedAvatarColor(task.assignedTo?.firstName.slice(0, 1) ?? '')
        return (
          <div className='w-full cursor-pointer'>
            <TaskCard
              key={`dashboardWidgetTaskCards-${index}`}
              initials={`${task.assignedTo?.firstName.slice(0, 1)}${task.assignedTo?.lastName.slice(0, 1)}`}
              color={mappedColor}
              date={task.dueDate
                ? new Date(task.dueDate).toLocaleDateString(undefined, {
                  day: 'numeric',
                  month: 'numeric',
                  year: '2-digit',
                })
                : ''}
              tags={task.tags}
              size='md'
              title={task.name}
              chatCount={task.totalComments}
              pillStatus={mappedStatus?.status}
              pillText={mappedStatus?.text}
              onClick={() => displayTaskDetailsModal(task)}
              isSubTask={task.parentId !== null}
            >
              {task.taskDetails}
            </TaskCard>
          </div>
        )
      },
    },
  ]

  const fetchInteractionsPagination = async (pageNumber: number) => {
    setLoadingInteraction(true)
    await setInteractionsPage({
      pageNumber: (pageNumber),
      pageSize: interactionsRowsPerPage,
      pmaContactIds: authenticatedUser.userId?.toString(),
    })
    setLoadingInteraction(false)
  }

  const fetchTasksPagination = async (pageNumber: number) => {
    setLoadingTasks(true)
    await setTasksPage({
      pageNumber: (pageNumber),
      pageSize: tasksRowsPerPage,
      orderBy: 'dueDate',
      orderDirection: 'ASC',
      fromDate: formatDate(new Date().toString(), 'MM-DD-YYYY'),
      statusIds: taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
        ',',
      ),
      assignedToIds: authenticatedUser.userId?.toString(),
    })
    setLoadingTasks(false)
  }

  const handleInteractionPageChange = (pageNumber: number) => {
    fetchInteractionsPagination(pageNumber)
  }

  const handleTaskPageChange = (pageNumber: number) => {
    fetchTasksPagination(pageNumber)
  }

  return (
    <div className='-ml-8 -mr-8 text-primary-300'>
      <div className='h-[calc(100vh-80px)] overflow-auto flex-wrap bg-bgLightBlue'>
        <div className='border-b bg-white'>
          <div className='px-12 py-8 '>
            <h1 className='font-medium'>
              Welcome back, <span className='font-extrabold'>{`${authenticatedUser.userLogin}`}</span>
            </h1>
            <div className='flex flex-row'>
              <div className='mb-6 mt-6 grid grid-cols-3 md:gap-20 lg:gap-20 xl:gap-30 2xl:gap-48 '>
                <DashboardAlerts
                  line1='tasks'
                  line2='due today'
                  value={taskStatistics.totalTasksDueToday}
                />
                <DashboardAlerts
                  line1='tasks due'
                  line2='this week'
                  value={taskStatistics.totalTasksDueWeek}
                  icon={DashboardAlertsIcon.ClipBoard}
                />
                <DashboardAlerts
                  line1='tasks'
                  line2='past due'
                  value={taskStatistics.totalTasksPastDue}
                  icon={DashboardAlertsIcon.CalendarWarning}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='relative flex flex-row'>
          <div className='flex flex-col grow pt-2 place-items-center'>
            <div className='px-12 pt-4 grid grid-cols-7 gap-20 mb-6'>
              <div className='col-span-4'>
                <h3 className='mb-6'>Upcoming tasks</h3>
                <div className='relative grow rounded-lg bg-white pt-2'>
                  <Table
                    className='h-full'
                    columns={tasksTableColumns}
                    noTableHead
                    data={tasksPaged.items ?? []}
                    noDataComponent={
                      <EmptyTable
                        content={`You don't have any upcoming tasks. Click the button to create a new task.`}
                        buttonText={'Create new task'}
                        onClick={displayCreateTaskAction}
                      />
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={tasksPaged.totalItems}
                    progressPending={loadingTasks}
                    progressComponent={
                      <div className='h-[440px] grid place-items-center'>
                        <Loading />
                      </div>
                    }
                    onChangePage={handleTaskPageChange}
                    paginationPerPage={tasksRowsPerPage}
                    paginationComponent={TablePaginationComponent}
                  />
                </div>
              </div>
              <div className='col-span-3'>
                <h3 className='mb-6'>Recent interactions</h3>
                <div className='relative grow rounded-lg bg-white'>
                  <Table
                    className='h-full'
                    columns={interactionsTableColumns}
                    data={interactionsPaged.items ?? []}
                    noDataComponent={
                      <EmptyTable
                        content={`You haven't recorded any recent interactions. Click the button to log a new interaction.`}
                        buttonText={'Add new interaction'}
                        onClick={displayNewInteractionAction}
                      />
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={interactionsPaged.totalItems}
                    progressPending={loadingInteraction}
                    progressComponent={
                      <div className='h-[440px] grid place-items-center'>
                        <Loading />
                      </div>
                    }
                    onChangePage={handleInteractionPageChange}
                    paginationPerPage={interactionsRowsPerPage}
                    paginationComponent={TablePaginationComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
