import { PlusIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'

type EmptyTableProps = {
  content: string
  buttonText: string
  onClick?: () => void
}

export const EmptyTable = (props: EmptyTableProps) => {
  return (
    <div className='flex flex-col items-center justify-center h-[440px]'>
      <div className='flex gap-2'>
        <span className='h-4 w-4 rounded-full bg-primary-100'></span>
        <span className='h-4 w-4 rounded-full bg-primary-200'></span>
        <span className='h-4 w-4 rounded-full bg-primary-100'></span>
      </div>
      <p className='font-semibold text-textGrey w-1/2 text-center mt-6'>
        {props.content}
      </p>
      {props.buttonText !== ''
        ? (
          <Button className='mt-6' onClick={props.onClick}>
            <PlusIcon className='w-4 h-4 mr-2' />
            {props.buttonText}
          </Button>
        )
        : <div></div>}
    </div>
  )
}
