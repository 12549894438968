export class InternalServerError extends Error {
  constructor() {
    super()
    this.name = 'InternalServerError'
  }
}

export class UnexpectedError extends Error {
  constructor() {
    super()
    this.name = 'UnexpectedError'
  }
}

export class UnauthenticatedError extends Error {
  constructor() {
    super()
    this.name = 'UnauthenticatedError'
  }
}

export class ForbiddenError extends Error {
  constructor() {
    super()
    this.name = 'ForbiddenError'
  }
}

export class ConflictError extends Error {
  constructor(error: string) {
    super()
    this.name = 'ConflictError'
    this.message = error
  }
}
