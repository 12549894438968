import React, { ButtonHTMLAttributes } from 'react'

type Colors = 'primary' | 'secondary'
type Sizes = 'sm' | 'md' | 'lg'

const colors: Record<Colors, string> = {
  primary: 'bg-primary-100 hover:bg-primary-300 text-white',
  secondary:
    'bg-transparent text-primary-100 font-semibold hover:text-primary-300 hover:border-primary-300 py-2 px-4 border-2 border-primary-100',
}

const sizes: Record<Sizes, string> = {
  lg: 'py-4 px-6 text-lg',
  md: 'py-2 px-6 text-base',
  sm: 'py-1 px-6 text-sm',
}

const roundedSizes: Record<Sizes, string> = {
  lg: 'rounded-lg',
  md: 'rounded-md',
  sm: 'rounded-sm',
}

interface ButtonProps {
  color?: Colors
  rounded?: Sizes
  size?: Sizes
}

export const Button = ({
  className,
  color = 'primary',
  rounded = 'lg',
  size = 'md',
  ...rest
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={`
        flex items-center justify-center ${className ?? ''}
        font-semibold cursor-pointer disabled:opacity-[48%]
        ${`
          ${colors[color]} ${sizes[size]} ${roundedSizes[rounded]}
        `}
      `}
      {...rest}
    />
  )
}
