import { XCircleIcon } from '@heroicons/react/outline'
import { PillStatus } from 'entities/enums/PillStatus'

export interface TagProps {
  text?: string
  status?: PillStatus
  onClose?: () => void
}

export const Pill = ({
  status = PillStatus.Done,
  ...props
}: TagProps) => {
  return (
    <span
      className={`flex flex-wrap justify-center space-x-2 rounded-full text-xs font-bold py-2 px-2 w-max               
                ${status === PillStatus.ToDo && 'text-white bg-tertiary-100'}
                ${status === PillStatus.Blocked && 'text-white bg-tertiary-200'}
                ${status === PillStatus.Delayed && 'text-primary-300 bg-tertiary-300'}
                ${status === PillStatus.Done && 'text-white bg-tertiary-400'}                
                ${status === PillStatus.InProgress && 'text-white bg-primary-100'}`}
    >
      {props.text}
      {props.onClose && (
        <span className='pl-1'>
          <XCircleIcon className='h-4 w-4 cursor-pointer' onClick={props.onClose} />
        </span>
      )}
    </span>
  )
}
