import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import { EmailOrAddressFormRequest } from 'entities/types/Emails'
import { ListType, OutputType } from 'generated/iTypes'
import { useActions } from 'presenter'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { EmailsFormValidator } from 'validators/emailsFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'
import {
  EmailsCountyFilter,
  EmailsOptedFilter,
  EmailsPLFTypeFilter,
  EmailsStateFilter,
  EmailsStatusFilter,
  EmailsTypesList,
} from './Filters/Index'

export const EmailsDashboard = () => {
  const { downloadEmailOrAddressFile } = useActions()
  const [downloading, setDownloading] = useState<boolean>(false)
  const methods = useForm({
    defaultValues: {
      obj: {
        listType: ListType.Email,
      },
    } as EmailOrAddressFormRequest,
    resolver: yupResolver(EmailsFormValidator),
  })

  const SubmitEmailsList = async (props: EmailOrAddressFormRequest) => {
    setDownloading(true)
    await downloadEmailOrAddressFile({ ...props.obj, outputTo: OutputType.File })
    setDownloading(false)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(SubmitEmailsList)}>
        <div className='-ml-8 -mr-8 text-primary-300'>
          <div className='h-[calc(100vh-80px)] overflow-auto flex-wrap'>
            <div className='border-b bg-white'>
              <div className='relative px-12 py-8'>
                <h1>Email list</h1>
              </div>
            </div>
            <div className='px-12 py-6 grid grid-cols-2 gap-6'>
              <div>
                <EmailsTypesList />
                <h3 className='mb-6 mt-6'>Filters</h3>
                <div className='w-full grid grid-cols-2 gap-4 mb-6'>
                  <EmailsPLFTypeFilter />
                  <EmailsOptedFilter />
                  <EmailsStatusFilter />
                  <EmailsStateFilter />
                </div>
              </div>
              <div>
                <EmailsCountyFilter />
                <div className='flex pt-12 justify-end'>
                  <Button className='w-80 h-12' type='submit' disabled={downloading}>
                    {!downloading
                      ? 'Export list'
                      : <SpinnerSVG className='inline w-6 h-6 text-gray-200 animate-spin fill-primary-200' />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
