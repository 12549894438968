import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import TextInput from 'components/atoms/TextInput/TextInput'
import { LawyerAlternativeContact } from 'generated/iTypes'

type AlternativeContactProps = {
  item: LawyerAlternativeContact
  onItemChange: (item: LawyerAlternativeContact) => void
}

export const AlternativeContact = (props: AlternativeContactProps) => {
  const handleItemChange = (item: LawyerAlternativeContact) => {
    props.onItemChange({ ...item })
  }

  return (
    <div>
      <div className='mt-5'>
        <h4>{`Contact #${props.item.id}`}</h4>
      </div>
      <div className='w-full pb-1 grid grid-cols-10 mt-5 gap-x-4'>
        <div className='col-span-3'>
          <span className='font-bold text-sm mb-1'>Name</span>
        </div>
        <div className='col-span-3'>
          <span className='font-bold text-sm mb-1'>Phone</span>
        </div>
        <div className='col-span-2'>
          <span className='font-bold text-sm mb-1'>Email</span>
        </div>
        <div></div>
        <div></div>
        <div className='col-span-3'>
          <TextInput
            value={props.item.name}
            onChange={(e) => handleItemChange({ ...props.item, name: e.target.value })}
          />
        </div>
        <div className='col-span-3'>
          <TextInput
            value={props.item.phoneNumber}
            onChange={(e) => handleItemChange({ ...props.item, phoneNumber: e.target.value })}
          />
        </div>
        <div className='col-span-2'>
          <TextInput
            value={props.item.email}
            onChange={(e) => handleItemChange({ ...props.item, email: e.target.value })}
          />
        </div>
        <div></div>
        <div></div>
      </div>
      <div className='w-full pb-1 grid grid-cols-10 mt-5 gap-x-4'>
        <div className='col-span-3'>
          <span className='font-bold text-sm mb-1'>Address</span>
        </div>
        <div className='col-span-3'>
          <span className='font-bold text-sm mb-1'>Address line 2</span>
        </div>
        <div className='col-span-2'>
          <span className='font-bold text-sm mb-1'>City</span>
        </div>
        <div>
          <span className='font-bold text-sm mb-1'>State</span>
        </div>
        <div>
          <span className='font-bold text-sm mb-1'>ZIP Code</span>
        </div>
        <div className='col-span-3'>
          <TextInput
            value={props.item.address1}
            onChange={(e) => handleItemChange({ ...props.item, address1: e.target.value })}
          />
        </div>
        <div className='col-span-3'>
          <TextInput
            value={props.item.address2}
            onChange={(e) => handleItemChange({ ...props.item, address2: e.target.value })}
          />
        </div>
        <div className='col-span-2'>
          <TextInput
            value={props.item.city}
            onChange={(e) => handleItemChange({ ...props.item, city: e.target.value })}
          />
        </div>
        <div>
          <TextInput
            value={props.item.zipCode}
            onChange={(e) => handleItemChange({ ...props.item, zipCode: e.target.value })}
          />
        </div>
        <div>
          <TextInput
            value={props.item.state}
            onChange={(e) => handleItemChange({ ...props.item, state: e.target.value })}
          />
        </div>
      </div>
      <div className='w-full pb-1 grid grid-cols-10 mt-5 gap-x-4'>
        <div className='col-span-6'>
          <span className='font-bold text-sm mb-1'>Relationship</span>
        </div>
        <div className='col-span-4'>
        </div>
        <div className='col-span-10'>
          <div className='grid grid-cols-10 items-center'>
            <Checkbox
              text={'Family'}
              fontWeight='font-bold'
              defaultChecked={props.item.isFamily}
              onChange={(e) => handleItemChange({ ...props.item, isFamily: e })}
            />
            <Checkbox
              text={'Colleague'}
              fontWeight='font-bold'
              defaultChecked={props.item.isColleague}
              onChange={(e) => handleItemChange({ ...props.item, isColleague: e })}
            />
            <Checkbox
              text={'Staff'}
              fontWeight='font-bold'
              defaultChecked={props.item.isStaff}
              onChange={(e) => handleItemChange({ ...props.item, isStaff: e })}
            />
            <Checkbox
              text={'Friend'}
              fontWeight='font-bold'
              defaultChecked={props.item.isFriend}
              onChange={(e) => handleItemChange({ ...props.item, isFriend: e })}
            />
            <div className='flex items-center col-span-2'>
              <Checkbox
                text={'Assisting Atty'}
                fontWeight='font-bold'
                defaultChecked={props.item.isAssistingAtty}
                onChange={(e) => handleItemChange({ ...props.item, isAssistingAtty: e })}
              />
            </div>
            <div className='flex items-center col-span-2'>
              <Checkbox
                text={''}
                defaultChecked={props.item.isOther}
                onChange={(e) => handleItemChange({ ...props.item, isOther: e })}
              />
              <TextInput
                disabled={!props.item.isOther}
                value={props.item.other}
                onChange={(e) => handleItemChange({ ...props.item, other: e.target.value })}
                placeholder='Other'
                remmovePadding={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
