import { Transition } from '@headlessui/react'
import { DotsHorizontalIcon, InformationCircleIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { Dropdown } from 'components/atoms/Dropdown/Dropdown'
import { Link } from 'components/atoms/Link/Link'
import { Modal } from 'components/molecules'
import { ConfirmationCard } from 'components/molecules/ConfirmationCard/ConfirmationCard'
import { getContactLinkText } from 'helpers/interactionsHelpers'
import { useActions, useAppState } from 'presenter'
import { Fragment, useState } from 'react'

export const InteractionDetailsModal = () => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { currentModal, interactionState } = useAppState()
  const { displayModalAction, displayLawyerProfileAction, deleteInteractionAction, displayEditInteractionAction } =
    useActions()

  const onClose = async () => {
    displayModalAction('None')
  }

  const contactOnClick = (contactId: number) => {
    displayLawyerProfileAction(contactId)
    onClose()
  }

  const handleDelete = async () =>
    interactionState.interactionId && deleteInteractionAction(interactionState.interactionId)

  return (
    <Modal
      isOpen={currentModal === 'InteractionDetailsModal'}
      onClose={onClose}
      contentStyle={{
        width: '646px',
      }}
    >
      <div className='flex flex-col justify-center w-[80vw] sm:w-[558px] px-2'>
        <div className='text-primary-300 text-xl font-semibold mb-2 text-left flex mb-4'>
          <InformationCircleIcon className='h-6 w-6 text-primary-100' />
          <span className='pl-2 align-center'>Interaction details</span>
          <div className='absolute right-8'>
            <Dropdown
              icon={() => <DotsHorizontalIcon className='h-5 w-10' />}
              label={''}
              data={[{
                icon: () => <PencilAltIcon className='h-5 w-10' />,
                name: 'Edit Interaction',
                onClick: () => {
                  displayEditInteractionAction()
                },
              }, {
                icon: () => <TrashIcon className='h-5 w-10' />,
                name: 'Delete Interaction',
                onClick: () => {
                  setShowDeleteConfirmation(true)
                },
              }]}
            />
          </div>
        </div>
        <div className='text-primary-300 text-base font-semibold text-left mb-6'>
          Name
          <p>
            <Link
              text={getContactLinkText(interactionState)}
              underline
              onClick={() => {
                interactionState.contact && contactOnClick(interactionState.contact.contactId!)
              }}
            />
          </p>
        </div>
        <div className='grid gap-4 grid-cols-2 '>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            Date<p className='text-grey-100 font-normal'>
              {new Date(interactionState.interactionDate?.toString() ?? '').toLocaleDateString()}
            </p>
          </div>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            Area of Assistance<p className='text-grey-100 font-normal'>
              {interactionState.areaOfAssistance}
            </p>
          </div>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            PMA Contact<p className='text-grey-100 font-normal'>{interactionState.user?.userName}</p>
          </div>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            Method of Contact<p className='text-grey-100 font-normal'>
              {interactionState.methodOfContact}
            </p>
          </div>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            Cell Number<p className='text-grey-100 font-normal'>{interactionState.cellNumber}</p>
          </div>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            Other Email Address<p className='text-grey-100 font-normal'>
              {interactionState.otherEmailAddress}
            </p>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='text-primary-300 text-base font-semibold text-left mb-6'>
            Notes<p className='text-grey-100 font-normal'>{interactionState.notes}</p>
          </div>
        </div>
      </div>
      <div className='absolute bottom-0 right-0 w-full z-40 bg-white'>
        <Transition
          as={Fragment}
          show={showDeleteConfirmation}
          enter='transform transition ease-out duration-200 sm:duration-200'
          enterFrom='opacity-0 translate-x-full'
          enterTo='opacity-100 translate-x-0'
        >
          <div>
            <ConfirmationCard
              text='Are you sure you want to delete this interaction?'
              confirmText='Yes'
              cancelText='No'
              confirmButtonStyle='secondary'
              cancelButtonStyle='secondary'
              onConfirm={handleDelete}
              onCancel={() => setShowDeleteConfirmation(false)}
            />
          </div>
        </Transition>
      </div>
    </Modal>
  )
}
