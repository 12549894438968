import { ResponseWrapper } from 'entities/types/ResponseWrapper'
import { SubTaskTemplateDetail, TaskTemplateDetail } from 'generated/iTypes'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function displayTasksTemplatesDashboardAction(context: Context) {
  try {
    context.state.currentPage = 'Loading'
    context.state.tags = await context.effects.getAllTags()
    context.state.allTaskTemplates = await context.effects.getAllTaskTemplates()
    context.state.currentPage = 'TaskTemplates'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setSelectedTaskTemplate(context: Context, task: TaskTemplateDetail) {
  try {
    context.state.selectedTask = { ...task }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayTaskTemplateDetailsModal(
  context: Context,
  value: { task: TaskTemplateDetail; subTask: SubTaskTemplateDetail },
) {
  context.state.currentModal = 'TaskTemplateDetailsModal'
  context.state.isCreateNewTaskFormDirty = false
  context.state.loadingTaskDetails = true
  context.state.selectedTaskTemplateDetails = { ...value.task }
  context.state.selectedSubTaskTemplateDetails = { ...value.subTask }
  context.state.subTaskDirectSelect = value.subTask.id !== undefined
  context.state.loadingTaskDetails = false
}

export async function displayNewTaskTemplateModalAction(
  context: Context,
) {
  context.state.currentModal = 'CreateNewTaskTemplateModal'
  context.state.isCreateNewTaskFormDirty = false
  context.state.subTaskDirectSelect = false
  context.state.selectedTaskTemplateDetails = {}
  context.state.selectedSubTaskTemplateDetails = {}
}

export async function loadSubTaskTemplateDetailsAction(
  context: Context,
  value: { taskId: number | undefined; subTask: SubTaskTemplateDetail },
) {
  if (value.taskId === undefined || value.taskId === 0) {
    context.state.selectedSubTaskTemplateDetails = { ...value.subTask }
  } else {
    context.state.selectedSubTaskTemplateDetails = await context.effects.getTaskTemplateDetails(value.taskId)
  }
}

export async function addNewTaskTemplateAction(
  context: Context,
  task: TaskTemplateDetail,
): Promise<TaskTemplateDetail | ResponseWrapper> {
  try {
    context.state.autoSaving = true
    const response = await context.effects.addNewTaskTemplate(task)
    const created = response as TaskTemplateDetail
    if (created.id) {
      context.state.allTaskTemplates = await context.effects.getAllTaskTemplates()
      context.state.autoSaving = false
      return created
    } else {
      context.state.autoSaving = false
      return response as ResponseWrapper
    }
  } catch (err) {
    context.state.autoSaving = false
    defaultErrorHandler(context, err)
  }
  return { message: 'Error creating task Template', responseType: 'Error' }
}

export async function addNewSubTaskTemplateAction(
  context: Context,
  subTask: SubTaskTemplateDetail,
): Promise<TaskTemplateDetail | undefined> {
  try {
    const created = await context.effects.addNewSubTaskTemplate(subTask)
    const parent = context.state.allTaskTemplates.find(t => t.id === subTask.parentId)
    if (parent !== undefined) {
      const updatedParent = { ...parent, subTasks: [...parent.subTasks ?? [], { ...subTask, id: created.id }] }
      context.state.allTaskTemplates = context.state.allTaskTemplates.map(tt =>
        tt.id === updatedParent.id ? updatedParent : tt
      )
    }
    return created
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function saveTaskTemplateAction(
  context: Context,
  value: { task: TaskTemplateDetail | SubTaskTemplateDetail; shouldRefreshSummary: boolean },
): Promise<TaskTemplateDetail | undefined> {
  try {
    context.state.autoSaving = true
    const result = await context.effects.saveTaskTemplate(value.task)
    if (value.shouldRefreshSummary) {
      const asSubTask = value.task as SubTaskTemplateDetail
      if (asSubTask.parentId !== undefined) {
        const parent = context.state.allTaskTemplates.find(t => t.id === asSubTask.parentId)
        if (parent !== undefined) {
          const updatedParent = {
            ...parent,
            subTasks: parent.subTasks?.map(st => st.id === value.task.id ? value.task : st),
          }
          context.state.allTaskTemplates = context.state.allTaskTemplates.map(tt =>
            tt.id === updatedParent.id ? updatedParent : tt
          )
        }
      } else {
        context.state.allTaskTemplates = context.state.allTaskTemplates.map(tt =>
          tt.id === value.task.id ? value.task : tt
        )
      }
    }
    context.state.autoSaving = false
    return result
  } catch (err) {
    context.state.autoSaving = false
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function deleteTaskTemplateAction(
  context: Context,
  taskId: number,
): Promise<void> {
  try {
    await context.effects.deleteTaskTemplate(taskId)
    context.state.allTaskTemplates = context.state.allTaskTemplates.filter(tt => tt.id !== taskId)
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function deleteSubTaskTemplateAction(
  context: Context,
  value: { parentId: number; id: number },
): Promise<void> {
  try {
    await context.effects.deleteTaskTemplate(value.id)
    const parent = context.state.allTaskTemplates.find(t => t.id === value.parentId)
    if (parent !== undefined) {
      const updatedParent = { ...parent, subTasks: parent.subTasks?.filter(st => st.id !== value.id) }
      context.state.allTaskTemplates = context.state.allTaskTemplates.map(tt =>
        tt.id === updatedParent.id ? updatedParent : tt
      )
    }
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}
