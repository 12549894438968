import { ChatIcon } from '@heroicons/react/outline'
import { Avatar } from 'components/atoms/Avatar/Avatar'
import { Card } from 'components/atoms/Card/Card'
import { Pill } from 'components/atoms/Pills/Pills'
import { Tag } from 'components/atoms/Tag/Tag'
import { Tooltip } from 'components/atoms/Tooltip/Tooltip'
import { AvatarColors } from 'entities/enums/AvatarColors'
import { PillStatus } from 'entities/enums/PillStatus'
import { TagColor } from 'entities/enums/TagsColors'
import { TagResponse } from 'generated/iTypes'
import { getMappedTagsColor } from 'helpers/tasksHelper'

export interface TaksCardProps {
  color?: AvatarColors
  initials: string
  date: string
  title?: string
  size?: 'sm' | 'md'
  tags?: TagResponse[]
  tagColor?: TagColor
  pillText?: string
  pillStatus?: PillStatus
  isPastDue?: boolean
  subTasksCount?: number
  chatCount?: number
  children: React.ReactNode
  onClick?: () => void
  isSubTask?: boolean
}

export const TaskCard = ({
  color = AvatarColors.Blue,
  pillText = 'In Progress',
  pillStatus = PillStatus.InProgress,
  size = 'sm',
  ...props
}: TaksCardProps) => {
  return (
    <Card
      width={size === 'sm' ? 'w-52' : 'w-full'}
      onClick={props.onClick}
      borderStyle={props.isSubTask ? 'border-t-8 border-t-primary-100' : ''}
    >
      {size === 'sm'
        ? (
          <div className='text-center w-full h-full'>
            <div className='h-60'>
              <div className='flex py-3 px-4 justify-between'>
                <div className='inline-flex'>
                  <Avatar initials={props.initials} color={color} />
                  {(props.subTasksCount && props.subTasksCount > 0)
                    ? (
                      <div className='mt-1.5 ml-3 h-6 w-6 rounded-full text-sm text-primary-100 font-extrabold h-full grid place-items-center border-2 border-primary-100'>
                        {props.subTasksCount}
                      </div>
                    )
                    : <></>}
                </div>
                {props.isPastDue
                  ? (
                    <Tooltip toolTipText={'Past Due'}>
                      <div className='pt-1 text-md font-semibold text-tertiary-500'>{props.date}</div>
                    </Tooltip>
                  )
                  : <div className='pt-1 text-md font-semibold'>{props.date}</div>}
              </div>
              <div className='py-4 px-4 mb-3'>
                <div className='line-clamp-1 font-extrabold text-left mb-2'>{props.title}</div>
                <p className='line-clamp-4 text-left text-textGrey'>
                  {props.children}
                </p>
              </div>
            </div>
            <div className='flex py-3 px-2 border-t border-outlineLightBlue justify-between'>
              <Pill text={pillText} status={pillStatus} />
              <div className='flex inline'>
                {props.chatCount}
                <ChatIcon className='w-7 h-7 pl-2' />
              </div>
            </div>
          </div>
        )
        : (
          <div className='w-full h-full'>
            <div className='flex pt-3 place-content-between place-items-center'>
              <div className='flex font-extrabold mb-2 pl-3'>
                <Avatar initials={props.initials} color={color} />
                <span className='line-clamp-2 pl-4 pt-2 text-primary-300 text-left'>{props.title}</span>
              </div>
              <div className='place-content-end text-sm font-extrabold pr-3 text-primary-300'>{props.date}</div>
            </div>
            <div className='py-4 px-4 mb-3'>
              <p className='line-clamp-2 text-left text-textGrey'>
                {props.children}
              </p>
            </div>
            <div className='flex justify-between py-4 px-4 border-t border-outlineLightBlue place-content-between place-items-center'>
              <div className='flex text-left truncate mr-2 gap-3'>
                <span className=''>
                  <Pill text={pillText} status={pillStatus} />
                </span>
                {props.tags?.map((tag) => (
                  <Tag
                    color={props.tagColor ?? getMappedTagsColor(tag.name ?? '')}
                    text={tag.name}
                  />
                ))}
              </div>
              <div className='flex inline place-items-center'>
                {props.chatCount}
                <ChatIcon className='w-7 h-7 pl-2' />
              </div>
            </div>
          </div>
        )}
    </Card>
  )
}
