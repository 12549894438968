import { Button } from 'components/atoms/Button/Button'
import TextArea from 'components/atoms/TextArea/TextArea'
import React, { useMemo, useState } from 'react'

type TextEditorProps = {
  value?: string
  onSave?: (value: string) => void
  onCancel?: () => void
  placeholder?: string
}
export const TextEditor = (props: TextEditorProps): React.ReactElement => {
  const [originalValue] = useState(props.value)
  const [value, setValue] = useState<string | undefined>(props.value)
  const [needsSave, setNeedsSave] = useState(false)

  useMemo(() => {
    setNeedsSave(value !== originalValue && value !== '')
  }, [value, originalValue])

  const handleChange = (value: string) => {
    setValue(value !== '' ? value : undefined)
  }

  const handleSave = () => {
    if (props.onSave) {
      props.onSave(value!)
    }
  }

  const handleCancel = () => {
    setValue('')
    if (props.onCancel) {
      props.onCancel()
    }
  }

  return (
    <div>
      <TextArea
        value={value}
        placeholder={props.placeholder}
        rows={4}
        onChange={e => handleChange(e.target.value)}
        onKeyDown={(e) => {
          ;(value && e.ctrlKey && e.key === 'Enter') && handleSave()
        }}
      />
      {needsSave && (
        <div className='grid justify-items-end mt-2'>
          <div className='flex space-x-2'>
            <Button
              onClick={handleSave}
              size='sm'
            >
              Save
            </Button>
            <Button
              color='secondary'
              onClick={handleCancel}
              size='sm'
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
