import { InformationCircleIcon } from '@heroicons/react/outline'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import Select, { Option } from 'components/atoms/Select/Select'
import TextInput from 'components/atoms/TextInput/TextInput'
import { SubTaskSelection } from 'entities/types/Tasks'
import { SubTaskTemplateDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import React, { useEffect } from 'react'

type SubTasksSelectorProps = {
  subTasksSelection?: SubTaskSelection[]
  onChange?: (value: SubTaskSelection[]) => void
  validate?: boolean
  onValidate?: (isValid: boolean) => void
}

export const SubTasksSelector = (props: SubTasksSelectorProps): React.ReactElement => {
  const { setIsCreateNewTaskFormDirty } = useActions()
  const { users } = useAppState()

  const onSubTasksChange = (value: SubTaskSelection[]) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const subTaskChecked = (subTask: SubTaskTemplateDetail, checked: boolean) => {
    setIsCreateNewTaskFormDirty(true)
    const subTasks = props.subTasksSelection?.map(st =>
      st.subTask.id === subTask.id
        ? (checked
          ? ({ ...st, checked: checked })
          : ({ ...st, checked: checked, assignedTo: undefined, dueDate: undefined }))
        : st
    ) ?? []
    onSubTasksChange(subTasks)
  }

  const handleAsignToChanged = (subTask: SubTaskTemplateDetail, user: Option) => {
    setIsCreateNewTaskFormDirty(true)
    let selectedUser = users.find(u => u.userId?.toString() === user.key)
    const subTasks =
      props.subTasksSelection?.map(st => st.subTask.id === subTask.id ? ({ ...st, assignedTo: selectedUser }) : st)
        ?? []
    onSubTasksChange(subTasks)
  }

  const handleDateChanged = (subTask: SubTaskTemplateDetail, date: string) => {
    const subTasks = props.subTasksSelection?.map(st => st.subTask.id === subTask.id ? ({ ...st, dueDate: date }) : st)
      ?? []
    onSubTasksChange(subTasks)
  }

  useEffect(() => {
    let isValid = true
    props.subTasksSelection?.forEach(st => {
      isValid = isValid
        && ((st.checked
          && (st.dueDate !== undefined && st.dueDate !== '')
          && (st.assignedTo?.userId !== undefined)) || !st.checked)
    })
    if (props.onValidate) {
      props.onValidate(isValid)
    }
  }, [props.subTasksSelection, props])

  return (
    <div className='space-y-4 pl-2'>
      {props.subTasksSelection?.map((st, index) => (
        <div key={st.subTask.id}>
          <div className='flex mb-4'>
            <Checkbox text={st.subTask.name ?? ''} onChange={(c) => subTaskChecked(st.subTask, c)} />
          </div>
          {st.checked && (
            <div className='grid grid-cols-2 gap-4'>
              <div className=''>
                <p className='font-bold text-sm mb-1'>Due Date</p>
                <div className='relative'>
                  <div className='flex flex-row grow relative'>
                    <TextInput
                      hasAutoFocus={true}
                      type='date'
                      onChange={e => handleDateChanged(st.subTask, e.target.value)}
                    />
                  </div>
                  <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                    {props.validate && (st.dueDate === undefined || st.dueDate === '')
                      && (
                        <>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          Select a valid date
                        </>
                      )}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <p className='font-bold text-sm mb-1'>Assign To</p>
                  <Select
                    label='Assign To'
                    data={users?.map(u => {
                      return { key: u.userId?.toString() ?? '', value: u.firstName ?? '' + u.lastName ?? '' }
                    })}
                    onChange={(u) => {
                      handleAsignToChanged(st.subTask, u)
                      setIsCreateNewTaskFormDirty(true)
                    }}
                    selectedValueFontWeight='font-normal'
                  />
                  <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                    {props.validate && (st.assignedTo === undefined)
                      && (
                        <>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          Select a PMA contact
                        </>
                      )}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div>
          </div>
        </div>
      ))}
    </div>
  )
}
