import { XCircleIcon } from '@heroicons/react/solid'
import { TagColor } from 'entities/enums/TagsColors'

export interface TagProps {
  text?: string
  color?: TagColor
  className?: string
  onClose?: () => void
}

export const Tag = ({
  color = TagColor.Blue,
  ...props
}: TagProps) => {
  return (
    <div className={`shrink-0 ${props.className}`}>
      <span
        className={`text-xs inline-flex items-center font-bold leading-sm px-3 py-2 rounded-lg
                ${color === TagColor.Green && 'text-[#02614E] bg-[#C4EDD9]'}
                ${color === TagColor.Purple && 'text-[#39004D] bg-[#F2D0FE]'}
                ${color === TagColor.Yellow && 'text-[#A95100] bg-[#FFF1CD]'}
                ${color === TagColor.Blue && 'text-[#003D75] bg-[#BBE2FF]'}`}
      >
        {props.text}
        {props.onClose && (
          <span className='pl-1'>
            <XCircleIcon className='h-4 w-4 cursor-pointer' onClick={props.onClose} />
          </span>
        )}
      </span>
    </div>
  )
}
