import { EmailOrAddressRequest } from 'generated/iTypes'
import { setTopActiveScreen } from 'helpers/siteHelpers'
import { formatDate } from 'helpers/tasksHelper'
import { RouteNames } from 'presenter/router'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function displayEmailsDashboardAction(context: Context) {
  try {
    context.state.currentPage = 'EmailsDashboard'
    setTopActiveScreen(context, RouteNames.Emails)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function downloadEmailOrAddressFile(
  context: Context,
  data: EmailOrAddressRequest,
) {
  try {
    return await context.effects.getEmailOrAddressList({ ...data })
      .then((res) => {
        const today = formatDate(new Date().toLocaleDateString(), 'MM-DD-YYYY')
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `PMAP-${data.listType}-${today}-Generated.csv`)
        document.body.appendChild(link)
        link.click()
      })
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
