import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { InteractionPageRequestParameters } from 'entities/types/Interactions'
import {
  InteractionInsertRequest,
  InteractionResponse,
  InteractionResponsePagedResult,
  InteractionUpdateRequest,
} from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getInteractionsPage(
  request: InteractionPageRequestParameters,
): Promise<InteractionResponsePagedResult> {
  let url_ = 'api/Interaction/getAll?OrderBy=InteractionDate&OrderDirection=DESC&'
  if (request.pageNumber !== undefined) url_ += 'PageNumber=' + encodeURIComponent('' + request.pageNumber) + '&'
  if (request.pageSize !== undefined) url_ += 'PageSize=' + encodeURIComponent('' + request.pageSize) + '&'
  if (request.contactId !== undefined) url_ += 'ContactId=' + encodeURIComponent('' + request.contactId) + '&'
  if (request.areaOfAssistanceIds !== undefined && request.areaOfAssistanceIds !== '') {
    url_ += 'AreaOfAssistanceIds=' + encodeURIComponent('' + request.areaOfAssistanceIds) + '&'
  }
  if (request.methodOfContactIds !== undefined && request.methodOfContactIds !== '') {
    url_ += 'MethodOfContactIds=' + encodeURIComponent('' + request.methodOfContactIds) + '&'
  }
  if (request.pmaContactIds !== undefined && request.pmaContactIds !== '') {
    url_ += 'PMAContactIds=' + encodeURIComponent('' + request.pmaContactIds) + '&'
  }

  url_ = url_.replace(/[?&]$/, '')
  let options_: AxiosRequestConfig = {
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<InteractionResponsePagedResult>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getInteractionById(id: number): Promise<InteractionResponse> {
  let url_ = '/api/Interaction/getById?'
  if (id === null) throw new Error("The parameter 'id' cannot be null.")
  else if (id !== undefined) url_ += 'interactionId=' + encodeURIComponent('' + id) + '&'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<InteractionResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addInteraction(interaction: InteractionInsertRequest): Promise<number> {
  let url_ = 'api/Interaction/addNew'
  if (interaction === null) throw new Error("The parameter 'id' cannot be null.")

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: interaction,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<InteractionInsertRequest>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function updateInteraction(interaction: InteractionUpdateRequest): Promise<InteractionResponse> {
  let url_ = 'api/Interaction/update'
  if (interaction === null) throw new Error("The parameter 'id' cannot be null.")

  let options_: AxiosRequestConfig = {
    method: 'Put',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: interaction,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<InteractionInsertRequest>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function deleteInteraction(interactionId: number): Promise<void> {
  let url_ = 'api/Interaction/delete?interactionId=' + interactionId

  let options_: AxiosRequestConfig = {
    method: 'delete',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
