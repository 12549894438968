import { ResponseWrapper } from 'entities/types/ResponseWrapper'
import { TagInsertRequest, TagResponse } from 'generated/iTypes'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function addNewTagAction(context: Context, tag: TagInsertRequest): Promise<TagResponse | ResponseWrapper> {
  try {
    let created = await context.effects.addTag(tag)
    context.state.tags = [...context.state.tags, created]
    return created
  } catch (err) {
    console.error(err)
    return { message: 'Tag could not be added', responseType: 'Warning' }
  }
}

export async function getAllTagsAction(context: Context) {
  try {
    context.state.tags = await context.effects.getAllTags()
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
