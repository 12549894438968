import { setTopActiveScreen } from 'helpers/siteHelpers'
import { RouteNames } from 'presenter/router'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function displayReportsDashboardAction(context: Context) {
  try {
    context.state.uiConfig = await context.effects.getUIConfig()
    context.state.currentPage = 'ReportsDashboard'
    setTopActiveScreen(context, RouteNames.Reports)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
export async function reloadDashboard() {
  animateRefresh(true)
  var ifr = document.getElementById('reportsIf') as HTMLIFrameElement
  if (ifr != null) {
    let srcValue = ifr.src
    ifr.src = srcValue
    ifr.onload = function() {
      animateRefresh(false)
    }
  }
}

async function animateRefresh(flag: boolean) {
  var spin = document.getElementById('spinRep')
  var className: string
  if (spin != null) {
    if (flag) {
      className = spin.getAttribute('class')!.toString() + ' animate-spin'
      spin.setAttribute('class', className)
    } else {
      className = spin.getAttribute('class')!.toString().replace('animate-spin', '')
      spin.setAttribute('class', className)
    }
  }
}
