import { PlusIcon } from '@heroicons/react/outline'
import { Link } from 'components/atoms/Link/Link'
import { CommentDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import React, { useState } from 'react'
import { CommentCard } from '../CommentCard/CommentCard'
import { TextEditor } from '../TextEditor/TextEditor'

type TaskCommentsProps = {
  taskId?: number
  mode: 'View' | 'Create'
  comments?: CommentDetail[]
  onChange?: (comments: CommentDetail[]) => void
  placeholder?: string
}
export const TaskComments = (props: TaskCommentsProps): React.ReactElement => {
  const { addTaskCommentAction } = useActions()
  const { authenticatedUser } = useAppState()
  const [toggle, setToggle] = useState(false)

  const onAddNewComment = () => {
    setToggle(true)
  }

  const handleSaveNewComment = async (comment: string) => {
    if (props.mode === 'Create' || props.taskId === undefined) {
      let commentDate = new Date()
      let newComment = {
        value: comment,
        createdAt: commentDate,
        firstName: authenticatedUser.firstName,
        lastName: authenticatedUser.lastName,
      }
      let newComments = [newComment, ...props.comments ?? []]
      setToggle(false)
      if (props.onChange) {
        props.onChange(newComments)
      }
    } else if (props.mode === 'View' && props.taskId !== undefined) {
      const newComment = await addTaskCommentAction({ taskId: props.taskId, comment: comment })
      if (newComment !== undefined) {
        const newComments = [newComment, ...props.comments ?? []]
        setToggle(false)
        if (props.onChange) {
          props.onChange(newComments)
        }
      }
    }
  }

  const handleCancelComment = () => {
    setToggle(false)
  }

  return (
    <div>
      {toggle && (
        <>
          <TextEditor
            onSave={handleSaveNewComment}
            onCancel={handleCancelComment}
            placeholder='Enter a comment'
          />
        </>
      )}
      {!toggle && (
        <div>
          <Link
            text={'Add a comment'}
            iconleft={PlusIcon}
            onClick={onAddNewComment}
          />
          <div className='mt-2'>
            {props.comments?.map((c, index) => (
              <div className='mt-8' key={index}>
                <CommentCard
                  initials={(c.firstName?.slice(0, 1) ?? '') + '' + (c.lastName?.slice(0, 1) ?? '')}
                  date={c.createdAt ?? new Date()}
                  name={(c.firstName ?? '') + '' + (c.lastName ?? '')}
                  comment={c.value ?? ''}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
