import { LoopkUps } from 'entities/enums/LoopkUps'
import { ContactInsertRequest, ContactUpdateRequest, OfficeClosureForm } from 'generated/iTypes'
import { formatDate } from 'helpers/tasksHelper'
import { toast } from 'react-toastify'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function addNewContactAction(context: Context, contact: ContactInsertRequest) {
  try {
    context.state.newContactCreation = await context.effects.addNewContact(contact)
    context.state.contacts = await context.effects.getLookUp(
      LoopkUps.Contact,
      `${context.state.newContactCreation.firstName} ${context.state.newContactCreation.lastName}`,
    )
    context.state.currentModal = context.state.returnToEditInteractionModal ? 'EditInteractionDetailsModal' : 'None'
    context.state.returnToEditInteractionModal = false
    toast.success(`Contact: ${context.state.newContactCreation.firstName} has been created!`)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateContactAction(context: Context, contact: ContactUpdateRequest) {
  try {
    const updatedContact = await context.effects.updateContact(contact)
    context.state.newContactCreation = updatedContact
    context.state.selectedLawyer = updatedContact
    toast.success(`Contact: ${updatedContact.firstName} has been updated!`)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function downloadContactOfficeClosureFormAction(context: Context, officeClosureForm: OfficeClosureForm) {
  try {
    if (officeClosureForm.isDownloadForm) {
      return await context.effects.downloadContactOfficeClosureForm(officeClosureForm)
        .then((res) => {
          const today = formatDate(new Date().toLocaleDateString(), 'MM-DD-YYYY')
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${officeClosureForm.content?.attorneyInformation?.name}-${today}.pdf`)
          document.body.appendChild(link)
          link.click()
          toast.success(
            `The Office Closure Form for ${officeClosureForm.content?.attorneyInformation?.name} has been updated!`,
          )
        })
    } else {
      await context.effects.downloadContactOfficeClosureForm(officeClosureForm)
      toast.success(
        `The Office Closure Form for ${officeClosureForm.content?.attorneyInformation?.name} has been updated!`,
      )
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
