import React from 'react'

interface CheckboxProps {
  text: string
  value?: string
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  grayOut?: boolean
  onChange: (checked: boolean) => void
  fontWeight?: 'font-normal' | 'font-bold' | 'font-medium' | 'font-extrabold'
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div>
      <label>
        <input
          checked={props.checked}
          disabled={props.disabled}
          id='bordered-checkbox-1'
          onChange={(e) => props.onChange(e.target.checked)}
          defaultChecked={props.defaultChecked}
          type='checkbox'
          name='bordered-checkbox'
          className={`w-5 h-5 text-primary-300 rounded border-2 border-secondary-200 focus:ring-blue-500 focus:ring-2 ${
            props.grayOut ? 'opacity-[48%]' : ''
          }`}
        />
        <span
          className={`ml-2 text-primary-300 ${props.fontWeight ? props.fontWeight : 'font-normal'} ${
            props.grayOut
              ? 'opacity-[48%]'
              : ''
          }`}
        >
          {props.text}
        </span>
      </label>
    </div>
  )
}
