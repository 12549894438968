import { ForgotPasswordRequest, LoginRequest, ResetPasswordRequest, User } from 'generated/iTypes'
import { getUserToken } from 'helpers/userHelpers'
import { toast } from 'react-toastify'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function displayLoginAction(context: Context) {
  try {
    context.state.currentPage = 'Login'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayForgotPasswordAction(context: Context) {
  try {
    context.state.currentPage = 'ForgotPassword'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayResetPasswordAction(context: Context, token: string) {
  try {
    context.state.currentPage = 'ResetPassword'
    const isValid = await context.effects.validateResetPasswordToken(token)
    if (isValid !== true) {
      context.actions.redirectAction('/login')
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function loginUser(context: Context, params: LoginRequest) {
  try {
    await context.effects.loginRequest(params)
      .then(res => {
        localStorage.setItem('token', JSON.stringify(res))
        context.state.authenticatedUser = res?.currentUser as User
        context.effects.redirect('/')
      })
      .catch(err => {
        toast.error(err ?? 'The email or password you entered is invalid', { style: { marginLeft: '-30px' } })
      })
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function forgotPasswordAction(context: Context, params: ForgotPasswordRequest): Promise<boolean> {
  try {
    const emailSent = await context.effects.forgotPassword(params)
      .then(() => {
        toast.success('Email sent')
        return true
      })
      .catch(() => {
        toast.error('The email you entered is invalid', { style: { marginLeft: '-30px' } })
        return false
      })
    return emailSent
  } catch (err) {
    defaultErrorHandler(context, err)
  }
  return false
}

export async function resetPasswordAction(context: Context, params: ResetPasswordRequest) {
  try {
    await context.effects.resetPassword(params)
      .then(() => {
        toast.success('Password updated successfully')
        context.actions.redirectAction('/login')
      })
      .catch((result) => {
        toast.error(result.Error ?? 'Unable to reset password', { style: { marginLeft: '-30px' } })
      })
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function logoutUser(context: Context) {
  try {
    localStorage.removeItem('token')
    context.effects.redirect('/login')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function refreshTokenAction(context: Context) {
  const currentToken = getUserToken()

  if (currentToken?.refreshToken) {
    try {
      const result = await context.effects.refreshToken(currentToken)
      if (!result) {
        return removeLocalStorageAndRedirect('/login')
      }
      const { currentUser, ...tokens } = result
      localStorage.setItem('token', JSON.stringify(tokens))
      if (currentUser) {
        context.state.authenticatedUser = currentUser
        context.effects.redirect('/')
      } else {
        removeLocalStorageAndRedirect('/login')
      }
    } catch {
      removeLocalStorageAndRedirect('/login')
    }
  } else {
    removeLocalStorageAndRedirect('/login')
  }

  function removeLocalStorageAndRedirect(path: string) {
    localStorage.removeItem('token')
    context.effects.redirect(path)
  }
}

export async function validateAuthenticatedUser(context: Context): Promise<boolean> {
  try {
    if (context.state.publicPages.includes(context.state.currentPage)) {
      return true
    }
    const res = await context.effects.checkIfTokenIsValid()
    context.state.authenticatedUser = res
    return true
  } catch (err) {
    localStorage.removeItem('token')
    context.effects.redirect('/login')
    toast.error('Invalid session, please login ', { style: { marginLeft: '-30px' } })
    return false
  }
}
