import * as Yup from 'yup'

export const NewContactFormValidator = Yup.object().shape({
  firstName: Yup.string().required('Name is a required field'),
  personTypeId: Yup.number().required('Person type is a required field'),
  barNumber: Yup.string().optional(),
  lawFirm: Yup.string().optional(),
  address1: Yup.string().optional(),
  address2: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional().nullable(),
  zipCode: Yup.string().optional(),
  regionId: Yup.number().optional(),
  email: Yup.string().email().optional(),
  phoneNumber: Yup.string().optional(),
  notes: Yup.string().optional(),
})

export const AddEditPrefAltMethodContactInfo = Yup.object().shape({
  altContactMethod: Yup.string().nullable(),
  isAltContactPref: Yup.bool().optional(),
  personTypeId: Yup.number().optional(),
})
