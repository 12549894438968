import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { EmailOrAddressRequest } from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getEmailOrAddressList(data: EmailOrAddressRequest): Promise<any> {
  let url_ = 'api/Universe/getEmailOrAddress'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: data,
    responseType: 'blob',
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return response
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
