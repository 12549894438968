import { LoopkUps } from 'entities/enums/LoopkUps'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function loadLookUpsAction(context: Context, contactTerm?: string) {
  try {
    context.state.areasOfAssistance = await context.effects.getLookUp(LoopkUps.AreaOfAssistance)
    context.state.interactionTypes = await context.effects.getLookUp(LoopkUps.InteractionType)
    context.state.contacts = await context.effects.getLookUp(LoopkUps.Contact, contactTerm)
    context.state.methodsOfContact = await context.effects.getLookUp(LoopkUps.MethodOfContact)
    context.state.pmaContacts = await context.effects.getLookUp(LoopkUps.PMAContacts)
    context.state.regions = await context.effects.getLookUp(LoopkUps.Region)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateContacts(context: Context, value: string) {
  try {
    context.state.loadingContacts = true
    context.state.contacts = await context.effects.getLookUp(LoopkUps.Contact, value, context.state.resultCount)
  } catch (err) {
    defaultErrorHandler(context, err)
  } finally {
    context.state.loadingContacts = false
  }
}

export async function showMoreContacts(context: Context, value: { term: string; resultCount: number }) {
  try {
    const result = await context.effects.getLookUp(LoopkUps.Contact, value.term, value.resultCount)
    const uniqueNewContacts = [...result].filter(contact1 =>
      !context.state.contacts.some(contact2 => contact1.itemId === contact2.itemId)
    )
    if (uniqueNewContacts.length > 0) {
      context.state.contacts = [...context.state.contacts, ...uniqueNewContacts]
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
