import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'
import { PaginationComponent, PaginationComponentProps } from 'react-data-table-component/dist/src/DataTable/types'

export const TablePaginationComponent: PaginationComponent = (props: PaginationComponentProps) => {
  const totalPageAmount = Math.ceil(props.rowCount / props.rowsPerPage)
  return (
    <>
      <div className='flex px-2 py-3 justify-between'>
        <div className='font-semibold'>
          Page {props.currentPage} of {totalPageAmount}
        </div>
        <div className='flex font-semibold gap-4'>
          {(props.currentPage !== 1 && props.currentPage > 0)
            && (
              <button className='flex' onClick={() => props.onChangePage(props.currentPage - 1, 6)}>
                <ArrowCircleLeftIcon className='h-7 w-7 mr-2' /> Prev
              </button>
            )}
          {props.currentPage !== totalPageAmount
            && (
              <button className='flex' onClick={() => props.onChangePage(props.currentPage + 1, 6)}>
                Next <ArrowCircleRightIcon className='h-7 w-7 ml-2' />
              </button>
            )}
        </div>
      </div>
    </>
  )
}
