import { AxiosError } from 'axios'
import { ConflictError, UnauthenticatedError, UnexpectedError } from 'presenter/errors'

export function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any,
): any {
  if (result !== null && result !== undefined) {
    throw result
  } else {
    if (status === 401) throw new UnauthenticatedError()
    if (status === 404) throw new UnexpectedError()
    if (status === 409) throw new ConflictError(message)
    if (status === 501 || status === 500) throw new UnexpectedError()
  }
}

export function isAxiosError(obj: any | undefined): obj is AxiosError {
  return obj && obj.isAxiosError === true
}
