import { LoopkUps } from 'entities/enums/LoopkUps'
import { TaskPageRequestParameters, TaskSummaryFilters } from 'entities/types/Tasks'
import { CommentDetail, SubTaskDetail, TaskComment, TaskDetail, TaskResponse, User } from 'generated/iTypes'
import { setSideActiveScreen, setTopActiveScreen } from 'helpers/siteHelpers'
import { filterTaskSummary, formatDate } from 'helpers/tasksHelper'
import { RouteNames } from 'presenter/router'
import { toast } from 'react-toastify'
import { Context } from '..'
import { defaultErrorHandler, setSelectedCompletedTasks, setSelectedUpcomingTasks } from './actions'

export async function displayCreateTaskAction(context: Context) {
  try {
    context.state.currentSlideOut = 'CreateTaskSlideOut'
    setSideActiveScreen(context, RouteNames.CreateTask)
    context.state.allTaskTemplates = await context.effects.getAllTaskTemplates()
    context.state.tags = await context.effects.getAllTags()
    context.state.users = await context.effects.getAllUsers()
    context.state.taskStatus = await context.effects.getLookUp(LoopkUps.TaskStatus)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayTasksDashboardAction(context: Context) {
  try {
    setTopActiveScreen(context, RouteNames.Tasks)
    context.state.currentPage = 'Loading'
    context.state.users = await context.effects.getAllUsers()
    context.state.tags = await context.effects.getAllTags()
    context.state.allTaskTemplates = await context.effects.getAllTaskTemplates()
    context.state.taskStatus = await context.effects.getLookUp(LoopkUps.TaskStatus)
    var curr = new Date()

    context.state.tasksFilters = {
      fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
      assignedToIds: context.state.authenticatedUser.userId?.toString() ?? '',
      taskTypes: context.state.taskTypes.map(t => ({ taskType: t, checked: false })),
      taskStatus: context.state.taskStatus.map(t => ({ taskStatus: t, checked: false })),
      tags: context.state.tags.map(t => ({ tag: t, checked: false })),
    }
    context.state.allTaskSummary = filterTaskSummary(
      await context.effects.getAllTaskSummary(context.state.tasksFilters),
      context.state.tasksFilters,
    )
    context.state.pagedTasksArray = context.state.allTaskSummary.map(() => ({}))
    context.state.tasksPastDuePaged = await context.effects.getTasksPastDuePage({
      pageNumber: 1,
      pageSize: context.state.maxAmountItemsPerPage,
      orderBy: 'dueDate',
      orderDirection: 'DESC',
      assignedToIds: context.state.authenticatedUser.userId?.toString() ?? '',
    })
    context.state.currentPage = 'TasksDashboard'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function fetchTasksPaginationAction(
  context: Context,
  value: { index: number; pageNumber: number; pageSize: number; date: Date },
) {
  const response = await getTasksPageResponse(context, {
    pageNumber: value.pageNumber,
    pageSize: value.pageSize,
    fromDate: formatDate(value.date.toString(), 'YYYY-MM-DD'),
    toDate: formatDate(value.date.toString(), 'YYYY-MM-DD'),
    assignedToIds: context.state.tasksFilters.assignedToIds,
    taskTypes: context.state.tasksFilters.taskTypes?.filter(t => t.checked).map(t => t.taskType).join(','),
    taskStatus: context.state.tasksFilters.taskStatus?.filter(t => t.checked).map(t => t.taskStatus.itemId).join(','),
    tags: context.state.tasksFilters.tags?.filter(t => t.checked).map(t => t.tag.id).join(','),
    noTags: context.state.tasksFilters.noTags,
  })
  if (response) {
    context.state.pagedTasksArray = context.state.pagedTasksArray.map((i, idx) => idx === value.index ? response : i)
  }
}

export async function filterPastDueTasks(
  context: Context,
  value: TaskSummaryFilters,
) {
  try {
    context.state.tasksPastDuePaged = await context.effects.getTasksPastDuePage({
      pageNumber: 1,
      pageSize: context.state.maxAmountItemsPerPage,
      orderBy: 'dueDate',
      orderDirection: 'DESC',
      assignedToIds: value.assignedToIds,
      tags: value.tags?.filter(t => t.checked).map(t => t.tag.id).join(','),
      taskStatus: value.taskStatus?.filter(t => t.checked).map(t => t.taskStatus.itemId).join(','),
      taskTypes: value.taskTypes?.filter(t => t.checked).map(t => t.taskType).join(','),
      noTags: value.noTags,
    })
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function filterTasks(
  context: Context,
  value: TaskSummaryFilters,
) {
  try {
    context.state.loadingTaskSummary = true
    context.state.allTaskSummary = filterTaskSummary(
      await context.effects.getAllTaskSummary(context.state.tasksFilters),
      context.state.tasksFilters,
    )
    context.state.pagedTasksArray = context.state.allTaskSummary.map(() => ({}))
    await filterPastDueTasks(context, value)
    context.state.loadingTaskSummary = false
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setTaskSummaryFilters(
  context: Context,
  value: TaskSummaryFilters,
) {
  try {
    const filters = { ...context.state.tasksFilters, ...value }
    context.state.tasksFilters = filters
    await filterTasks(context, filters)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setUsersFilters(
  context: Context,
  value: User[],
) {
  try {
    const userIds = value.map(u => u.userId).join(',')
    const filters = { ...context.state.tasksFilters, assignedToIds: userIds }
    context.state.tasksFilters = filters
    await filterTasks(context, filters)
    await filterPastDueTasks(context, filters)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setTasksPage(
  context: Context,
  value?: TaskPageRequestParameters,
) {
  try {
    const taskPage = await getTasksPageResponse(context, value)
    if (taskPage) context.state.tasksPaged = taskPage
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getTasksPageResponse(
  context: Context,
  value?: TaskPageRequestParameters,
) {
  try {
    return await context.effects.getTasksPage(value)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setTaskPastDuePage(
  context: Context,
  value?: TaskPageRequestParameters,
) {
  try {
    context.state.tasksPastDuePaged = await await context.effects.getTasksPastDuePage(value)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setSelectedTask(context: Context, task: TaskResponse) {
  try {
    context.state.selectedTask = task
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayTaskDetailsModal(context: Context, task: TaskResponse) {
  try {
    context.state.currentModal = 'TaskCardDetailsModal'
    context.state.isCreateNewTaskFormDirty = false
    context.state.loadingTaskDetails = true
    context.state.selectedTask = { ...task }
    if ((task.parentId ?? 0) > 0) {
      context.state.selectedSubTaskDetails = await context.effects.getTaskDetails(task.taskId ?? 0)
      context.state.selectedTaskDetails = await context.effects.getTaskDetails(task.parentId ?? 0)
      context.state.subTaskDirectSelect = true
    } else {
      context.state.selectedTaskDetails = await context.effects.getTaskDetails(task.taskId ?? 0)
      context.state.selectedSubTaskDetails = {}
      context.state.subTaskDirectSelect = false
    }
    context.state.loadingTaskDetails = false
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayNewTaskFromTemplateModal(context: Context, task: TaskDetail) {
  context.state.currentModal = 'CreateNewTaskModal'
  context.state.isCreateNewTaskFormDirty = false
  context.state.loadingTaskDetails = true
  context.state.selectedTaskDetails = task
  context.state.selectedSubTaskDetails = {}
  context.state.subTaskDirectSelect = false
  context.state.loadingTaskDetails = false
}

export async function loadSubTaskDetailsAction(
  context: Context,
  value: { taskId: number | undefined; subTask: SubTaskDetail },
) {
  if (value.taskId === undefined || value.taskId === 0) {
    context.state.selectedSubTaskDetails = { ...value.subTask }
  } else {
    context.state.selectedSubTaskDetails = await context.effects.getTaskDetails(value.taskId)
  }
}

export async function addNewTaskAction(context: Context, task: TaskDetail): Promise<TaskDetail | undefined> {
  try {
    context.state.autoSaving = true
    let created = await context.effects.addNewTask(task)
    context.state.allTaskSummary = filterTaskSummary(
      await context.effects.getAllTaskSummary(context.state.tasksFilters),
      context.state.tasksFilters,
    )
    context.state.pagedTasksArray = context.state.allTaskSummary.map(() => ({}))
    context.state.autoSaving = false
    if (context.state.selectedLawyer) {
      var curr = new Date()
      let upcommingTaskStatuses = context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item =>
        item.itemId
      ).join(',')
      let completedTaskStatus = context.state.taskStatus.filter(item => item.itemText === 'Done').map(item =>
        item.itemId
      ).join(',')
      const pastDueTaskStatus = context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item =>
        item.itemId
      ).join(',')

      context.state.tasksPastDuePaged = await context.effects.getTasksPastDuePage({
        pageNumber: 1,
        pageSize: context.state.maxAmountItemsPerPage,
        contactId: context.state.selectedLawyer.contactId,
        statusIds: pastDueTaskStatus,
        orderBy: 'dueDate',
        orderDirection: 'DESC',
      })

      await context.actions.setSelectedUpcomingTasks({
        fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
        pageNumber: 1,
        pageSize: context.state.maxAmountItemsPerPage,
        contactId: context.state.selectedLawyer.contactId,
        statusIds: upcommingTaskStatuses,
      })
      await context.actions.setSelectedCompletedTasks({
        pageNumber: 1,
        pageSize: context.state.maxAmountItemsPerPage,
        contactId: context.state.selectedLawyer.contactId,
        statusIds: completedTaskStatus,
      })
    } else {
      await setTasksPage(context, {
        pageNumber: 1,
        pageSize: 3,
        orderBy: 'dueDate',
        orderDirection: 'ASC',
        fromDate: formatDate(new Date().toString(), 'MM-DD-YYYY'),
        statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
          ',',
        ),
        assignedToIds: context.state.authenticatedUser.userId?.toString(),
      })
    }
    if (context.state.currentPage === 'Dashboard') {
      context.state.taskStatistics = await context.effects.getTaskStatistics(
        context.state.authenticatedUser.userId,
        context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(','),
      )
    }
    context.actions.displaySlideOutAction('None')
    toast.success('Task saved successfully!')
    return created
  } catch (err) {
    context.state.autoSaving = false
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function addNewSubTaskAction(context: Context, subTask: SubTaskDetail): Promise<TaskDetail | undefined> {
  try {
    let created = await context.effects.addNewSubTask(subTask)
    return created
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function saveTaskAction(
  context: Context,
  value: { task: TaskDetail; shouldRefreshSummary: boolean },
): Promise<TaskDetail | undefined> {
  try {
    context.state.autoSaving = true
    const updated = await context.effects.saveTask(value.task)
    if (context.state.currentPage === 'TasksDashboard') {
      await filterTasks(context, context.state.tasksFilters)
    } else if (context.state.currentPage === 'Dashboard') {
      await setTasksPage(context, {
        pageNumber: 1,
        pageSize: 3,
        orderBy: 'dueDate',
        orderDirection: 'ASC',
        fromDate: formatDate(new Date().toString(), 'MM-DD-YYYY'),
        statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
          ',',
        ),
        assignedToIds: context.state.authenticatedUser.userId?.toString(),
      })
      context.state.taskStatistics = await context.effects.getTaskStatistics(
        context.state.authenticatedUser.userId,
        context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(','),
      )
    } else if (context.state.currentPage === 'LawyerProfile') {
      var curr = new Date()
      context.state.tasksPastDuePaged = await context.effects.getTasksPastDuePage({
        pageNumber: 1,
        pageSize: context.state.maxAmountItemsPerPage,
        contactId: context.state.selectedLawyer?.contactId,
        statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
          ',',
        ),
        orderBy: 'dueDate',
        orderDirection: 'DESC',
      })
      await setSelectedUpcomingTasks(context, {
        fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
        pageNumber: 1,
        pageSize: context.state.maxAmountItemsPerPage,
        contactId: context.state.selectedLawyer?.contactId,
        statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
          ',',
        ),
        orderDirection: 'ASC',
      })
      await setSelectedCompletedTasks(context, {
        pageNumber: 1,
        pageSize: context.state.maxAmountItemsPerPage,
        contactId: context.state.selectedLawyer?.contactId,
        statusIds: context.state.taskStatus.filter(item => item.itemText === 'Done').map(item => item.itemId).join(
          ',',
        ),
      })
    }
    context.state.autoSaving = false
    return updated
  } catch (err) {
    context.state.autoSaving = false
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function deleteTaskAction(
  context: Context,
  value: { taskId: number; shouldRefresh: boolean },
): Promise<void> {
  try {
    await context.effects.deleteTask(value.taskId)
    if (value.shouldRefresh) {
      context.state.allTaskSummary = filterTaskSummary(
        await context.effects.getAllTaskSummary(context.state.tasksFilters),
        context.state.tasksFilters,
      )
      context.state.pagedTasksArray = context.state.allTaskSummary.map(() => ({}))
      if (!context.state.selectedLawyer) {
        await setTasksPage(context, {
          pageNumber: 1,
          pageSize: 3,
          orderBy: 'dueDate',
          orderDirection: 'ASC',
          fromDate: formatDate(new Date().toString(), 'MM-DD-YYYY'),
          statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
            ',',
          ),
          assignedToIds: context.state.authenticatedUser.userId?.toString(),
        })
      } else {
        context.state.tasksPastDuePaged = await context.effects.getTasksPastDuePage({
          pageNumber: 1,
          pageSize: context.state.maxAmountItemsPerPage,
          contactId: context.state.selectedLawyer.contactId,
          orderBy: 'dueDate',
          orderDirection: 'DESC',
        })
        var curr = new Date()

        await context.actions.setSelectedUpcomingTasks({
          fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
          pageNumber: 1,
          pageSize: context.state.maxAmountItemsPerPage,
          contactId: context.state.selectedLawyer.contactId,
          statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
            ',',
          ),
        })
        await context.actions.setSelectedUpcomingTasks({
          fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
          pageNumber: 1,
          pageSize: context.state.maxAmountItemsPerPage,
          contactId: context.state.selectedLawyer.contactId,
          statusIds: context.state.taskStatus.filter(item => item.itemText === 'Done').map(item => item.itemId).join(
            ',',
          ),
        })
      }
    }
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function addTaskCommentAction(
  context: Context,
  taskComment: TaskComment,
): Promise<CommentDetail | undefined> {
  try {
    const created = await context.effects.addTaskComment(taskComment)
    return created
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}

export async function setIsCreateNewTaskFormDirty(context: Context, value: boolean) {
  try {
    context.state.isCreateNewTaskFormDirty = value
  } catch (err) {
    defaultErrorHandler(context, err)
    return undefined
  }
}
