export interface CardProps {
  children: React.ReactNode
  width?: string
  onClick?: () => void
  borderStyle?: string
}

export const Card = ({
  width = 'w-full',
  ...props
}: CardProps) => {
  return (
    <div className={`flex ${width}`}>
      <div
        className={`block w-full rounded-xl bg-white text-center border border-outlineLightBlue ${
          props.borderStyle ?? ''
        }`}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    </div>
  )
}
