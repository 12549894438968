import { Tab, Transition } from '@headlessui/react'
import { CalendarIcon, ChatIcon, DotsHorizontalIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import { Dropdown } from 'components/atoms/Dropdown/Dropdown'
import TextInput from 'components/atoms/TextInput/TextInput'
import { ConfirmationCard } from 'components/molecules/ConfirmationCard/ConfirmationCard'
import { StatusSelector } from 'components/molecules/StatusSelector/StatusSelector'
import { TagSelector } from 'components/molecules/TagSelector/TagSelector'
import { TaskComments } from 'components/molecules/TaskEditor/TaskComments'
import { TaskDescription } from 'components/molecules/TaskEditor/TaskDescription'
import { TaskSubTasks } from 'components/molecules/TaskEditor/TaskSubTasks'
import { UserSelector } from 'components/molecules/UserSelector/UserSelector'
import { ComboBoxItem, CommentDetail, SubTaskDetail, TagResponse, TaskDetail, User } from 'generated/iTypes'
import { capitalizeFirstLetter } from 'helpers/stringHelpers'
import { deepEqual, formatDate } from 'helpers/tasksHelper'
import { useActions, useAppState } from 'presenter'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { ValidationError } from 'yup'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'
import { SubTaskEditor } from '../../molecules/SubTaskEditor/SubTaskEditor'

type TaskEditorProps = {
  mode: 'View' | 'Create'
  task?: TaskDetail
  selectedTab?: number
  onSaved?: (shouldClose: boolean) => void
}

export const TaskEditor = (props: TaskEditorProps) => {
  const { selectedSubTaskDetails, autoSaving } = useAppState()
  const { addNewTaskAction, saveTaskAction, deleteTaskAction, setIsCreateNewTaskFormDirty } = useActions()
  const [task, setTask] = useState<TaskDetail | undefined>(props.task)
  const [formValid, setFormValid] = useState<boolean>(true)
  const [savingTask, setSavingTask] = useState<boolean>(false)
  const [subTaskEditorMode, setSubTaskEditorMode] = useState<'Create' | 'View'>(
    selectedSubTaskDetails.id !== undefined ? 'View' : 'Create',
  )
  const [showSubTaskEditor, setShowSubTaskEditor] = useState(selectedSubTaskDetails.id !== undefined)
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ?? 0)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [subTaskIndex, setSubTaskIndex] = useState<number | undefined>()

  const dueDateSchema = yup.date().required()
  const nameSchema = yup.string().required()
  const assigneeSchema = yup.number().required()
  const statusSchema = yup.number().required()
  const taskDetailsSchema = yup.string().required()

  let schema = yup.object().shape({
    dueDate: dueDateSchema,
    name: nameSchema,
    userId: assigneeSchema,
    statusId: statusSchema,
    taskDetails: taskDetailsSchema,
  })

  const isFormValid = () => {
    try {
      let toValidate = {
        dueDate: task?.dueDate,
        name: task?.name,
        userId: task?.assignedTo?.userId,
        statusId: task?.taskStatus?.itemId,
        taskDetails: task?.taskDetails,
      }
      schema.validateSync(toValidate, { abortEarly: false })
      return true
    } catch (e) {
      console.dir(e)
      return false
    }
  }

  const validateDate = (value: string | undefined) => {
    try {
      dueDateSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateName = (value: string | undefined) => {
    try {
      nameSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateAssignee = (value: string | undefined) => {
    try {
      assigneeSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)[0].toUpperCase() + e.message.slice(1)
      }
    }
  }

  const validateStatus = (value: string | undefined) => {
    try {
      statusSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateTaskDetails = (value: string | undefined) => {
    try {
      taskDetailsSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const handleTaskStatusChanged = (taskStatus: ComboBoxItem) => {
    let updated = { ...task, taskStatus: taskStatus }
    setTask(updated)
    if (props.mode === 'View') {
      handleSave(updated, false)
    }
    setIsCreateNewTaskFormDirty(true)
  }

  const handleUserChanged = (user: User) => {
    let updated = { ...task, assignedTo: user }
    setTask(updated)
    if (props.mode === 'View') {
      handleSave(updated, false)
    }
    setIsCreateNewTaskFormDirty(true)
  }

  const handleTagsChanged = (tags: TagResponse[]) => {
    let updated = { ...task, tags: tags }
    setTask(updated)
    if (props.mode === 'View') {
      saveTaskAction({ task: updated, shouldRefreshSummary: false })
    }
    setIsCreateNewTaskFormDirty(true)
  }

  const handleSave = async (task: TaskDetail | undefined, shouldClose: boolean) => {
    if (task !== undefined) {
      let valid = isFormValid()
      setFormValid(valid)
      if (valid) {
        setSavingTask(true)
        if (props.mode === 'Create') {
          const created = await addNewTaskAction(task)
          if (!created) {
            toast.error('Error creating task')
          }
        } else if (props.mode === 'View') {
          await saveTaskAction({ task: task, shouldRefreshSummary: shouldClose })
        }
        setSavingTask(false)
        if (props.onSaved) {
          props.onSaved(shouldClose)
        }
      }
    }
  }

  const handleContactChange = (contact: ComboBoxItem) => {
    setTask({ ...task, contact: contact })
  }

  const handleTaskDetailsChange = (taskDetails: string) => {
    setTask({ ...task, taskDetails: taskDetails })
  }

  const handleShowSubTaskEditor = (mode: 'Create' | 'View', index?: number) => {
    setSubTaskEditorMode(mode)
    setShowSubTaskEditor(true)
    setSubTaskIndex(index)
  }

  const handleSubTaskSaved = async (subTask: SubTaskDetail) => {
    if (props.mode === 'Create') {
      if (subTaskEditorMode === 'View') {
        if (subTaskIndex !== undefined) {
          let subTasks = task?.subTasks?.map((st, index) => index === subTaskIndex ? subTask : st)
          setTask({ ...task, subTasks: subTasks })
        }
      } else if (subTaskEditorMode === 'Create') {
        let subTasks = [...task?.subTasks ?? [], subTask]
        setTask({ ...task, subTasks: subTasks })
      }
    } else if (props.mode === 'View') {
      let subTasks = task?.subTasks?.map(st => st.id === subTask.id ? subTask : st)
      if (subTaskEditorMode === 'Create') {
        subTasks = [...subTasks ?? [], subTask]
      }
      setTask({ ...task, subTasks: subTasks })
      handleSave(task, false)
    }
  }

  const handleSubTaskExit = (subTask: SubTaskDetail | undefined, exitBehavior: 'None' | 'Back' | 'Close') => {
    switch (exitBehavior) {
      case 'Back':
        setSelectedTab(1)
        setShowSubTaskEditor(false)
        break
      case 'Close':
        if (props.onSaved) {
          props.onSaved(true)
        }
        break
      case 'None':
        break
    }
  }

  const handleSubTaskRemoved = (subTask: SubTaskDetail) => {
    let subTasks = task?.subTasks
    if (subTask.id !== undefined) {
      subTasks = task?.subTasks?.filter(st => st.id !== subTask.id)
    } else {
      subTasks = task?.subTasks?.filter(st => !deepEqual(st, subTask))
    }
    setTask({ ...task, subTasks: subTasks })
    handleSave(task, false)
  }

  const handleCommentsChange = (value: CommentDetail[]) => {
    setTask({ ...task, comments: value })
    handleSave(task, false)
  }

  const handleDelete = async () => {
    if (task?.id !== undefined) {
      await deleteTaskAction({ taskId: task.id, shouldRefresh: true })
      toast.success('Task deleted')
      if (props.onSaved) {
        props.onSaved(true)
      }
    }
  }

  return (
    <div className='h-full'>
      <Transition
        as={Fragment}
        show={!showSubTaskEditor}
        enter='transform transition ease-out duration-200 sm:duration-200'
        enterFrom='opacity-0 -translate-x-1/3'
        enterTo='opacity-100 -translate-x-0'
      >
        <div className='flex flex-col pl-2 h-full'>
          <div className='overflow-y-auto pr-2'>
            <div className='text-primary-300 text-sm font-semibold mb-2 text-left mb-4'>
              <div className='flex items-center w-full'>
                <CalendarIcon className='w-5 h-5'></CalendarIcon>
                <span className='pl-2 inline-block'>Due:</span>
                <input
                  value={formatDate(task?.dueDate?.toString() ?? '', 'YYYY-MM-DD')}
                  onChange={e => {
                    setTask({ ...task, dueDate: new Date(e.target.value) })
                    setIsCreateNewTaskFormDirty(true)
                  }}
                  type='date'
                  className='border border-none text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 blocks w-[150px]'
                />
              </div>
              {!formValid && validateDate(task?.dueDate?.toString()) !== ''
                ? (
                  <div>
                    <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {validateDate(task?.dueDate?.toString())}
                    </span>
                  </div>
                )
                : null}
            </div>
            <div>
              <div className='flex'>
                <TextInput
                  value={task?.name}
                  onChange={(e) => {
                    setTask({ ...task, name: e.target.value })
                    setIsCreateNewTaskFormDirty(true)
                  }}
                  placeholder='Title'
                />
                {props.mode === 'View' && (
                  <div>
                    <Dropdown
                      icon={() => <DotsHorizontalIcon className='h-5 w-10' />}
                      label={''}
                      data={[{
                        icon: () => <></>,
                        name: 'Remove Task',
                        onClick: () => {
                          setShowDeleteConfirmation(true)
                        },
                      }]}
                    />
                  </div>
                )}
              </div>
              {!formValid && validateName(task?.name) !== ''
                ? (
                  <div>
                    <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {validateName(task?.name)}
                    </span>
                  </div>
                )
                : null}
            </div>
            <div className='mt-6'>
              <div className='grid grid-cols-6'>
                <div className='col-span-1 font-bold'>Asignee</div>
                <div className='col-span-5'>
                  <UserSelector
                    value={task?.assignedTo}
                    onChange={handleUserChanged}
                    valid={formValid}
                    errorMessage={validateAssignee(task?.assignedTo?.userId?.toString())}
                  />
                </div>
              </div>
              <div className='grid grid-cols-6 mt-4'>
                <div className='col-span-1 font-bold'>Status</div>
                <div className='col-span-5'>
                  <StatusSelector
                    value={task?.taskStatus}
                    onChange={handleTaskStatusChanged}
                    valid={formValid}
                    errorMessage={validateStatus(task?.taskStatus?.itemId?.toString())}
                  />
                </div>
              </div>
              <div className='grid grid-cols-6 mt-4'>
                <div className='col-span-1 font-bold'>Tags</div>
                <div className='col-span-5'>
                  <TagSelector
                    value={task?.tags}
                    onChange={handleTagsChanged}
                  />
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <Tab.Group onChange={(i) => setSelectedTab(i)} selectedIndex={selectedTab}>
                <Tab.List>
                  <Tab className=''>
                    {({ selected }) => (
                      <div
                        className={selected
                          ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                          : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                      >
                        Description
                      </div>
                    )}
                  </Tab>
                  <Tab className=''>
                    {({ selected }) => (
                      <div
                        className={selected
                          ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                          : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                      >
                        <div className='flex'>
                          Subtasks
                          {(task?.subTasks?.length ?? 0) > 0 && (
                            <div
                              className={'ml-2 h-6 w-6 rounded-full text-xs font-extrabold h-full grid place-items-center border-2 '
                                + (selected ? ' border-primary-100' : '')}
                            >
                              {task?.subTasks?.length}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Tab>
                  {(task?.id ?? 0) > 0 && (
                    <Tab className=''>
                      {({ selected }) => (
                        <div
                          className={selected
                            ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                            : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                        >
                          <div className='flex'>
                            Comments
                            {(task?.comments?.length ?? 0) > 0 && (
                              <div className='flex'>
                                <div className='ml-2 h-6 w-6 text-xs font-extrabold h-full grid place-items-center'>
                                  {task?.comments?.length}
                                </div>
                                <ChatIcon />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                  )}
                </Tab.List>
                <div className='relative border-b border-b-outlineLightBlue' />
                <Tab.Panels className='mt-4'>
                  <Tab.Panel>
                    <div className='pb-10 mb-20'>
                      <TaskDescription
                        mode={props.mode}
                        contact={task?.contact}
                        taskDetails={task?.taskDetails}
                        onContactChange={handleContactChange}
                        onTaskDetailsChange={handleTaskDetailsChange}
                        taskDetailsValid={formValid}
                        taskDetailsErrorMessage={validateTaskDetails(task?.taskDetails)}
                        onSave={() => (task?.id ?? 0) > 0 ? handleSave(task, false) : handleSave(task, true)}
                      />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className='pb-10 mb-20'>
                      <TaskSubTasks
                        subTasks={task?.subTasks}
                        onShowEditor={handleShowSubTaskEditor}
                      />
                    </div>
                  </Tab.Panel>
                  {(task?.id ?? 0) > 0 && (
                    <Tab.Panel>
                      <div className='mb-20'>
                        <TaskComments
                          taskId={task?.id}
                          comments={task?.comments}
                          mode={props.mode}
                          onChange={handleCommentsChange}
                          placeholder='Comment'
                        />
                      </div>
                    </Tab.Panel>
                  )}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <div className='absolute flex justify-between py-4 bottom-0 right-0 w-full bg-white'>
            <div className='pt-4'>
              {autoSaving && (
                <>
                  <SpinnerSVG className='inline mr-2 w-3 h-3 text-gray-200 animate-spin fill-primary-200' />
                  <span className='text-sm text-secondary-200'>saving</span>
                </>
              )}
            </div>
            <div className=''>
              <Button className='w-60 h-12' onClick={() => handleSave(task, true)} disabled={savingTask}>
                {savingTask
                  ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                  : (
                    <span>
                      {props.mode === 'Create' ? 'Create Task' : 'Done'}
                    </span>
                  )}
              </Button>
            </div>
          </div>
          <div className='absolute bottom-0 right-0 w-full z-40 bg-white'>
            <Transition
              as={Fragment}
              show={showDeleteConfirmation}
              enter='transform transition ease-out duration-200 sm:duration-200'
              enterFrom='opacity-0 translate-x-full'
              enterTo='opacity-100 translate-x-0'
            >
              <div>
                <ConfirmationCard
                  text='Are you sure you want to remove this task?'
                  confirmText='Yes'
                  cancelText='No'
                  confirmButtonStyle='secondary'
                  cancelButtonStyle='secondary'
                  onConfirm={handleDelete}
                  onCancel={() => setShowDeleteConfirmation(false)}
                />
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
      <Transition
        as={Fragment}
        show={showSubTaskEditor}
        enter='transform transition ease-out duration-200 sm:duration-200'
        enterFrom='opacity-0 translate-x-1/3'
        enterTo='opacity-100 translate-x-0'
      >
        <div className='h-full w-full'>
          <SubTaskEditor
            parentId={task?.id}
            contact={task?.contact}
            parentName={task?.name}
            subTask={selectedSubTaskDetails}
            subTaskIndex={subTaskIndex}
            mode={subTaskEditorMode}
            onSaved={handleSubTaskSaved}
            onExit={handleSubTaskExit}
            onRemoved={handleSubTaskRemoved}
          />
        </div>
      </Transition>
    </div>
  )
}
