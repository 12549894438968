import { Modal } from 'components/molecules'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { TaskTemplateEditor } from '../TaskTemplateEditor/TaskTemplateEditor'
import { ModalLeaveWarning } from './ModalLeaveWarning'

export const CreateNewTaskTemplateModal = () => {
  const { currentModal, isCreateNewTaskFormDirty } = useAppState()
  const { displayModalAction, setIsCreateNewTaskFormDirty } = useActions()
  const [closing, setClosing] = useState<boolean>(false)

  const onClose = async () => {
    setClosing(true)
    if (isCreateNewTaskFormDirty) return setIsCreateNewTaskFormDirty(true)
    displayModalAction('None')
  }

  const onCancel = () => displayModalAction('None')

  return (
    <Modal
      isOpen={currentModal === 'CreateNewTaskTemplateModal'}
      onClose={onClose}
      shouldCloseOnEsc
      contentStyle={{
        width: '550px',
      }}
    >
      <div className='h-[calc(100vh-80px)]'>
        <TaskTemplateEditor
          mode='Create'
          taskTemplate={{}}
          onSaved={(shouldClose) => shouldClose ? onCancel() : undefined}
        />
        <ModalLeaveWarning
          onClickNo={() => setClosing(false)}
          onClickYes={onCancel}
          show={isCreateNewTaskFormDirty && closing}
        />
      </div>
    </Modal>
  )
}
