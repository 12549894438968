import { InformationCircleIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import { Avatar } from 'components/atoms/Avatar/Avatar'
import { Link } from 'components/atoms/Link/Link'
import { User } from 'generated/iTypes'
import { getMappedAvatarColor } from 'helpers/tasksHelper'
import { useAppState } from 'presenter'
import React, { useEffect, useRef, useState } from 'react'

type UserSelectorProps = {
  value?: User
  onChange?: (user: User) => void
  valid?: boolean
  errorMessage?: string
}
export const UserSelector = (props: UserSelectorProps): React.ReactElement => {
  const { users } = useAppState()
  const [toggleUser, setToggleUser] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<User>(props.value!)
  const selectorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    toggleUser && selectorRef.current?.focus()
  }, [toggleUser])

  const handleSelectUser = (user: User) => {
    setSelectedUser(user)
    setToggleUser(false)
    if (props.onChange) {
      props.onChange(user)
    }
  }

  return (
    <div tabIndex={2} ref={selectorRef} onBlur={() => setToggleUser(false)}>
      <div className=''>
        {selectedUser
          ? (
            <>
              <span className='flex hover:cursor-pointer' onClick={() => setToggleUser(!toggleUser)}>
                <Avatar
                  initials={(selectedUser.firstName !== undefined && selectedUser.lastName !== undefined)
                    ? selectedUser.firstName[0] + '' + selectedUser.lastName[0]
                    : ''}
                  color={getMappedAvatarColor(selectedUser?.firstName.slice(0, 1) ?? '')}
                />&nbsp;&nbsp;
                <span className='font-extrabold mt-1'>
                  {selectedUser.firstName + ' ' + selectedUser.lastName}
                </span>
              </span>
            </>
          )
          : (
            <div>
              <Link
                onClick={() => setToggleUser(!toggleUser)}
                text='Add assignee'
                iconleft={toggleUser ? MinusIcon : PlusIcon}
              />
              {!props.valid && props.errorMessage !== ''
                ? (
                  <div>
                    <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {props.errorMessage}
                    </span>
                  </div>
                )
                : null}
            </div>
          )}
      </div>
      {toggleUser
        && (
          <div className='absolute flex z-10'>
            <div className='block rounded-xl shadow-xl bg-white text-center w-60'>
              <div className='flex flex-col h-80 w-60 gap-6 inline py-4 px-4 overflow-y-auto overflow-x-hidden'>
                {users?.map((u, index) => (
                  <span
                    className='flex hover:cursor-pointer'
                    key={index}
                    onClick={() => handleSelectUser(u)}
                  >
                    <Avatar
                      initials={(u.firstName !== undefined && u.lastName !== undefined)
                        ? u.firstName[0] + '' + u.lastName[0]
                        : ''}
                      color={getMappedAvatarColor(u.firstName.slice(0, 1) ?? '')}
                    />&nbsp;&nbsp;
                    <span className='font-bold mt-1'>{u.firstName}&nbsp;{u.lastName}</span>
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
    </div>
  )
}
