import { InformationCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import Select from 'components/atoms/Select/Select'
import { SubTaskSelection } from 'entities/types/Tasks'
import { TaskTemplateDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import React, { useState } from 'react'
import { SubTasksSelector } from '../SubTasksSelector/SubTasksSelector'

type TemplateSelectorProps = {
  isFromSideOut?: boolean
  selectedTaskTemplate?: TaskTemplateDetail
  selectedSubTasksTemplate?: SubTaskSelection[]
  onConfigured?: (taskTemplate: TaskTemplateDetail, subTasks: SubTaskSelection[]) => void
}
export const TemplateSelector = (props: TemplateSelectorProps): React.ReactElement => {
  const { allTaskTemplates } = useAppState()
  const { setIsCreateNewTaskFormDirty } = useActions()
  const [selectedTemplate, setSelectedTemplate] = useState<TaskTemplateDetail | undefined>(props.selectedTaskTemplate)
  const [selectedSubTasks, setSelectedSubTasks] = useState<SubTaskSelection[] | undefined>(
    props.selectedSubTasksTemplate,
  )
  const [validate, setValidate] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const handleTemplateChange = (value: { key: string; value: string }) => {
    setValidate(false)
    const selected = allTaskTemplates.find(tt => tt.id?.toString() === value.key)
    setSelectedSubTasks(selected?.subTasks?.map(st => ({ subTask: st, checked: false })))
    setSelectedTemplate(selected)
  }

  const handleSubTaskChange = (subTasks: SubTaskSelection[]) => {
    setSelectedSubTasks(subTasks)
  }

  const onTemplateConfigured = () => {
    setValidate(true)
    if (isValid) {
      if (props.onConfigured) {
        props.onConfigured(selectedTemplate ?? {}, selectedSubTasks ?? [])
      }
    }
  }

  const handleSubTasksValidation = (isValid: boolean) => {
    setIsValid(isValid && selectedTemplate?.id !== undefined)
  }

  return (
    <div>
      <div className='space-y-2 mb-4'>
        <h4>
          Task template
        </h4>
        <div className={`${!props.isFromSideOut && 'w-1/2 pr-4'}`}>
          <Select
            label='Task template'
            default={{
              key: props.selectedTaskTemplate?.id?.toString() ?? '',
              value: props.selectedTaskTemplate?.name ?? '',
            }}
            data={allTaskTemplates?.map(item => {
              return { key: item.id?.toString() ?? '', value: item.name ?? '' }
            })}
            onChange={(value) => {
              handleTemplateChange(value)
              setIsCreateNewTaskFormDirty(true)
            }}
            selectedValueFontWeight='font-normal'
          />
          <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
            {validate && (selectedTemplate?.id === undefined)
              && (
                <>
                  <InformationCircleIcon className='h-4 w-4 mr-1' />
                  Select a Template
                </>
              )}
          </span>
        </div>
      </div>
      <div className='border-b mb-4' />
      <div
        className={`${!props.isFromSideOut ? 'h-[calc(100vh-300px)] pb-20 pr-2 overflow-y-auto' : 'h-full'} space-y-4`}
      >
        <h4>Subtasks to include:</h4>
        <SubTasksSelector
          subTasksSelection={selectedSubTasks}
          onChange={handleSubTaskChange}
          validate={validate}
          onValidate={handleSubTasksValidation}
        />
      </div>
      <div className={`${!props.isFromSideOut && 'absolute'} flex justify-end bottom-4 right-0 w-full h-12'`}>
        <Button
          className='mt-6 mr-2 h-10 w-1/2'
          type='submit'
          onClick={onTemplateConfigured}
          disabled={validate && !isValid}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
