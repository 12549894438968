import { Popover, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

export interface PopProps {
  text?: string
  children: React.ReactNode
  icon?: React.ElementType
}

export const PopMenu = (props: PopProps) => (
  <Popover className='relative'>
    {({ open }) => (
      <>
        <Popover.Button
          className={`
         ${open ? 'text-primary-300 border-primary-300' : ''}
         py-1 px-6 text-sm bg-transparent text-primary-100 inline-flex font-semibold hover:text-primary-300 hover:border-primary-300 border-2 border-primary-100 rounded-lg`}
        >
          <span>{props.text}</span>
          {props.icon && <props.icon className='w-5 h-5 ml-2' />}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-200'
          enterFrom='opacity-0 translate-y-1'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-150'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-1'
        >
          <Popover.Panel className='absolute z-10 mt-1'>
            <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
              {props.children}
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
)
