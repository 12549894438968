import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAppState } from '../presenter'
import { Forbidden } from './errors/Forbidden'
import { NotFound } from './errors/NotFound'
import { Unexpected } from './errors/Unexpected'
import { Sidebar } from './molecules'
import { Topbar } from './molecules/Topbar/Topbar'
import { AddNewContactModal } from './organisms/Modals/AddNewContactModal'
import { CreateNewTaskModal } from './organisms/Modals/CreateNewTaskModal'
import { CreateNewTaskTemplateModal } from './organisms/Modals/CreateNewTaskTemplateModal'
import { EditAltContactMethodModal } from './organisms/Modals/EditAltContactMethodModal'
import { EditContactModal } from './organisms/Modals/EditContactModal'
import { EditInteractionDetailsModal } from './organisms/Modals/EditInteractionDetailsModal'
import { InteractionDetailsModal } from './organisms/Modals/InteractionDetailsModal'
import { LoadingModal } from './organisms/Modals/LoadingModal'
import { SlideOutWarningModal } from './organisms/Modals/SlideOutWarningModal'
import { TaskCardDetailsModal } from './organisms/Modals/TaskCardDetailsModal'
import { TaskTemplateDetailsModal } from './organisms/Modals/TaskTemplateDetailsModal'
import { TemplateSelectionModal } from './organisms/Modals/TemplateSelectionModal'
import { CreateTaskSlideOut } from './organisms/SlideOuts/CreateTaskSlideOut'
import { NewInteractionSlideOut } from './organisms/SlideOuts/NewInteractionSlideOut'
import { ForgotPassword } from './templates/Authentication/ForgotPassword'
import { Login } from './templates/Authentication/Login'
import { ResetPassword } from './templates/Authentication/ResetPassword'
import { Dashboard } from './templates/Dashboard'
import { EmailsDashboard } from './templates/Emails/EmailsDashboard'
import { Loading } from './templates/Loading'
import { LawyerProfile } from './templates/Profiles/LawyerProfile'
import { OfficeClosureFormPage } from './templates/Profiles/OfficeClosureFormPage'
import { ReportsDashboard } from './templates/Reports/ReportsDashboard'
import { StyleGuide } from './templates/StyleGuide'
import { TasksDashboard } from './templates/Tasks/TasksDashboard'
import { TaskTemplates } from './templates/Tasks/TaskTemplates'

const pages: { [key: string]: () => JSX.Element } = {
  Login,
  ForgotPassword,
  ResetPassword,
  Dashboard,
  EmailsDashboard,
  ReportsDashboard,
  TasksDashboard,
  TaskTemplates,
  LawyerProfile,
  OfficeClosureFormPage,
  Loading,
  Forbidden,
  NotFound,
  Unexpected,
  StyleGuide,
}

const modals: { [key: string]: () => JSX.Element } = {
  AddNewContactModal,
  EditContactModal,
  EditAltContactMethodModal,
  SlideOutWarningModal,
  InteractionDetailsModal,
  EditInteractionDetailsModal,
  TaskCardDetailsModal,
  CreateNewTaskModal,
  CreateNewTaskTemplateModal,
  TaskTemplateDetailsModal,
  TemplateSelectionModal,
  LoadingModal,
  None: () => <></>,
}

const slideOuts: { [key: string]: () => JSX.Element } = {
  CreateTaskSlideOut,
  NewInteractionSlideOut,
  None: () => <></>,
}

export function CurrentPage() {
  const { currentModal, currentPage, currentSlideOut, sideNavOptions, topNavOptions, authenticatedUser, publicPages } =
    useAppState()
  const CurrentPage = pages[currentPage]
  const CurrentSlideOut = slideOuts[currentSlideOut]
  const CurrentModal = modals[currentModal]
  return (
    <>
      {!publicPages.includes(currentPage)
        ? (
          <Topbar
            navOptions={topNavOptions}
            userName={authenticatedUser.userLogin ?? ''}
          >
            <Sidebar
              navOptions={sideNavOptions}
              name={{ first: '' || '', last: '' || '' }}
              avatar={'' || ''}
            >
              <CurrentPage />
              <CurrentModal />
              <CurrentSlideOut />
              <ToastContainer position='top-center' hideProgressBar={true} />
            </Sidebar>
          </Topbar>
        )
        : (
          <Topbar
            navOptions={[]}
            userName={''}
            hideSearchInput
            hideDropDown
          >
            <CurrentPage />
            <ToastContainer position='top-center' hideProgressBar={true} />
          </Topbar>
        )}
    </>
  )
}
