import { Avatar } from 'components/atoms/Avatar/Avatar'
import { AvatarColors } from 'entities/enums/AvatarColors'

export interface AsigneeProps {
  initials?: string
  name?: string
  color?: AvatarColors
}

export interface SelectAsigneeProps {
  initials: AsigneeProps[]
}

export const SelectAsignee = (props: SelectAsigneeProps) => {
  return (
    <div className='flex w-full'>
      <div className='block rounded-xl shadow-xl bg-white text-center w-48'>
        <div className='flex flex-col gap-6 inline py-4 px-4'>
          {props.initials.map((item) => (
            <div className='flex cursor-pointer'>
              <Avatar initials={item.initials} color={item.color} />
              <span className='pl-3 font-extrabold'>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
