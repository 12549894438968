import { Transition } from '@headlessui/react'
import { Button } from 'components/atoms/Button/Button'
import { Fragment } from 'react'

interface ModalLeaveWarningProps {
  onClickNo: () => void
  onClickYes: () => void
  show?: boolean
}

export const ModalLeaveWarning = (props: ModalLeaveWarningProps) => {
  return (
    <Transition
      as={Fragment}
      show={props.show}
      enter='transition ease-out duration-200'
      enterFrom='opacity-0 translate-y-0'
      enterTo='opacity-100 translate-y-1'
      leave='transition ease-in duration-150'
      leaveFrom='opacity-100 translate-y-1'
      leaveTo='opacity-0 translate-y-0'
    >
      <footer className='flex shadow-inner rounded-xl py-6 fixed px-6 bottom-0 right-0 w-full bg-white'>
        <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
          <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
            Are you sure you want to leave this page?
          </div>
          <div className='text-primary-300 text-base font-normal text-center mb-6'>
            Your work will not be saved.
          </div>
          <div className='flex flex-row'>
            <Button className='mx-2 px-7' rounded='lg' color='secondary' onClick={props.onClickNo}>No</Button>
            <Button className='mx-2 px-7' onClick={props.onClickYes}>Yes</Button>
          </div>
        </div>
      </footer>
    </Transition>
  )
}
