import { Listbox, Transition } from '@headlessui/react'
import { UserAddIcon } from '@heroicons/react/outline'
import { Avatar } from 'components/atoms/Avatar/Avatar'
import { User } from 'generated/iTypes'
import { getMappedAvatarColor } from 'helpers/tasksHelper'
import { Fragment, useState } from 'react'

export type SelectProps = {
  data: User[]
  default?: User[]
  onChange: (value: User[]) => void
}

export const UserFilter = (props: SelectProps) => {
  const [selected, setSelected] = useState<User[]>(
    props.data.filter(o => props.default?.some(k => o.userId === k.userId)) ?? [],
  )

  const handleUserChanged = (users: User[]) => {
    setSelected(users)
    if (props.onChange) {
      props.onChange(users)
    }
  }

  return (
    <Listbox value={selected} onChange={handleUserChanged} multiple>
      {({ open }) => (
        <>
          <Listbox.Button
            name='select'
            className={`${
              open ? 'text-primary-300 border-primary-300' : ''
            } py-1 px-6 text-sm text-primary-100 inline-flex font-semibold 
            hover:text-primary-300 hover:border-primary-300 border-2 border-primary-100 rounded-lg`}
          >
            <span>See other users</span>
            <UserAddIcon className='w-5 h-5 ml-2' />
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <div className='absolute z-10 mt-9 bg-white'>
              <Listbox.Options className='overflow-hidden rounded-xl shadow-lg ring-1 
              ring-black ring-opacity-5 border border-outlineLightBlue'>
                {props.data.map((option, idx) => {
                  const mappedColor = getMappedAvatarColor(option.userName.slice(0, 1) ?? '')
                  return (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        `cursor-default select-none relative py-4 pl-16 pr-10 ${active ? 'bg-bgLightBlue' : ''}`}
                      value={option}
                    >
                      {({ selected }) => (
                        <Fragment>
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                            <Avatar
                              key={`userAvatarKey-${idx}`}
                              initials={`${option.firstName.slice(0, 1)}${option.lastName.slice(0, 1)}`}
                              color={mappedColor}
                            />
                          </span>
                          <span className={`block truncate font-extrabold`}>
                            {option.firstName + ' ' + option.lastName}
                          </span>
                          <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
                            <input
                              checked={selected}
                              type='checkbox'
                              onChange={() => {}}
                              className=' text-primary-300 rounded border-2 border-secondary-200'
                            />
                          </span>
                        </Fragment>
                      )}
                    </Listbox.Option>
                  )
                })}
              </Listbox.Options>
            </div>
          </Transition>
        </>
      )}
    </Listbox>
  )
}
