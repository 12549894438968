import { Avatar } from 'components/atoms/Avatar/Avatar'
import { timeSince } from 'helpers/stringHelpers'
import { getMappedAvatarColor } from 'helpers/tasksHelper'

export interface CommentCardProps {
  initials: string
  name: string
  date: Date
  comment?: string
  onClick?: () => void
}

export const CommentCard = (props: CommentCardProps) => {
  return (
    <div>
      <div className='flex mb-2'>
        <Avatar initials={props.initials} color={getMappedAvatarColor(props.name.slice(0, 1) ?? '')} />
        <span className='pl-3 font-extrabold mt-1'>{props.name}</span>
        <span className='text-xs mt-2 ml-4'>{timeSince(props.date.toString())}</span>
      </div>
      <div>
        {props.comment}
      </div>
    </div>
  )
}
