import React from 'react'

interface LinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  text?: string
  underline?: boolean
  secondary?: boolean
  iconleft?: React.ElementType
  iconright?: React.ElementType
}

export const Link = ({
  underline = false,
  secondary = false,
  ...props
}: LinkProps) => {
  return (
    <a
      className={`
                ${underline && 'underline'} 
                ${secondary ? 'text-primary-300' : 'text-primary-100 hover:text-primary-300'}
                font-bold
                visited:text-purple-600 shrink-0`}
      {...props}
    >
      {props.iconleft
        && (
          <div className='flex items-center'>
            <props.iconleft className='w-4 h-4' />
            <div className='ml-1'>{props.text}</div>
          </div>
        )}
      {props.iconright
        && (
          <div className='flex items-center'>
            <div className='mr-1'>{props.text}</div>
            <props.iconright className='w-4 h-4' />
          </div>
        )}
      {(!props.iconleft && !props.iconright)
        && props.text}
    </a>
  )
}
