import merge from 'lodash/merge'
import DataTable, { TableProps as DataTableProps, TableStyles } from 'react-data-table-component'
export type { TableColumn } from 'react-data-table-component'

const customStyles: TableStyles = {
  headCells: {
    style: {
      fontSize: '.875rem',
      fontWeight: 800,
      color: '#011A54',
    },
  },
  headRow: {
    style: {
      borderBottom: 'none',
      paddingBottom: '.75rem',
      paddingTop: '.75rem',
    },
  },
  rows: {
    style: {
      borderBottom: '1px solid #99B4F5',
      fontSize: '1rem',
      paddingBottom: '1.125rem',
      paddingTop: '1.125rem',
    },
  },
  tableWrapper: {
    style: {
      // Makes the table fit in the available width instead of showing
      // a horizontal scroll bar.
      tableLayout: 'fixed',
      position: 'relative',
    },
  },
}

const highlightOnHoverAddendum: TableStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#F5F5F5',
    },
  },
}

interface TableProps<T> extends DataTableProps<T> {
  overrideStyles?: TableStyles
  hideShadow?: boolean
}

function Table<T>(props: TableProps<T>) {
  const { highlightOnHover, overrideStyles, ...rest } = props

  const updatedStyles = merge({}, customStyles, overrideStyles)
  if (highlightOnHover) {
    merge(updatedStyles, highlightOnHoverAddendum)
  }
  if (overrideStyles !== undefined) {
    merge(updatedStyles, overrideStyles)
  }

  return (
    <div className={`${!props.hideShadow && 'shadow-md'} rounded-xl px-2 py-2`}>
      <DataTable
        customStyles={updatedStyles}
        {...rest}
      />
    </div>
  )
}

export default Table
