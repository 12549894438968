import { Card } from 'components/atoms/Card/Card'
import { Tag } from 'components/atoms/Tag/Tag'
import { TagResponse } from 'generated/iTypes'
import { getMappedTagsColor } from 'helpers/tasksHelper'

export interface TaskTemplateCardProps {
  title?: string
  taskDetails?: string
  tags?: TagResponse[]
  subTasksCount?: number
  children: React.ReactNode
  onClick?: () => void
}

export const TaskTemplateCard = ({
  ...props
}: TaskTemplateCardProps) => {
  return (
    <Card width={'w-full'} onClick={props.onClick}>
      <div className='w-full h-full'>
        <div className='flex pt-3 place-content-between place-items-center'>
          <div className='flex font-extrabold mb-2'>
            <span className='line-clamp-2 pl-4 pt-2 text-primary-300 text-left'>{props.title}</span>
          </div>
        </div>
        <div className='py-4 px-4 mb-3'>
          <p className='line-clamp-2 text-left text-textGrey'>
            {props.children}
          </p>
        </div>
        <div className='flex py-4 px-4 border-t border-outlineLightBlue place-content-between place-items-center'>
          <div className='flex'>
            {props.tags?.map((tag) => (
              <Tag
                className='pl-1 pt-1'
                color={getMappedTagsColor(tag.name ?? '')}
                text={tag.name}
                key={tag.id}
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}
