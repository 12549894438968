import { PlusIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import { useActions } from 'presenter'

export const CreateNewTaskTemplateButton = () => {
  const { displayNewTaskTemplateModalAction } = useActions()

  return (
    <div className='absolute top-0 right-0 mr-12 mt-12'>
      <Button onClick={() => displayNewTaskTemplateModalAction()} className='w-60 h-12'>
        <PlusIcon className='w-4 h-4 mr-2' />
        Create new template
      </Button>
    </div>
  )
}
