import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'

export interface DropdownDataProps {
  icon: React.FC
  name: string
  onClick?: () => void
}

export interface DropdownProps {
  icon?: React.FC
  label?: string
  data?: DropdownDataProps[]
}

export const Dropdown = (props: DropdownProps) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex w-full justify-center rounded-md px-4 py-2 font-semibold hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
          {props.label}
          {props.icon ? <props.icon /> : (
            <ChevronDownIcon
              className='ml-2 -mr-1 mt-0.5 h-5 w-5 text-secondary-100'
              aria-hidden='true'
            />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='px-1 py-1 '>
            {props.data?.map((item, index) => (
              <Menu.Item key={`DropDownMenuItem-${item.name}-${index}`}>
                {({ active }) => (
                  <button
                    onClick={item.onClick}
                    className={`${
                      active ? 'bg-bgLightBlue' : 'text-textGrey'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {active ? <item.icon /> : <item.icon />}
                    {item.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
