import { Tab, Transition } from '@headlessui/react'
import { DotsHorizontalIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import { Dropdown } from 'components/atoms/Dropdown/Dropdown'
import TextArea from 'components/atoms/TextArea/TextArea'
import TextInput from 'components/atoms/TextInput/TextInput'
import { ConfirmationCard } from 'components/molecules/ConfirmationCard/ConfirmationCard'
import { SubTaskTemplateEditor } from 'components/molecules/SubTaskTemplateEditor/SubTaskTemplateEditor'
import { TagSelector } from 'components/molecules/TagSelector/TagSelector'
import { TaskTemplateSubTasks } from 'components/molecules/TaskTemplateEditor/TaskTemplateSubTasks'
import { TagAssignment } from 'entities/enums/TagAssignment'
import { ResponseWrapper } from 'entities/types/ResponseWrapper'
import { SubTaskDetail, SubTaskTemplateDetail, TagResponse, TaskTemplateDetail } from 'generated/iTypes'
import { capitalizeFirstLetter } from 'helpers/stringHelpers'
import { deepEqual } from 'helpers/tasksHelper'
import { showToast } from 'helpers/toastHelpers'
import { useActions, useAppState } from 'presenter'
import { Fragment, useState } from 'react'
import * as yup from 'yup'
import { ValidationError } from 'yup'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

type TaskTemplateEditorProps = {
  mode: 'View' | 'Create'
  taskTemplate?: TaskTemplateDetail
  selectedTab?: number
  onSaved?: (shouldClose: boolean) => void
}

export const TaskTemplateEditor = (props: TaskTemplateEditorProps) => {
  const { selectedSubTaskTemplateDetails, autoSaving } = useAppState()
  const { addNewTaskTemplateAction, saveTaskTemplateAction, deleteTaskTemplateAction, setIsCreateNewTaskFormDirty } =
    useActions()
  const [taskTemplate, setTaskTemplate] = useState<TaskTemplateDetail | undefined>(props.taskTemplate)
  const [formValid, setFormValid] = useState<boolean>(true)
  const [savingTask, setSavingTask] = useState<boolean>(false)
  const [subTaskEditorMode, setSubTaskEditorMode] = useState<'Create' | 'View'>(
    selectedSubTaskTemplateDetails.id !== undefined ? 'View' : 'Create',
  )
  const [showSubTaskEditor, setShowSubTaskEditor] = useState(selectedSubTaskTemplateDetails.id !== undefined)
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ?? 0)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [subTaskIndex, setSubTaskIndex] = useState<number | undefined>()

  const nameSchema = yup.string().required()
  const taskDetailsSchema = yup.string().required()

  let schema = yup.object().shape({
    name: nameSchema,
    taskDetails: taskDetailsSchema,
  })

  const isFormValid = () => {
    try {
      let toValidate = {
        name: taskTemplate?.name,
        taskDetails: taskTemplate?.taskDetails,
      }
      schema.validateSync(toValidate, { abortEarly: false })
      return true
    } catch (e) {
      console.dir(e)
      return false
    }
  }

  const validateName = (value: string | undefined) => {
    try {
      nameSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateTaskDetails = (value: string | undefined) => {
    try {
      taskDetailsSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const handleTagsChanged = (tags: TagResponse[]) => {
    let parentTags = tags.map((t) => ({ ...t, assignmentType: TagAssignment.FromParent.valueOf() }))
    let subTasks = taskTemplate?.subTasks?.map((st) => (
      {
        ...st,
        tags: [...parentTags, ...st.tags?.filter(t => t.assignmentType !== TagAssignment.FromParent.valueOf()) ?? []],
      }
    ))
    let updated = { ...taskTemplate, tags: tags, subTasks: subTasks }
    setTaskTemplate(updated)
    if (props.mode === 'View') {
      saveTaskTemplateAction({ task: updated, shouldRefreshSummary: false })
    }
    setIsCreateNewTaskFormDirty(true)
  }

  const handleSave = async (task: TaskTemplateDetail | undefined, shouldClose: boolean) => {
    if (task !== undefined) {
      let valid = isFormValid()
      setFormValid(valid)
      if (valid) {
        setSavingTask(true)
        if (props.mode === 'Create') {
          let response = await addNewTaskTemplateAction(task)
          let created = response as TaskTemplateDetail
          if (created.id) {
            showToast({ message: 'Template Created', responseType: 'Success' })
          } else {
            shouldClose = false
            showToast(response as ResponseWrapper)
          }
        } else if (props.mode === 'View') {
          await saveTaskTemplateAction({ task: task, shouldRefreshSummary: true })
        }
        setSavingTask(false)
        if (props.onSaved) {
          props.onSaved(shouldClose)
        }
      }
    }
  }

  const handleTaskDetailsChange = (taskDetails: string) => {
    setTaskTemplate({ ...taskTemplate, taskDetails: taskDetails })
  }

  const handleShowSubTaskEditor = (mode: 'Create' | 'View', index?: number) => {
    setSubTaskEditorMode(mode)
    setShowSubTaskEditor(true)
    setSubTaskIndex(index)
  }

  const handleSubTaskSaved = async (subTask: SubTaskDetail, subTaskIndex?: number) => {
    if (props.mode === 'Create') {
      if (subTaskEditorMode === 'View') {
        if (subTaskIndex !== undefined) {
          let subTasks = taskTemplate?.subTasks?.map((st, index) => index === subTaskIndex ? subTask : st)
          setTaskTemplate({ ...taskTemplate, subTasks: subTasks })
        }
      } else if (subTaskEditorMode === 'Create') {
        let subTasks = [...taskTemplate?.subTasks ?? [], subTask]
        setTaskTemplate({ ...taskTemplate, subTasks: subTasks })
      }
    } else if (props.mode === 'View') {
      let subTasks = taskTemplate?.subTasks?.map(st => st.id === subTask.id ? subTask : st)
      if (subTaskEditorMode === 'Create') {
        subTasks = [...subTasks ?? [], subTask]
      }
      setTaskTemplate({ ...taskTemplate, subTasks: subTasks })
    }
  }

  const handleSubTaskExit = (subTask: SubTaskDetail | undefined, exitBehavior: 'None' | 'Back' | 'Close') => {
    switch (exitBehavior) {
      case 'Back':
        setSelectedTab(1)
        setShowSubTaskEditor(false)
        break
      case 'Close':
        if (props.onSaved) {
          props.onSaved(true)
        }
        break
      case 'None':
        break
    }
  }

  const handleSubTaskRemoved = async (subTask: SubTaskTemplateDetail) => {
    let subTasks = taskTemplate?.subTasks
    if (subTask.id !== undefined) {
      subTasks = taskTemplate?.subTasks?.filter(st => st.id !== subTask.id)
    } else {
      subTasks = taskTemplate?.subTasks?.filter(st => !deepEqual(st, subTask))
    }
    setTaskTemplate({ ...taskTemplate, subTasks: subTasks })
  }

  const handleDelete = async () => {
    if (taskTemplate?.id !== undefined) {
      await deleteTaskTemplateAction(taskTemplate.id)
      showToast({ responseType: 'Success', message: 'Template deleted' })
      if (props.onSaved) {
        props.onSaved(true)
      }
    }
  }

  return (
    <div className='h-full'>
      <Transition
        as={Fragment}
        show={!showSubTaskEditor}
        enter='transform transition ease-out duration-200 sm:duration-200'
        enterFrom='opacity-0 -translate-x-1/3'
        enterTo='opacity-100 -translate-x-0'
      >
        <div className='flex flex-col pl-2 h-full'>
          <div className='overflow-y-auto pr-2'>
            <div>
              <div className='flex mt-10'>
                <TextInput
                  value={taskTemplate?.name}
                  onChange={(e) => {
                    setTaskTemplate({ ...taskTemplate, name: e.target.value })
                    setIsCreateNewTaskFormDirty(true)
                  }}
                  placeholder='Title'
                />
                {props.mode === 'View' && (
                  <div>
                    <Dropdown
                      icon={() => <DotsHorizontalIcon className='h-5 w-10' />}
                      label={''}
                      data={[{
                        icon: () => <></>,
                        name: 'Remove Template',
                        onClick: () => {
                          setShowDeleteConfirmation(true)
                        },
                      }]}
                    />
                  </div>
                )}
              </div>
              {!formValid && validateName(taskTemplate?.name) !== ''
                ? (
                  <div>
                    <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {validateName(taskTemplate?.name)}
                    </span>
                  </div>
                )
                : null}
            </div>
            <div className='mt-6'>
              <div className='grid grid-cols-6 mt-4'>
                <div className='col-span-1 font-bold'>Tags</div>
                <div className='col-span-5'>
                  <TagSelector
                    value={taskTemplate?.tags}
                    onChange={handleTagsChanged}
                  />
                </div>
              </div>
            </div>
            <div className='mt-6 mb-16'>
              <Tab.Group onChange={(i) => setSelectedTab(i)} selectedIndex={selectedTab}>
                <Tab.List className=''>
                  <Tab className=''>
                    {({ selected }) => (
                      <div
                        className={selected
                          ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                          : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                      >
                        <div className='mb-0'>
                          Description
                        </div>
                      </div>
                    )}
                  </Tab>
                  <Tab className=''>
                    {({ selected }) => (
                      <div
                        className={selected
                          ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                          : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                      >
                        <div className='flex'>
                          Subtasks
                          {(taskTemplate?.subTasks?.length ?? 0) > 0 && (
                            <div
                              className={'ml-2 h-6 w-6 rounded-full text-xs font-extrabold h-full grid place-items-center border-2 '
                                + (selected ? ' border-primary-100' : '')}
                            >
                              {taskTemplate?.subTasks?.length}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Tab>
                </Tab.List>
                <div className='relative border-b border-b-outlineLightBlue' />
                <Tab.Panels className='mt-4'>
                  <Tab.Panel>
                    <div className='pb-10 mb-20'>
                      <TextArea
                        value={taskTemplate?.taskDetails}
                        placeholder='Description'
                        rows={5}
                        onChange={e => {
                          handleTaskDetailsChange(e.target.value)
                          setIsCreateNewTaskFormDirty(true)
                        }}
                        onKeyDown={(e) => {
                          ;(taskTemplate?.taskDetails && e.ctrlKey && e.key === 'Enter')
                            && handleSave(taskTemplate, true)
                        }}
                      />
                      {!formValid && validateTaskDetails(taskTemplate?.taskDetails) !== ''
                        ? (
                          <div>
                            <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                              <InformationCircleIcon className='h-4 w-4 mr-1' />
                              {validateTaskDetails(taskTemplate?.taskDetails)}
                            </span>
                          </div>
                        )
                        : null}
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className='mb-8'>
                      <TaskTemplateSubTasks
                        subTasks={taskTemplate?.subTasks}
                        parentTags={taskTemplate?.tags}
                        onShowEditor={handleShowSubTaskEditor}
                      />
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <div className='absolute flex justify-between py-4 bottom-0 right-0 w-full bg-white'>
            <div className='pt-4'>
              {autoSaving && (
                <>
                  <SpinnerSVG className='inline mr-2 w-3 h-3 text-gray-200 animate-spin fill-primary-200' />
                  <span className='text-sm text-secondary-200'>saving</span>
                </>
              )}
            </div>
            <div className=''>
              <Button className='w-60 h-12' onClick={() => handleSave(taskTemplate, true)} disabled={savingTask}>
                {savingTask
                  ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                  : (
                    <span>
                      {props.mode === 'Create' ? 'Create Template' : 'Done'}
                    </span>
                  )}
              </Button>
            </div>
          </div>
          <div className='absolute bottom-0 right-0 w-full z-40 bg-white'>
            <Transition
              as={Fragment}
              show={showDeleteConfirmation}
              enter='transform transition ease-in duration-200 sm:duration-200'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
            >
              <div className=''>
                <ConfirmationCard
                  text='Are you sure you want to remove this template?'
                  confirmText='Yes'
                  cancelText='No'
                  confirmButtonStyle='secondary'
                  cancelButtonStyle='secondary'
                  onConfirm={handleDelete}
                  onCancel={() => setShowDeleteConfirmation(false)}
                />
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
      <Transition
        as={Fragment}
        show={showSubTaskEditor}
        enter='transform transition ease-out duration-200 sm:duration-200'
        enterFrom='opacity-0 translate-x-1/3'
        enterTo='opacity-100 translate-x-0'
      >
        <div className='h-full w-full'>
          <SubTaskTemplateEditor
            parentId={taskTemplate?.id}
            parentName={taskTemplate?.name}
            subTask={selectedSubTaskTemplateDetails}
            subTaskIndex={subTaskIndex}
            mode={subTaskEditorMode}
            onSaved={handleSubTaskSaved}
            onExit={handleSubTaskExit}
            onRemoved={handleSubTaskRemoved}
          />
        </div>
      </Transition>
    </div>
  )
}
