export enum ScreenSize {
  '5xl' = 3440,
  '4xl' = 2560,
  '3xl' = 1920,
  '2xl' = 1536,
  '1xl' = 1440,
  'xl' = 1280,
  'lg' = 1024,
  'md' = 768,
  'sm' = 640,
}
