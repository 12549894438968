import { Provider } from 'overmind-react'
import React from 'react'
import * as ReactDOM from 'react-dom'
import { CurrentPage } from './components/CurrentPage'
import { overmindApp } from './presenter'
import { initializeRouter } from './presenter/router'
// @ts-ignore
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
// @ts-ignore
import reportWebVitals from 'reportWebVitals'
import './input.css'
;(async function() {
  initializeRouter(overmindApp)
  if (await !overmindApp.actions.validateAuthenticatedUser()) {
    await overmindApp.effects.redirect('/login')
  }
})()

ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmindApp}>
      <main>
        <CurrentPage />
      </main>
    </Provider>
  </React.StrictMode>,
  window.document.querySelector('#root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
