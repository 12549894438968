import * as Yup from 'yup'

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()_+[\]{};':"\\|,.<>`~^#=-])[A-Za-z\d@$!%*?&()_+[\]{};':"\\|,.<>`~^#=-]{8,}$/

export const LoginFormValidator = Yup.object().shape({
  userLogin: Yup.string().required('user name is a required field'),
  password: Yup.string().required('password is a required field'),
})

export const ForgotPasswordFormValidator = Yup.object().shape({
  emailAddress: Yup.string().email('email address must be a valid email').required('email address is a required field'),
})

export const ResetPasswordFormValidator = Yup.object().shape({
  password: Yup.string().required('Password is required')
    .matches(passwordRegex, {
      message: 'Password must include at least eight characters, one uppercase, one number and one special character',
      excludeEmptyString: false,
    }),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
})
