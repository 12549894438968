import { InformationCircleIcon, PencilAltIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import Select from 'components/atoms/Select/Select'
import TextInput from 'components/atoms/TextInput/TextInput'
import { Modal } from 'components/molecules'
import { ContactUpdateRequest } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AddEditPrefAltMethodContactInfo } from 'validators/contactFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const EditAltContactMethodModal = () => {
  const { currentModal, selectedLawyer, personTypes } = useAppState()
  const { displayModalAction, updateContactAction } = useActions()
  const [savingContact, setSavingContact] = useState<boolean>(false)

  const { formState: { errors }, handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      ...selectedLawyer,
      personTypeId: selectedLawyer?.personType?.id,
      regionId: selectedLawyer?.region?.id,
    } as ContactUpdateRequest,
    resolver: yupResolver(AddEditPrefAltMethodContactInfo),
  })

  const onClose = async () => displayModalAction('None')

  const handleSave = async (payload: ContactUpdateRequest) => {
    setSavingContact(true)
    await updateContactAction(payload)
    setSavingContact(false)
    displayModalAction('None')
  }
  return (
    <Modal
      isOpen={currentModal === 'EditAltContactMethodModal'}
      onClose={onClose}
      shouldCloseOnEsc
      contentStyle={{
        width: '646px',
        padding: '40px',
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <div className='h-full text-primary-300 overflow-x-hidden'>
          <div className='flex items-center pt-2 font-extrabold text-primary-300 text-xl font-semibold mb-2 text-left'>
            <PencilAltIcon className='w-6 h-6 mr-2 text-primary-100' />
            {`${selectedLawyer?.altContactMethod ? 'Edit' : 'Add'}`} contact info
          </div>
          <div className='pt-6 mt-6 grid grid-cols-2 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>Alternate Contact Method</p>
              <TextInput
                {...register('altContactMethod')}
                placeholder='Email or phone number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.altContactMethod
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.altContactMethod.message}
                    </>
                  )}
              </span>
              <div className='relative pt-2'>
                <Checkbox
                  text={'Set as preferred contact method '}
                  defaultChecked={getValues('isAltContactPref')}
                  onChange={(val) => {
                    setValue('isAltContactPref', val)
                  }}
                />
              </div>
            </div>
            <div>
              <div className='absolute w-64'>
                <p className='font-bold text-sm mb-1'>Person type</p>
                <Select
                  label='Person type'
                  data={personTypes.map(item => {
                    return { key: item.itemId!, value: item.itemText! }
                  })}
                  onChange={(value) => {
                    const personType = personTypes.find(f => f.itemId === Number(value.key))
                    setValue('personTypeId', Number(personType?.itemId), { shouldDirty: true, shouldValidate: true })
                  }}
                  default={{
                    key: personTypes.find(f => f.itemId === getValues('personTypeId'))?.itemId ?? '',
                    value: personTypes.find(f => f.itemId === getValues('personTypeId'))?.itemText ?? '',
                  }}
                />
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.personTypeId
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.personTypeId.message}
                      </>
                    )}
                </span>
              </div>
            </div>
          </div>
          <div className='flex justify-end'>
            <Button className='mt-6 h-10 w-1/2' type='submit' disabled={savingContact}>
              {savingContact
                ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                : (
                  <span>
                    Save
                  </span>
                )}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
