import { Tab, Transition } from '@headlessui/react'
import { CalendarIcon, ChatIcon, DotsHorizontalIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import { Dropdown } from 'components/atoms/Dropdown/Dropdown'
import TextArea from 'components/atoms/TextArea/TextArea'
import TextInput from 'components/atoms/TextInput/TextInput'
import { StatusSelector } from 'components/molecules/StatusSelector/StatusSelector'
import { TagSelector } from 'components/molecules/TagSelector/TagSelector'
import { TaskComments } from 'components/molecules/TaskEditor/TaskComments'
import { UserSelector } from 'components/molecules/UserSelector/UserSelector'
import { ComboBoxItem, CommentDetail, SubTaskDetail, TagResponse, User } from 'generated/iTypes'
import { capitalizeFirstLetter } from 'helpers/stringHelpers'
import { formatDate } from 'helpers/tasksHelper'
import { useActions, useAppState } from 'presenter'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { ValidationError } from 'yup'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'
import { ConfirmationCard } from '../ConfirmationCard/ConfirmationCard'

type SubTaskEditorProps = {
  parentId: number | undefined
  contact?: ComboBoxItem
  parentName?: string
  mode: 'View' | 'Create'
  subTask?: SubTaskDetail | undefined
  onSaved?: (subTask: SubTaskDetail, subTaskIndex?: number) => void
  subTaskIndex?: number
  onExit?: (subTask: SubTaskDetail | undefined, exitBehavior: 'None' | 'Back' | 'Close') => void
  onRemoved?: (idsubTask: SubTaskDetail) => void
}

export const SubTaskEditor = (props: SubTaskEditorProps) => {
  const { autoSaving, subTaskDirectSelect } = useAppState()
  const [subTask, setSubTask] = useState<SubTaskDetail | undefined>(props.subTask)
  const [formValid, setFormValid] = useState<boolean>(true)
  const { saveTaskAction, addNewSubTaskAction, deleteTaskAction } = useActions()
  const [savingTask, setSavingTask] = useState<boolean>(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const dueDateSchema = yup.date().required()
  const nameSchema = yup.string().required()
  const assigneeSchema = yup.number().required()
  const statusSchema = yup.number().required()
  const taskDetailsSchema = yup.string().required()

  let schema = yup.object().shape({
    dueDate: dueDateSchema,
    name: nameSchema,
    userId: assigneeSchema,
    statusId: statusSchema,
    taskDetails: taskDetailsSchema,
  })

  const isFormValid = () => {
    try {
      let interactionToValidate = {
        dueDate: subTask?.dueDate,
        name: subTask?.name,
        userId: subTask?.assignedTo?.userId,
        statusId: subTask?.taskStatus?.itemId,
        taskDetails: subTask?.taskDetails,
      }
      schema.validateSync(interactionToValidate, { abortEarly: false })
      return true
    } catch (e) {
      console.dir(e)
      return false
    }
  }

  const validateDate = (value: string | undefined) => {
    try {
      dueDateSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateName = (value: string | undefined) => {
    try {
      nameSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateAssignee = (value: string | undefined) => {
    try {
      assigneeSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)[0].toUpperCase() + e.message.slice(1)
      }
    }
  }

  const validateStatus = (value: string | undefined) => {
    try {
      statusSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const validateTaskDetails = (value: string | undefined) => {
    try {
      taskDetailsSchema.validateSync(value)
      return ''
    } catch (e) {
      if (e instanceof ValidationError) {
        return capitalizeFirstLetter(e.message)
      }
    }
  }

  const handleTaskStatusChanged = (taskStatus: ComboBoxItem) => {
    let updated = { ...subTask, taskStatus: taskStatus }
    setSubTask(updated)
    if (props.mode === 'View') {
      handleSave(updated, false)
    }
  }

  const handleUserChanged = (user: User) => {
    let updated = { ...subTask, assignedTo: user }
    setSubTask(updated)
    if (props.mode === 'View') {
      handleSave(updated, false)
    }
  }

  const handleTagsChanged = (tags: TagResponse[]) => {
    let updated = { ...subTask, tags: tags }
    setSubTask(updated)
    if (props.mode === 'View') {
      handleSave(updated, false)
    }
  }

  const handleSave = async (subTask: SubTaskDetail | undefined, shouldExit: boolean) => {
    if (subTask !== undefined) {
      let valid = isFormValid()
      setFormValid(valid)
      if (valid) {
        setSavingTask(true)
        if (props.mode === 'Create' && props.parentId !== undefined) {
          let updated = { ...subTask, parentId: props.parentId, contact: props.contact }
          let response = await addNewSubTaskAction(updated)
          if (response !== undefined) {
            subTask.id = response.id
          }
        } else if (props.mode === 'View') {
          if (subTask.id !== undefined) {
            subTask = (await saveTaskAction({ task: subTask, shouldRefreshSummary: shouldExit })) ?? {}
          }
        }
        if (props.onSaved) {
          props.onSaved(subTask, props.subTaskIndex)
        }
        setSavingTask(false)
        if (shouldExit && props.onExit) {
          props.onExit(subTask, subTaskDirectSelect ? 'Close' : 'Back')
        }
      }
    }
  }

  const handleBack = async () => {
    // Validate exit
    if (subTask !== undefined) {
      if (props.mode === 'View') {
        if (subTask.id !== undefined) {
          saveTaskAction({ task: subTask, shouldRefreshSummary: false })
        }
      }
    }
    // If discarded
    if (props.onExit) {
      props.onExit(subTask, 'Back')
    }
  }

  const handleDelete = async () => {
    if (subTask?.id !== undefined) {
      await deleteTaskAction({ taskId: subTask.id, shouldRefresh: false })
      toast.success('Subtask deleted')
    }
    if (props.onExit) {
      props.onExit(subTask, 'Back')
      if (props.onRemoved && subTask !== undefined) {
        props.onRemoved(subTask)
      }
    }
  }

  const handleTaskDetailsChange = (taskDetails: string) => {
    setSubTask({ ...subTask, taskDetails: taskDetails })
  }

  const handleCommentsChange = (value: CommentDetail[]) => {
    setSubTask({ ...subTask, comments: value })
    handleSave(subTask, false)
  }

  return (
    <div className='flex flex-col pl-2 mt-4 h-full'>
      <div className='overflow-y-auto pr-2'>
        <span className='text-secondary-200 font-bold hover:cursor-pointer w-1/2' onClick={handleBack}>
          {'< Back' + (props.parentName ? ' - ' + props.parentName : '')}
        </span>
        <div className='text-primary-300 text-sm font-semibold mb-2 text-left mb-4 mt-2'>
          <div className='flex items-center w-full'>
            <CalendarIcon className='w-5 h-5'></CalendarIcon>
            <span className='pl-2 inline-block'>Due:</span>
            <input
              value={formatDate(subTask?.dueDate?.toString() ?? '', 'YYYY-MM-DD')}
              onChange={e => setSubTask({ ...subTask, dueDate: new Date(e.target.value) })}
              type='date'
              className='border border-none text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 blocks w-[150px]'
            />
          </div>
          {!formValid && validateDate(subTask?.dueDate?.toString()) !== ''
            ? (
              <div>
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  <InformationCircleIcon className='h-4 w-4 mr-1' />
                  {validateDate(subTask?.dueDate?.toString())}
                </span>
              </div>
            )
            : null}
        </div>
        <div>
          <div className='flex'>
            <TextInput
              value={subTask?.name}
              onChange={(e) => setSubTask({ ...subTask, name: e.target.value })}
              placeholder='Title'
            />
            {props.mode === 'View' && (
              <div>
                <Dropdown
                  icon={() => <DotsHorizontalIcon className='h-5 w-10' />}
                  label={''}
                  data={[{
                    icon: () => <></>,
                    name: 'Remove Subtask',
                    onClick: () => {
                      setShowDeleteConfirmation(true)
                    },
                  }]}
                />
              </div>
            )}
          </div>
          {!formValid && validateName(subTask?.name) !== ''
            ? (
              <div>
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  <InformationCircleIcon className='h-4 w-4 mr-1' />
                  {validateName(subTask?.name)}
                </span>
              </div>
            )
            : null}
        </div>
        <div className='mt-6'>
          <div className='grid grid-cols-6'>
            <div className='col-span-1 font-bold'>Asignee</div>
            <div className='col-span-5'>
              <UserSelector
                value={subTask?.assignedTo}
                onChange={handleUserChanged}
                valid={formValid}
                errorMessage={validateAssignee(subTask?.assignedTo?.userId?.toString())}
              />
            </div>
          </div>
          <div className='grid grid-cols-6 mt-4'>
            <div className='col-span-1 font-bold'>Status</div>
            <div className='col-span-5'>
              <StatusSelector
                value={subTask?.taskStatus}
                onChange={handleTaskStatusChanged}
                valid={formValid}
                errorMessage={validateStatus(subTask?.taskStatus?.itemId?.toString())}
              />
            </div>
          </div>
          <div className='grid grid-cols-6 mt-4'>
            <div className='col-span-1 font-bold'>Tags</div>
            <div className='col-span-5'>
              <TagSelector
                value={subTask?.tags}
                onChange={handleTagsChanged}
              />
            </div>
          </div>
        </div>
        <div className='mt-4'>
          <Tab.Group>
            <Tab.List>
              <Tab className=''>
                {({ selected }) => (
                  <div
                    className={selected
                      ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                      : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                  >
                    Description
                  </div>
                )}
              </Tab>
              {(subTask?.id ?? 0) > 0 && (
                <Tab className=''>
                  {({ selected }) => (
                    <div
                      className={selected
                        ? 'pb-2 mr-10 border-b-8 border-b-primary-200 font-bold text-primary-100'
                        : 'pb-2 mr-10 border-b-8 border-b-white font-bold text-secondary-200'}
                    >
                      <div className='flex'>
                        Comments
                        {(subTask?.comments?.length ?? 0) > 0 && (
                          <div className='flex'>
                            <div className='ml-2 h-6 w-6 text-xs font-extrabold h-full grid place-items-center'>
                              {subTask?.comments?.length}
                            </div>
                            <ChatIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Tab>
              )}
            </Tab.List>
            <div className='relative border-b border-b-outlineLightBlue' />
            <Tab.Panels className='mt-4'>
              <Tab.Panel>
                <div className='h-40 mb-20 mt-2 text-sm'>
                  <TextArea
                    value={subTask?.taskDetails}
                    placeholder='Description'
                    rows={4}
                    onChange={e => handleTaskDetailsChange(e.target.value)}
                    onKeyDown={(e) => {
                      ;(subTask?.taskDetails && e.ctrlKey && e.key === 'Enter') && handleSave(subTask, true)
                    }}
                  />
                  {!formValid && validateTaskDetails(subTask?.taskDetails) !== ''
                    ? (
                      <div>
                        <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          {validateTaskDetails(subTask?.taskDetails)}
                        </span>
                      </div>
                    )
                    : null}
                </div>
              </Tab.Panel>
              {(subTask?.id ?? 0) > 0 && (
                <Tab.Panel>
                  <div className='mb-20'>
                    <TaskComments
                      taskId={subTask?.id}
                      comments={subTask?.comments}
                      mode={props.mode}
                      onChange={handleCommentsChange}
                      placeholder='Comment'
                    />
                  </div>
                </Tab.Panel>
              )}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <div className='absolute flex justify-between py-4 bottom-0 right-0 w-full bg-white'>
        <div className='pt-4'>
          {autoSaving && (
            <>
              <SpinnerSVG className='inline mr-2 w-3 h-3 text-gray-200 animate-spin fill-primary-200' />
              <span className='text-sm text-secondary-200'>saving</span>
            </>
          )}
        </div>
        <div className=''>
          <Button className='w-60 h-12' onClick={() => handleSave(subTask, true)} disabled={savingTask}>
            {savingTask
              ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
              : (
                <span>
                  {props.mode === 'Create' ? 'Create Subtask' : 'Done'}
                </span>
              )}
          </Button>
        </div>
      </div>
      <div className='absolute bottom-0 right-0 w-full z-40 bg-white'>
        <Transition
          as={Fragment}
          show={showDeleteConfirmation}
          enter='transform transition ease-in duration-200 sm:duration-200'
          enterFrom='translate-x-full'
          enterTo='translate-x-0'
        >
          <div>
            <ConfirmationCard
              text='Are you sure you want to remove this subtask?'
              confirmText='Yes'
              cancelText='No'
              confirmButtonStyle='secondary'
              cancelButtonStyle='secondary'
              onConfirm={handleDelete}
              onCancel={() => setShowDeleteConfirmation(false)}
            />
          </div>
        </Transition>
      </div>
    </div>
  )
}
