import * as Yup from 'yup'

export const EmailsFormValidator = Yup.object().shape({
  obj: Yup.object().when('specificTime', {
    is: true,
    then: Yup.object().shape({
      yearAdmittedFrom: Yup.number()
        .max(Yup.ref('yearAdmittedThru'), `'From' date cannot be more than 'Thru' date`)
        .required('Required field'),
      yearAdmittedThru: Yup.number()
        .min(Yup.ref('yearAdmittedFrom'), `'Thru' date cannot be less than 'From' date`)
        .required('Required field'),
    }),
  }),
})
