import { FilterIcon } from '@heroicons/react/outline'
import { Card } from 'components/atoms/Card/Card'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import { Link } from 'components/atoms/Link/Link'
import { PopMenu } from 'components/atoms/PopMenu/PopMenu'
import { useAppState } from 'presenter'
import { useEffect, useState } from 'react'

export interface InteractionFilterCheckboxItem {
  itemId: number
  ietmText: string
  isChecked: boolean
}
export interface InteractionFilterProps {
  onFiltersChange?: (props: any) => void
}

export const InteractionFilter = (props: InteractionFilterProps) => {
  const [pamContactList, setPMAContactList] = useState<InteractionFilterCheckboxItem[]>([])
  const [areaOfAssistanceList, setAreaOfAssistanceList] = useState<InteractionFilterCheckboxItem[]>([])
  const [methodOfContactList, setMethodOfContactList] = useState<InteractionFilterCheckboxItem[]>([])
  const { pmaContacts, areasOfAssistance, methodsOfContact } = useAppState()
  const selectAllAreasOfAssistance = (isChecked: boolean) => {
    areaOfAssistanceList.forEach(item => item.isChecked = isChecked)
    props.onFiltersChange && props.onFiltersChange(getSelectedFilters())
  }
  const getSelectedFilters = () => {
    return {
      pmaContactIds: pamContactList.length > 0
        ? pamContactList.filter(item => item.isChecked).map(item => item.itemId).join(',')
        : undefined,
      methodOfContactIds: methodOfContactList.length > 0
        ? methodOfContactList.filter(item => item.isChecked).map(item => item.itemId).join(',')
        : undefined,
      areaOfAssistanceIds: areaOfAssistanceList.length > 0
        ? areaOfAssistanceList.filter(item => item.isChecked).map(item => item.itemId).join(',')
        : undefined,
    }
  }

  useEffect(() => {
    setPMAContactList(pmaContacts.map(item => {
      return { itemId: item.itemId!, ietmText: item.itemText!, isChecked: false }
    }))
    setAreaOfAssistanceList(areasOfAssistance.map(item => {
      return { itemId: item.itemId!, ietmText: item.itemText!, isChecked: false }
    }))
    setMethodOfContactList(methodsOfContact.map(item => {
      return { itemId: item.itemId!, ietmText: item.itemText!, isChecked: false }
    }))
  }, [pmaContacts, areasOfAssistance, methodsOfContact])

  return (
    <PopMenu text='Filter' icon={FilterIcon}>
      <Card>
        <div className='w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl'>
          <div className='py-4 px-4'>
            <div className='flex grid grid-cols-2 place-items-start gap-5 pt-2'>
              <div className='w-full pb-2 border-b text-sm text-left font-extrabold'>PMA Contact</div>
              <div className='w-full pb-2 border-b text-sm text-left font-extrabold'>Method Of Contact</div>
              <div className='flex grid gap-2 place-items-start pt-2'>
                {pamContactList.map((item, index) => {
                  return (
                    <Checkbox
                      text={item.ietmText}
                      defaultChecked={pamContactList[index].isChecked}
                      onChange={() => {
                        pamContactList[index].isChecked = !pamContactList[index].isChecked
                        props.onFiltersChange && props.onFiltersChange(getSelectedFilters())
                      }}
                    />
                  )
                })}
              </div>
              <div className='flex grid gap-2 place-items-start pt-2'>
                {methodOfContactList.map((item, index) => {
                  return (
                    <Checkbox
                      text={item.ietmText}
                      defaultChecked={methodOfContactList[index].isChecked}
                      onChange={(e) => {
                        methodOfContactList[index].isChecked = !methodOfContactList[index].isChecked
                        props.onFiltersChange && props.onFiltersChange(getSelectedFilters())
                      }}
                    />
                  )
                })}
              </div>
            </div>
            <div className='flex pt-10 grid grid-cols-2 place-items-start gap-5 pt-2'>
              <div className='w-full pb-2 border-b text-sm text-left font-extrabold'>Area Of Assistance</div>
            </div>
            <div className='flex gap-4 pb-1 pt-1'>
              <Link text='Select All' underline onClick={() => selectAllAreasOfAssistance(true)} />
              <Link text='Clear All' underline onClick={() => selectAllAreasOfAssistance(false)} />
            </div>
            <div className='flex grid grid-cols-2 gap-2 place-items-start pt-2'>
              {areaOfAssistanceList.map((item, index) => {
                return (
                  <Checkbox
                    text={item.ietmText}
                    checked={areaOfAssistanceList[index].isChecked}
                    onChange={(e) => {
                      areaOfAssistanceList[index].isChecked = !areaOfAssistanceList[index].isChecked
                      props.onFiltersChange && props.onFiltersChange(getSelectedFilters())
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </Card>
    </PopMenu>
  )
}
