import { getUIConfig } from 'gateways/ConfigGateway'
import {
  addNewContact,
  downloadContactOfficeClosureForm,
  getContactById,
  getContactOfficeClosureForm,
  updateContact,
} from 'gateways/ContactGateway'
import {
  addInteraction,
  deleteInteraction,
  getInteractionById,
  getInteractionsPage,
  updateInteraction,
} from 'gateways/InteractionGateway'
import { getLookUp } from 'gateways/LookUpsGateway'
import { addTag, getAllTags } from 'gateways/TagsGateway'
import {
  addNewSubTask,
  addNewTask,
  addTaskComment,
  deleteTask,
  getAllTaskSummary,
  getTaskDetails,
  getTaskStatistics,
  getTasksPage,
  getTasksPastDuePage,
  saveTask,
} from 'gateways/TasksGateway'

import {
  addNewSubTaskTemplate,
  addNewTaskTemplate,
  deleteTaskTemplate,
  getAllTaskTemplates,
  getTaskTemplateDetails,
  saveTaskTemplate,
} from 'gateways/TaskTemplatesGateway'
import { getEmailOrAddressList } from 'gateways/UniverseGateway'

import {
  checkIfTokenIsValid,
  forgotPassword,
  getAllUsers,
  getUserById,
  loginRequest,
  refreshToken,
  resetPassword,
  validateResetPasswordToken,
} from 'gateways/UserGateway'
import { IContext, createOvermind } from 'overmind'
import { createActionsHook, createStateHook } from 'overmind-react'
import {
  displayDashboardAction,
  displayLawyerProfileAction,
  displayModalAction,
  displayNotFoundAction,
  displayOfficeClosureFormAction,
  displaySlideOutAction,
  getLookUps,
  loadUIConfigAction,
  redirectAction,
  setSelectedCompletedTasks,
  setSelectedUpcomingTasks,
} from './actions/actions'
import { addNewContactAction, downloadContactOfficeClosureFormAction, updateContactAction } from './actions/contacts'
import { displayEmailsDashboardAction, downloadEmailOrAddressFile } from './actions/emails'
import {
  addNewInteractionAction,
  deleteInteractionAction,
  displayEditInteractionAction,
  displayNewInteractionAction,
  loadInteractionDetailsAction,
  setInteractionsPage,
  updateInteractionAction,
} from './actions/interactions'
import { loadLookUpsAction, showMoreContacts, updateContacts } from './actions/lookUps'
import { displayReportsDashboardAction } from './actions/reports'
import { addNewTagAction, getAllTagsAction } from './actions/tags'
import {
  addNewSubTaskAction,
  addNewTaskAction,
  addTaskCommentAction,
  deleteTaskAction,
  displayCreateTaskAction,
  displayNewTaskFromTemplateModal,
  displayTaskDetailsModal,
  displayTasksDashboardAction,
  fetchTasksPaginationAction,
  filterTasks,
  getTasksPageResponse,
  loadSubTaskDetailsAction,
  saveTaskAction,
  setIsCreateNewTaskFormDirty,
  setSelectedTask,
  setTaskPastDuePage,
  setTaskSummaryFilters,
  setTasksPage,
  setUsersFilters,
} from './actions/tasks'
import {
  addNewSubTaskTemplateAction,
  addNewTaskTemplateAction,
  deleteSubTaskTemplateAction,
  deleteTaskTemplateAction,
  displayNewTaskTemplateModalAction,
  displayTaskTemplateDetailsModal,
  displayTasksTemplatesDashboardAction,
  loadSubTaskTemplateDetailsAction,
  saveTaskTemplateAction,
} from './actions/taskTemplates'
import {
  displayForgotPasswordAction,
  displayLoginAction,
  displayResetPasswordAction,
  forgotPasswordAction,
  loginUser,
  logoutUser,
  refreshTokenAction,
  resetPasswordAction,
  validateAuthenticatedUser,
} from './actions/users'
import { redirect } from './router'
import { clientState, coreState, userState } from './state'

const overmindConfig = {
  actions: {
    addNewContactAction,
    updateContactAction,
    displayEmailsDashboardAction,
    displayReportsDashboardAction,
    displayTasksDashboardAction,
    fetchTasksPaginationAction,
    displayTasksTemplatesDashboardAction,
    displaySlideOutAction,
    displayDashboardAction,
    displayModalAction,
    displayNotFoundAction,
    displayNewInteractionAction,
    displayEditInteractionAction,
    displayCreateTaskAction,
    displayLawyerProfileAction,
    displayOfficeClosureFormAction,
    displayLoginAction,
    displayForgotPasswordAction,
    displayResetPasswordAction,
    filterTasks,
    setTaskSummaryFilters,
    setUsersFilters,
    redirectAction,
    addNewInteractionAction,
    updateInteractionAction,
    deleteInteractionAction,
    addNewTagAction,
    addNewTaskAction,
    addTaskCommentAction,
    saveTaskAction,
    deleteTaskAction,
    addNewSubTaskAction,
    getAllTagsAction,
    downloadEmailOrAddressFile,
    getLookUps,
    loadInteractionDetailsAction,
    loadLookUpsAction,
    setInteractionsPage,
    setTasksPage,
    setSelectedTask,
    setSelectedUpcomingTasks,
    setSelectedCompletedTasks,
    getTasksPageResponse,
    setTaskPastDuePage,
    setIsCreateNewTaskFormDirty,
    displayTaskTemplateDetailsModal,
    addNewTaskTemplateAction,
    saveTaskTemplateAction,
    deleteTaskTemplateAction,
    deleteSubTaskTemplateAction,
    loadSubTaskDetailsAction,
    displayNewTaskTemplateModalAction,
    loadSubTaskTemplateDetailsAction,
    addNewSubTaskTemplateAction,
    displayTaskDetailsModal,
    displayNewTaskFromTemplateModal,
    validateAuthenticatedUser,
    loginUser,
    forgotPasswordAction,
    resetPasswordAction,
    logoutUser,
    refreshTokenAction,
    updateContacts,
    showMoreContacts,
    loadUIConfigAction,
    downloadContactOfficeClosureFormAction,
  },
  effects: {
    addNewContact,
    updateContact,
    loginRequest,
    getTasksPage,
    getTasksPastDuePage,
    getTaskStatistics,
    getAllTaskSummary,
    getEmailOrAddressList,
    getTaskDetails,
    addNewTask,
    addNewSubTask,
    addTaskComment,
    saveTask,
    deleteTask,
    getTaskTemplateDetails,
    addNewTaskTemplate,
    addNewSubTaskTemplate,
    saveTaskTemplate,
    deleteTaskTemplate,
    getInteractionsPage,
    getUserById,
    redirect,
    getInteractionById,
    addInteraction,
    updateInteraction,
    deleteInteraction,
    getAllTaskTemplates,
    addTag,
    getAllTags,
    getAllUsers,
    getLookUp,
    refreshToken,
    forgotPassword,
    validateResetPasswordToken,
    resetPassword,
    getContactById,
    checkIfTokenIsValid,
    getUIConfig,
    getContactOfficeClosureForm,
    downloadContactOfficeClosureForm,
  },
  state: {
    ...coreState,
    ...userState,
    ...clientState,
  },
}

export type Context = IContext<{
  state: typeof overmindConfig.state
  actions: typeof overmindConfig.actions
  effects: typeof overmindConfig.effects
}>

export const overmindApp = createOvermind(overmindConfig)

export const useAppState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
