import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TaskPageRequestParameters, TaskSummaryFilters } from 'entities/types/Tasks'
import {
  CommentDetail,
  SubTaskDetail,
  TaskComment,
  TaskDetail,
  TaskResponse,
  TaskResponsePagedResult,
  TaskStatisticsResponse,
  TaskSummaryResponse,
} from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getTasksPage(params?: TaskPageRequestParameters): Promise<TaskResponsePagedResult> {
  let url_ = 'api/Task/getAll?'
  if (params?.pageNumber !== undefined) url_ += 'PageNumber=' + encodeURIComponent('' + params?.pageNumber) + '&'
  if (params?.pageSize !== undefined) url_ += 'PageSize=' + encodeURIComponent('' + params?.pageSize) + '&'
  if (params?.fromDate !== undefined) url_ += 'FromDate=' + encodeURIComponent('' + params?.fromDate) + '&'
  if (params?.toDate !== undefined) url_ += 'ToDate=' + encodeURIComponent('' + params?.toDate) + '&'
  if (params?.contactId !== undefined) url_ += 'ContactId=' + encodeURIComponent('' + params?.contactId) + '&'
  if (params?.assignedToIds !== undefined) {
    url_ += 'AssignedToIds=' + encodeURIComponent('' + params?.assignedToIds) + '&'
  }
  if (params?.orderBy !== undefined) url_ += 'OrderBy=' + encodeURIComponent('' + params?.orderBy) + '&'
  if (params?.orderDirection !== undefined) {
    url_ += 'OrderDirection=' + encodeURIComponent('' + params?.orderDirection) + '&'
  }
  if (params?.statusIds !== undefined && params?.statusIds !== '') {
    url_ += 'StatusIds=' + encodeURIComponent('' + params?.statusIds) + '&'
  }
  if (params?.taskStatus !== undefined && params?.taskStatus !== '') {
    url_ += 'StatusIds=' + encodeURIComponent('' + params?.taskStatus) + '&'
  }
  if (params?.tags !== undefined) {
    url_ += 'TagIds=' + encodeURIComponent('' + params?.tags) + '&'
  }
  if (params?.taskTypes !== undefined) {
    url_ += 'TaskTypes=' + encodeURIComponent('' + params?.taskTypes) + '&'
  }
  if (params?.noTags !== undefined) {
    url_ += 'noTags=' + encodeURIComponent('' + params?.noTags) + '&'
  }
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskResponsePagedResult>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getTasksPastDuePage(params?: TaskPageRequestParameters): Promise<TaskResponsePagedResult> {
  let url_ = 'api/Task/getAllPastDue?'
  if (params?.pageNumber !== undefined) url_ += 'PageNumber=' + encodeURIComponent('' + params?.pageNumber) + '&'
  if (params?.pageSize !== undefined) url_ += 'PageSize=' + encodeURIComponent('' + params?.pageSize) + '&'
  if (params?.contactId !== undefined) url_ += 'ContactId=' + encodeURIComponent('' + params?.contactId) + '&'
  if (params?.orderBy !== undefined) url_ += 'OrderBy=' + encodeURIComponent('' + params?.orderBy) + '&'
  if (params?.orderDirection !== undefined) {
    url_ += 'OrderDirection=' + encodeURIComponent('' + params?.orderDirection) + '&'
  }
  if (params?.statusIds !== undefined && params?.statusIds !== '') {
    url_ += 'StatusIds=' + encodeURIComponent('' + params?.statusIds) + '&'
  }
  if (params?.assignedToIds !== undefined && params?.assignedToIds !== '') {
    url_ += 'AssignedToIds=' + encodeURIComponent('' + params?.assignedToIds) + '&'
  }
  if (params?.taskStatus !== undefined && params?.taskStatus !== '') {
    url_ += 'StatusIds=' + encodeURIComponent('' + params?.taskStatus) + '&'
  }
  if (params?.tags !== undefined) {
    url_ += 'TagIds=' + encodeURIComponent('' + params?.tags) + '&'
  }
  if (params?.taskTypes !== undefined) {
    url_ += 'TaskTypes=' + encodeURIComponent('' + params?.taskTypes) + '&'
  }
  if (params?.noTags !== undefined) {
    url_ += 'noTags=' + encodeURIComponent('' + params?.noTags) + '&'
  }

  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskResponsePagedResult>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getTaskStatistics(assignTo?: number, statusIds?: string): Promise<TaskStatisticsResponse> {
  let url_ = 'api/Task/getStatistics?'
  if (assignTo !== undefined) url_ += 'AssignTo=' + encodeURIComponent('' + assignTo) + '&'
  if (statusIds !== undefined) url_ += 'StatusIds=' + encodeURIComponent('' + statusIds) + '&'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskStatisticsResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getAllTaskSummary(filter?: TaskSummaryFilters): Promise<TaskSummaryResponse[]> {
  let url_ = 'api/Task/getAllSummary?'
  if (filter) {
    if (filter.fromDate !== undefined) url_ += 'FromDate=' + encodeURIComponent('' + filter.fromDate) + '&'
    if (filter.toDate !== undefined) url_ += 'ToDate=' + encodeURIComponent('' + filter.toDate) + '&'
    if (filter.assignedToIds !== undefined) {
      url_ += 'AssignedToIds=' + encodeURIComponent('' + filter.assignedToIds) + '&'
    }
    if (filter.tags !== undefined) {
      url_ += 'TagIds=' + encodeURIComponent('' + filter.tags.filter(t => t.checked).map(t => t.tag.id)) + '&'
    }
    if (filter.taskStatus !== undefined) {
      url_ += 'StatusIds=' + encodeURIComponent(
        '' + filter.taskStatus.filter(t => t.checked).map(t => t.taskStatus.itemId),
      ) + '&'
    }
    if (filter.taskTypes !== undefined) {
      url_ += 'TaskTypes=' + encodeURIComponent('' + filter.taskTypes.filter(t => t.checked).map(t => t.taskType)) + '&'
    }
    if (filter?.noTags !== undefined) {
      url_ += 'noTags=' + encodeURIComponent('' + filter?.noTags) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')
  }

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskSummaryResponse[]>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addNewTask(task: TaskDetail): Promise<TaskDetail> {
  let url_ = 'api/Task/addNew'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: task,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskDetail>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addNewSubTask(task: SubTaskDetail): Promise<TaskResponse> {
  let url_ = 'api/Task/addNewSubTask'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: task,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function saveTask(task: TaskDetail): Promise<TaskDetail> {
  let url_ = 'api/Task/update'

  let options_: AxiosRequestConfig = {
    method: 'Put',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: task,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskDetail>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getTaskDetails(taskId: number): Promise<TaskDetail> {
  let url_ = 'api/Task/getById?id=' + taskId

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskResponsePagedResult>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addTaskComment(taskComment: TaskComment): Promise<CommentDetail> {
  let url_ = 'api/Task/addComment'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: taskComment,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function deleteTask(taskId: number): Promise<void> {
  let url_ = 'api/Task/delete?id=' + taskId

  let options_: AxiosRequestConfig = {
    method: 'delete',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
