import { Modal } from 'components/molecules'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'
import { TaskTemplateEditor } from '../TaskTemplateEditor/TaskTemplateEditor'
import { ModalLeaveWarning } from './ModalLeaveWarning'

export const TaskTemplateDetailsModal = () => {
  const { currentModal, selectedTaskTemplateDetails, loadingTaskDetails, isCreateNewTaskFormDirty } = useAppState()
  const { displayModalAction, setIsCreateNewTaskFormDirty } = useActions()

  const [closing, setClosing] = useState<boolean>(false)

  const onClose = async () => {
    setClosing(true)
    if (isCreateNewTaskFormDirty) return setIsCreateNewTaskFormDirty(true)
    displayModalAction('None')
  }

  const onCancel = () => displayModalAction('None')

  return (
    <Modal
      isOpen={currentModal === 'TaskTemplateDetailsModal'}
      onClose={onClose}
      shouldCloseOnEsc
      contentStyle={{
        width: '550px',
      }}
    >
      <div className='h-[calc(100vh-80px)]'>
        {!loadingTaskDetails
          ? (
            <TaskTemplateEditor
              mode='View'
              taskTemplate={selectedTaskTemplateDetails}
              onSaved={(shouldClose) => shouldClose ? onCancel() : undefined}
            />
          )
          : (
            <div className='h-[558px] flex flex-col items-center justify-center mx-auto'>
              <SpinnerSVG className='inline mr-2 w-5 h-5 text-gray-200 animate-spin fill-primary-200' />
            </div>
          )}
        <ModalLeaveWarning
          onClickNo={() => setClosing(false)}
          onClickYes={onCancel}
          show={isCreateNewTaskFormDirty && closing}
        />
      </div>
    </Modal>
  )
}
