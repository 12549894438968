import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/outline'
import React from 'react'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export interface AccordionProps {
  title?: React.ReactNode
  children: React.ReactNode
  loading?: boolean
  defaultOpen?: boolean
  customTitle?: React.ReactNode
  onClick?: () => void
}

export const Accordion = (props: AccordionProps) => {
  return (
    <div className='shadow-md rounded-lg'>
      <Disclosure defaultOpen={props.defaultOpen}>
        {({ open }) => {
          return (
            <>
              {props.customTitle
                ? (
                  <div className='flex px-2 block h-16 justify-between rounded-lg px-4 py-3 text-left text-lg font-medium'>
                    {props.customTitle}
                    <Disclosure.Button className='w-full'>
                      <div className='h-full grid place-items-center justify-end'>
                        <ChevronUpIcon
                          className={`${!open ? 'rotate-180 transform' : ''} h-7 w-7 text-primary-300`}
                        />
                      </div>
                    </Disclosure.Button>
                  </div>
                )
                : (
                  <Disclosure.Button
                    className='flex w-full h-16 justify-between rounded-lg px-4 py-3 text-left text-sm font-medium'
                    onClick={!open ? props.onClick : undefined}
                  >
                    <span className='px-2 font-extrabold h-full grid place-items-center'>
                      {props.title}
                    </span>
                    {props.loading
                      ? (
                        <div className='h-full grid place-items-center'>
                          <SpinnerSVG className='inline grid place-items-center mr-2 w-5 h-5 text-gray-200 animate-spin fill-primary-200' />
                        </div>
                      )
                      : (
                        <div className='h-full grid place-items-center '>
                          <ChevronUpIcon
                            className={`${!open ? 'rotate-180 transform' : ''} h-7 w-7 text-primary-300`}
                          />
                        </div>
                      )}
                  </Disclosure.Button>
                )}
              <Transition
                show={open}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Disclosure.Panel className='px-4 pb-2'>
                  {props.loading ? <div className='h-80 px-2'>Loading...</div> : (
                    props.children
                  )}
                </Disclosure.Panel>
              </Transition>
            </>
          )
        }}
      </Disclosure>
    </div>
  )
}
