import './Tooltip.css'
interface TooltipProps {
  children: React.ReactNode
  toolTipText: string
}
export const Tooltip = (props: TooltipProps) => {
  return (
    <div className='tooltip'>
      {props.children}
      <span className='tooltiptext border shadow-md'>
        {props.toolTipText}
      </span>
    </div>
  )
}
