import { useAppState } from 'presenter'
import { reloadDashboard } from '../../../presenter/actions/reports'

export const ReportsDashboard = () => {
  const { uiConfig } = useAppState()

  return (
    <div className='w-full h-full'>
      <h1 className='text-primary-300'>
        Reports

        <button
          type='button'
          onClick={reloadDashboard}
          className='inline-flex items-center justify-center w-auto px-3 py-2 space-x-2 text-sm font-medium text-white transition    rounded appearance-none cursor-pointer select-none disabled:pointer-events-none disabled:opacity-75'
        >
          <svg id='spinRep' xmlns='http://www.w3.org/2000/svg' className='w-5 h-5' viewBox='0 0 20 20' fill='blue'>
            <path
              fill-rule='evenodd'
              d='M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z'
              clip-rule='evenodd'
            />
          </svg>
        </button>
      </h1>
      <iframe
        title='Reports'
        id='reportsIf'
        className='w-full h-full'
        src={uiConfig?.reports?.embeddedUrl}
      >
      </iframe>
    </div>
  )
}
