import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ContactInsertRequest, ContactResponse, ContactUpdateRequest, OfficeClosureForm } from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getContactById(id: number): Promise<ContactResponse> {
  let url_ = 'api/Contact/getById?'
  if (id === null) throw new Error("The parameter 'id' cannot be null.")
  else if (id !== undefined) url_ += 'contactId=' + encodeURIComponent('' + id) + '&'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<ContactResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addNewContact(body: ContactInsertRequest): Promise<ContactResponse> {
  let url_ = 'api/Contact/addNew'

  const content_ = JSON.stringify(body)

  let options_: AxiosRequestConfig = {
    method: 'POST',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: content_,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<ContactResponse>(response)
    } else if (_response.status !== 200 && _response.status === 409) {
      const _responseText = response
      return throwException(_responseText, _response.status, response, _response.headers)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function updateContact(body: ContactUpdateRequest): Promise<ContactResponse> {
  let url_ = 'api/Contact/update'

  const content_ = JSON.stringify(body)

  let options_: AxiosRequestConfig = {
    method: 'PUT',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: content_,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<ContactResponse>(response)
    } else if (_response.status !== 200 && _response.status === 409) {
      const _responseText = response
      return throwException(_responseText, _response.status, response, _response.headers)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
export async function getContactOfficeClosureForm(id: number): Promise<OfficeClosureForm> {
  let url_ = 'api/OfficeClosureForm/getOfficeClosureForm?'
  if (id === null) throw new Error("The parameter 'id' cannot be null.")
  else if (id !== undefined) url_ += 'contactId=' + encodeURIComponent('' + id) + '&'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<OfficeClosureForm>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
export async function downloadContactOfficeClosureForm(data: OfficeClosureForm): Promise<Blob> {
  let url_ = 'api/OfficeClosureForm/downloadOfficeClosureForm'
  let options_: AxiosRequestConfig = {
    method: 'POST',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: data,
    responseType: 'blob',
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return response
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
