import { RadioGroup } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import Select, { Option } from 'components/atoms/Select/Select'
import { EmailOrAddressFormRequest } from 'entities/types/Emails'
import { ListType } from 'generated/iTypes'
import { getListTypeName, getYearsList } from 'helpers/stringHelpers'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const dateAdmitted = [
  { id: 1, name: 'Any date admitted' },
  { id: 2, name: 'Specific time frame' },
]

export const EmailsTypesList = () => {
  const { formState: { errors }, setValue, getValues } = useFormContext<EmailOrAddressFormRequest>()
  const [selectedDA, setSelectedDA] = useState(dateAdmitted[0])
  const [displayDateRanges, setDisplayDateRanges] = useState<boolean>(false)
  return (
    <div>
      <h3 className='mb-6'>Type of list</h3>
      <div className='px-4 py-6 rounded-xl bg-bgLightBlue grid grid-cols-7'>
        <div className='col-span-4'>
          <p className='font-extrabold text-sm mb-1'>List type</p>
          <Select
            label='Select One'
            default={(Object.values(ListType) as Array<ListType>).map((exp, index) => ({
              enum: exp,
              key: index.toString(),
              value: getListTypeName(exp),
            })).find(f => f.enum === getValues('obj.listType'))}
            selectedValueFontWeight='extrabold'
            data={(Object.values(ListType) as Array<ListType>).map((exp, index) => ({
              enum: exp,
              key: index.toString(),
              value: getListTypeName(exp),
            }))}
            onChange={(value) => {
              setValue('obj.listType', value.enum)
            }}
          />
        </div>
        <div className='col-span-3 pl-20'>
          <RadioGroup
            value={selectedDA}
            onChange={(e: any) => {
              if (e.id === 2) {
                setValue('specificTime', true)
                setDisplayDateRanges(true)
              } else {
                setValue('specificTime', false)
                setDisplayDateRanges(false)
                setValue('obj.yearAdmittedFrom', undefined)
                setValue('obj.yearAdmittedThru', undefined)
              }
              setSelectedDA(e)
            }}
          >
            <RadioGroup.Label className='text-primary-300 font-extrabold text-sm mb-1 normal-case'>
              Date admitted
            </RadioGroup.Label>
            {dateAdmitted.map((item) => (
              <RadioGroup.Option
                className='mb-6 cursor-pointer'
                key={item.id}
                value={item}
              >
                {({ checked }) => (
                  <div className='inline-flex place-items-center gap-2'>
                    <div
                      className={`w-6 h-6 text-primary-300 rounded-full bg-white ${
                        checked
                          ? 'border-[6px] border-primary-300'
                          : 'border-2 border-outlineLightBlue'
                      } 
                     focus:ring-blue-500 focus:ring-2`}
                    >
                    </div>
                    {item.name}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        {displayDateRanges
          && (
            <div className='col-span-7'>
              <div className='w-1/2 grid grid-cols-9 gap-x-4 gap-y-6'>
                <div className='col-span-4'>
                  <p className='font-extrabold text-sm mb-1'>Year range</p>
                  <Select
                    label='Select year'
                    selectedValueFontWeight='extrabold'
                    data={getYearsList().map((exp, index) => ({
                      enum: exp,
                      key: index.toString(),
                      value: exp.toString(),
                    } as Option))}
                    onChange={(value) => {
                      setValue('obj.yearAdmittedFrom', value.value)
                    }}
                  />
                  <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                    {errors.obj?.yearAdmittedFrom
                      && (
                        <>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          {errors.obj.yearAdmittedFrom.message}
                        </>
                      )}
                  </span>
                </div>
                <div className='pt-8 text-center'>
                  <span>to</span>
                </div>
                <div className='mt-6 col-span-4'>
                  <Select
                    label='Select year'
                    selectedValueFontWeight='extrabold'
                    data={getYearsList().map((exp, index) => ({
                      enum: exp,
                      key: index.toString(),
                      value: exp.toString(),
                    } as Option))}
                    onChange={(value) => {
                      setValue('obj.yearAdmittedThru', value.value)
                    }}
                  />
                  <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                    {errors.obj?.yearAdmittedThru
                      && (
                        <>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          {errors.obj.yearAdmittedThru.message}
                        </>
                      )}
                  </span>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}
