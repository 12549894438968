import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Accordion } from 'components/atoms/Accordion/Accordion'
import { Card } from 'components/atoms/Card/Card'
import { Link } from 'components/atoms/Link/Link'
import { useActions, useAppState } from 'presenter'
import { CreateNewTaskTemplateButton } from './CreateNewTaskTemplateButton'

export const TaskTemplates = () => {
  const { allTaskTemplates } = useAppState()
  const { redirectAction, displayTaskTemplateDetailsModal } = useActions()
  return (
    <div className='-ml-8 -mr-8 text-primary-300'>
      <div className='h-[calc(100vh-80px)] overflow-auto flex-wrap bg-bgLightBlue'>
        <div className='border-b bg-white'>
          <div className='relative px-12 py-8'>
            <h1>Task Templates</h1>
            <CreateNewTaskTemplateButton />
            <div className='mt-8'>
              <Link
                className='font-extrabold'
                text='Back to tasks'
                onClick={() => {
                  redirectAction('/tasks')
                }}
                secondary
                iconleft={ArrowLeftIcon}
              />
            </div>
          </div>
        </div>
        <div className='relative flex flex-row'>
          <div className='flex flex-col grow pt-4'>
            <div className='px-12 pt-4'>
              <h3 className='mb-6 text-left capitalize'>Available task templates</h3>
              <div className='grid gap-5 mb-6'>
                {allTaskTemplates.map((taskTemplate, index) => (
                  <div
                    key={`tasksTemplates-${index}`}
                    className='bg-white rounded-lg'
                  >
                    <Accordion
                      defaultOpen={index === 0}
                      customTitle={
                        <div className='flex h-full place-items-center gap-2'>
                          <Link
                            text={taskTemplate.name}
                            onClick={() =>
                              displayTaskTemplateDetailsModal({
                                task: taskTemplate,
                                subTask: {},
                              })}
                          />
                          <div className='shrink-0 ml-1 mt-0.5 h-6 w-6 rounded-full text-sm font-extrabold h-full grid place-items-center border-2 text-[#EB7100] border-[#EB7100]'>
                            {taskTemplate.subTasks?.length}
                          </div>
                        </div>
                      }
                    >
                      <div className='pb-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-9 5xl:grid-cols-13 gap-y-5'>
                        {taskTemplate.subTasks?.map((subTask, idx) => {
                          return (
                            <div
                              key={`taskTemplatesTaskCardDiv-${idx}`}
                              className='cursor-pointer'
                            >
                              <Card
                                width={'w-52'}
                                onClick={() =>
                                  displayTaskTemplateDetailsModal({
                                    task: taskTemplate,
                                    subTask: subTask,
                                  })}
                              >
                                <div className='w-full h-60'>
                                  <div className='py-4 px-4 mb-3'>
                                    <div className='font-extrabold text-left mb-2'>
                                      {subTask.name}
                                    </div>
                                    <p className='line-clamp-6 text-left text-textGrey'>
                                      {subTask.taskDetails}
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          )
                        })}
                      </div>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
