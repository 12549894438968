import { Button } from 'components/atoms/Button/Button'

export interface ConfirmationCardProps {
  text: string
  confirmText: string
  cancelText: string
  confirmButtonStyle?: 'primary' | 'secondary'
  cancelButtonStyle?: 'primary' | 'secondary'
  onConfirm?: () => void
  onCancel?: () => void
}

export const ConfirmationCard = (props: ConfirmationCardProps) => {
  return (
    <div>
      <h4>
        {props.text}
      </h4>
      <div className='mt-2 grid grid-cols-2 gap-4 justify-items-center'>
        <Button
          color={props.cancelButtonStyle}
          onClick={props.onCancel}
          className='w-full'
        >
          {props.cancelText}
        </Button>
        <Button
          color={props.confirmButtonStyle}
          onClick={props.onConfirm}
          className='w-full'
        >
          {props.confirmText}
        </Button>
      </div>
    </div>
  )
}
