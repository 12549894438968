import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SearchIcon } from '@heroicons/react/solid'
import { Link } from 'components/atoms/Link/Link'
import { ComboBoxItem } from 'generated/iTypes'
import { useActions } from 'presenter'
import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

type SearchInputProps = {
  value?: ComboBoxItem
  data: ComboBoxItem[]
  placeholder?: string
  onChange?: (value: ComboBoxItem) => void
  onInputChange?: (value: string) => void
  addNewPerson?: boolean
  fetchingData?: boolean
  fetchingNewData?: boolean
  hideInputValue?: boolean
  showMore?: boolean
  onShowMore?: () => void
}

export const SearchInput = (props: SearchInputProps): React.ReactElement => {
  const { displayModalAction } = useActions()
  const [selectedData, setSelectedData] = useState<ComboBoxItem | undefined>(props.value)
  const [query, setQuery] = useState('')
  const [displayValues, setDisplayValues] = useState<boolean>(false)

  useEffect(() => {
    if (props.value && props.addNewPerson) {
      setSelectedData(props.value)
      props.onChange && props.onChange(props.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.addNewPerson])

  const filteredPeople = props.data
  // const filteredPeople = query === ''
  //   ? props.data
  //   : props.data?.filter((person) =>
  //     person?.itemText?.toLowerCase()
  //       .replace(/\s+/g, '')
  //       .includes(query.toLowerCase().replace(/\s+/g, ''))
  //   )
  const onInputChange = (value: string) => {
    setDisplayValues(false)
    if (value === '') setSelectedData({})
    setQuery(value)
    props.onInputChange && props.onInputChange(value)
  }

  return (
    <div className='relative flex-row grow'>
      <Combobox
        value={selectedData}
        onChange={e => {
          setSelectedData(e)
          if (props.onChange) {
            props.onChange(e as ComboBoxItem)
          }
        }}
      >
        <div className='relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'>
          <Combobox.Button className='absolute inset-y-0 left-0 flex items-center pl-2'>
            <SearchIcon
              className='h-5 w-5 text-primary-100'
              aria-hidden='true'
            />
          </Combobox.Button>
          <Combobox.Input
            displayValue={(person) => props.hideInputValue ? '' : (person as ComboBoxItem).itemText ?? ''}
            className='shadow appearance-none border rounded-lg w-full py-2 px-3 pl-8 text-primary-300 leading-tight focus:ring-blue-500 focus:outline-none focus:shadow-outline placeholder-gray-500'
            type='search'
            placeholder={props.placeholder}
            onChange={(event) => onInputChange(event.target.value)}
          />
          <Combobox.Button
            className='absolute inset-y-0 left-0 flex items-center pl-2'
            onClick={() => setDisplayValues(true)}
          >
            <SearchIcon
              className='h-5 w-5 text-primary-100'
              aria-hidden='true'
            />
          </Combobox.Button>
        </div>
        {(query.length > 1 || displayValues)
          && (
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50'>
                {props.fetchingData
                  ? (
                    <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                      <SpinnerSVG className='inline mr-2 w-5 h-5 text-gray-200 animate-spin fill-primary-200' />
                    </div>
                  )
                  : (
                    filteredPeople?.length === 0 && query !== ''
                      ? (
                        <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                          Nothing found
                        </div>
                      )
                      : (
                        filteredPeople?.map((person, index) => (
                          <Combobox.Option
                            key={`SIComboBoxOption-${index}`}
                            className={({ active }) =>
                              `relative cursor-pointer select-none py-2 pl-10 pr-4 text-gray-700 ${
                                active ? 'bg-bgLightBlue' : 'bg-white'
                              }`}
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate font-bold`}
                                >
                                  {person.itemText}
                                </span>
                                {selected
                                  ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? '' : ''}`}
                                    >
                                      <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                    </span>
                                  )
                                  : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )
                  )}
                {(props.showMore && !props.fetchingData)
                  && (
                    !props.fetchingNewData
                      ? (
                        <li
                          key={`SIComboBoxOption-fetchingNewData`}
                          className={`relative cursor-pointer select-none py-2 pl-10 pr-4 text-gray-700 hover:bg-bgLightBlue bg-white`}
                          onClick={() => props.onShowMore && props.onShowMore()}
                        >
                          <Link text='Show more' />
                        </li>
                      )
                      : (
                        <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                          <SpinnerSVG className='inline mr-2 w-5 h-5 text-gray-200 animate-spin fill-primary-200' />
                        </div>
                      )
                  )}
                {(props.addNewPerson && !props.fetchingData)
                  && (
                    <li
                      key={`SIComboBoxOption-addPerson`}
                      className={`relative cursor-pointer select-none py-2 pl-10 pr-4 text-gray-700 hover:bg-bgLightBlue bg-white`}
                      onClick={() => displayModalAction('AddNewContactModal')}
                    >
                      <Link text='Add a new person' />
                    </li>
                  )}
              </Combobox.Options>
            </Transition>
          )}
      </Combobox>
    </div>
  )
}
