import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ResponseWrapper } from 'entities/types/ResponseWrapper'
import { SubTaskTemplateDetail, TaskTemplateDetail } from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getAllTaskTemplates(name?: string): Promise<TaskTemplateDetail[]> {
  let url_ = 'api/TaskTemplate/getAll?'
  if (name !== undefined) url_ += 'Name=' + encodeURIComponent('' + name) + '&'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskTemplateDetail[]>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addNewTaskTemplate(task: TaskTemplateDetail | ResponseWrapper): Promise<any> {
  let url_ = 'api/TaskTemplate/addNew'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: task,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskTemplateDetail>(response)
    } else if (_response.status === 409) {
      return { message: 'Another template with same name already exists', responseType: 'Warning' }
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addNewSubTaskTemplate(task: SubTaskTemplateDetail): Promise<SubTaskTemplateDetail> {
  let url_ = 'api/TaskTemplate/addNewSubTask'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: task,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskTemplateDetail>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function saveTaskTemplate(task: TaskTemplateDetail): Promise<TaskTemplateDetail> {
  let url_ = 'api/TaskTemplate/update'

  let options_: AxiosRequestConfig = {
    method: 'Put',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: task,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskTemplateDetail>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getTaskTemplateDetails(taskId: number): Promise<TaskTemplateDetail> {
  let url_ = 'api/TaskTemplate/getById?id=' + taskId

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TaskTemplateDetail>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function deleteTaskTemplate(taskId: number): Promise<void> {
  let url_ = 'api/TaskTemplate/delete?id=' + taskId

  let options_: AxiosRequestConfig = {
    method: 'delete',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
