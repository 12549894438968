import { InteractionPageRequestParameters } from 'entities/types/Interactions'
import { InteractionInsertRequest, InteractionResponse, InteractionUpdateRequest } from 'generated/iTypes'
import { sortInteractions } from 'helpers/interactionsHelpers'
import { setSideActiveScreen } from 'helpers/siteHelpers'
import { formatDate } from 'helpers/tasksHelper'
import { RouteNames } from 'presenter/router'
import { toast } from 'react-toastify'
import { Context } from '..'
import { defaultErrorHandler } from './actions'
import { loadLookUpsAction } from './lookUps'

export async function displayNewInteractionAction(context: Context) {
  try {
    setSideActiveScreen(context, RouteNames.NewInteraction)
    context.state.newContactCreation = {}
    context.state.currentSlideOut = 'NewInteractionSlideOut'
    await loadLookUpsAction(context)
    context.state.users = await context.effects.getAllUsers()
    context.state.allTaskTemplates = await context.effects.getAllTaskTemplates()
    context.state.tags = await context.effects.getAllTags()
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayEditInteractionAction(context: Context) {
  try {
    context.state.newContactCreation = {}
    context.state.currentModal = 'LoadingModal'
    await loadLookUpsAction(
      context,
      `${context.state.interactionState.contact?.firstName} ${context.state.interactionState.contact?.lastName}`,
    )
    context.state.currentModal = 'EditInteractionDetailsModal'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function loadInteractionDetailsAction(context: Context, interaction: InteractionResponse) {
  try {
    context.state.interactionState = { ...interaction }
    context.state.currentModal = 'InteractionDetailsModal'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function addNewInteractionAction(context: Context, interaction: InteractionInsertRequest) {
  try {
    interaction.userId = context.state.authenticatedUser.userId
    await context.effects.addInteraction(interaction)
    if (context.state.selectedLawyer) {
      await setInteractionsPage(context, {
        pageNumber: 1,
        pageSize: 8,
        contactId: context.state.selectedLawyer.contactId,
      })
    } else {
      await setInteractionsPage(context, {
        pageNumber: 1,
        pageSize: 6,
        pmaContactIds: context.state.authenticatedUser.userId?.toString(),
      })
    }
    if (interaction.task) {
      if (context.state.currentPage === 'Dashboard') {
        await context.actions.setTasksPage({
          pageNumber: 1,
          pageSize: 3,
          orderBy: 'dueDate',
          orderDirection: 'ASC',
          fromDate: formatDate(new Date().toString(), 'MM-DD-YYYY'),
          statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
            ',',
          ),
          assignedToIds: context.state.authenticatedUser.userId?.toString(),
        })
        context.state.taskStatistics = await context.effects.getTaskStatistics(
          context.state.authenticatedUser.userId,
          context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(','),
        )
      } else if (context.state.currentPage === 'LawyerProfile') {
        const curr = new Date()
        await context.actions.setSelectedUpcomingTasks({
          fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
          pageNumber: 1,
          pageSize: context.state.maxAmountItemsPerPage,
          contactId: context.state.selectedLawyer?.contactId,
          statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
            ',',
          ),
        })
      }
    }
    context.actions.displaySlideOutAction('None')
    toast.success('Interaction saved successfully!')

    if ('ReportsDashboard' === context.state.currentPage) {
      var ifr = document.getElementById('reportsIf') as HTMLIFrameElement
      if (ifr != null) {
        let srcValue = ifr.src
        ifr.src = srcValue
      }
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setInteractionsPage(context: Context, value: InteractionPageRequestParameters) {
  try {
    const interactionsPagedResult = await context.effects.getInteractionsPage(value)
    if (interactionsPagedResult) context.state.interactionsPaged = interactionsPagedResult
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateInteractionAction(
  context: Context,
  interaction: InteractionUpdateRequest,
): Promise<InteractionResponse | undefined> {
  try {
    interaction.userId = context.state.authenticatedUser.userId
    const oldInteraction = context.state.interactionsPaged.items?.find(f =>
      f.interactionId === interaction.interactionId
    )
    const updatedInteraction = await context.effects.updateInteraction(interaction)

    const interactions = context.state.interactionsPaged.items || []
    const indexToUpdate = interactions.findIndex(i => i.interactionId === updatedInteraction.interactionId)
    if (indexToUpdate >= 0) {
      interactions[indexToUpdate] = updatedInteraction
    }
    context.state.interactionsPaged.items = sortInteractions(interactions)

    if (context.state.currentPage === 'LawyerProfile') {
      const keepIt = oldInteraction?.contact?.contactId === updatedInteraction.contact?.contactId
      if (!keepIt) {
        context.state.interactionsPaged.items = interactions.filter(f =>
          f.interactionId !== updatedInteraction.interactionId
        )
      }
    }

    toast.success('Interaction updated successfully!')
    context.actions.displayModalAction('None')

    if ('ReportsDashboard' === context.state.currentPage) {
      window.location.reload()
    }

    return updatedInteraction
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
export async function deleteInteractionAction(context: Context, interactionId: number): Promise<void> {
  try {
    await context.effects.deleteInteraction(interactionId)
    if (context.state.currentPage === 'LawyerProfile') {
      await setInteractionsPage(context, {
        pageNumber: 1,
        pageSize: 6,
        contactId: context.state.selectedLawyer?.contactId,
      })
    } else {
      await setInteractionsPage(context, {
        pageNumber: 1,
        pageSize: 6,
        pmaContactIds: context.state.authenticatedUser.userId?.toString(),
      })
    }
    context.state.currentModal = 'None'
    toast.success('Interaction deleted successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
