import { InteractionResponse } from 'generated/iTypes'
import { getDate } from './stringHelpers'

export const getContactLinkText = (interactionState: InteractionResponse) => {
  return interactionState.contact?.firstName
    + ' ' + interactionState.contact?.lastName
    + ' | #' + interactionState.contact?.barNumber
    + ' | ' + (interactionState.contact?.personType?.name ?? '[No Type]')
    + ' | ' + interactionState.contact?.city
    + ', ' + interactionState.contact?.state
}

export const sortInteractions = (array?: InteractionResponse[]) => {
  return array?.sort((postA, postB) => {
    if (getDate(postA.interactionDate) < (getDate(postB.interactionDate))) {
      return 1
    } else if (getDate(postA.interactionDate) > getDate(postB.interactionDate)) {
      return -1
    }
    return 0
  })
}
