import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { InteractionInsertRequest, TagResponse } from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getAllTags(): Promise<TagResponse[]> {
  let url_ = 'api/Tag/getAll'

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<TagResponse[]>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function addTag(tag: InteractionInsertRequest): Promise<TagResponse> {
  let url_ = 'api/Tag/addNew'

  let options_: AxiosRequestConfig = {
    method: 'Post',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
    data: tag,
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<InteractionInsertRequest>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
