import React from 'react'

type SlideOutFooterProps = {
  children: React.ReactNode
  justify?: string
}

export function SlideOutFooter({
  children,
  justify = 'justify-end',
}: SlideOutFooterProps) {
  const footerStyle = `flex fixed ${justify} py-4 px-6 bottom-0 right-0 w-full bg-white`
  return <footer className={footerStyle}>{children}</footer>
}
