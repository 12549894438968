import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { UIConfig } from 'generated/iTypes'
import { isAxiosError } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function getUIConfig(): Promise<UIConfig | undefined> {
  let url_ = '/api/Configuration/getUIConfig'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    data: '',
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<UIConfig>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      return Promise.reject(response)
    }
  })
}
