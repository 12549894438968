import { FilterIcon } from '@heroicons/react/outline'
import { Card } from 'components/atoms/Card/Card'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import { Link } from 'components/atoms/Link/Link'
import { PopMenu } from 'components/atoms/PopMenu/PopMenu'
import TextInput from 'components/atoms/TextInput/TextInput'
import { TaskTypes } from 'entities/enums/TaskTypes'
import { TagSelection, TaskStatusSelection, TaskTypeSelection } from 'entities/types/Filters'
import { TaskSummaryFilters } from 'entities/types/Tasks'
import { getMappedStatus } from 'helpers/tasksHelper'
import { useState } from 'react'

export interface TaskFilterProps {
  onSummaryFilter?: (props: any) => void
  filters?: TaskSummaryFilters
}

export const TaskFilter = (props: TaskFilterProps) => {
  const [tagSelections, setTagSelections] = useState<TagSelection[]>(props.filters?.tags ?? [])
  const [statusSelections, setStatusSelections] = useState<TaskStatusSelection[]>(props.filters?.taskStatus ?? [])
  const [taskTypeSelections, setTaskTypeSelections] = useState<TaskTypeSelection[]>(props.filters?.taskTypes ?? [])
  const [taglessSelection, setTaglessSelection] = useState(false)

  const handleSelectAllTags = (checked: boolean) => {
    if (checked) {
      setTaglessSelection(false)
    }
    const updatedTags = tagSelections.map(t => ({ tag: t.tag, checked: checked }))
    setTagSelections(updatedTags)
    if (props.onSummaryFilter) {
      props.onSummaryFilter({ tags: updatedTags, noTags: false })
    }
  }

  const handleTagSelection = (tag: TagSelection, checked: boolean) => {
    if (checked) {
      setTaglessSelection(false)
    }
    const updatedTags = tagSelections.map(t => t.tag.id === tag.tag.id ? { tag: t.tag, checked: checked } : t)
    setTagSelections(updatedTags)
    if (props.onSummaryFilter) {
      props.onSummaryFilter({ tags: updatedTags, noTags: false })
    }
  }

  const handleSelectAllStatus = (checked: boolean) => {
    const updatedStatus = statusSelections.map(t => ({ taskStatus: t.taskStatus, checked: checked }))
    setStatusSelections(updatedStatus)
    if (props.onSummaryFilter) {
      props.onSummaryFilter({ taskStatus: updatedStatus })
    }
  }

  const handleStatusSelection = (taskSTatus: TaskStatusSelection, checked: boolean) => {
    const updatedStatus = statusSelections.map(t =>
      t.taskStatus.itemId === taskSTatus.taskStatus.itemId ? { taskStatus: t.taskStatus, checked: checked } : t
    )
    setStatusSelections(updatedStatus)
    if (props.onSummaryFilter) {
      props.onSummaryFilter({ taskStatus: updatedStatus })
    }
  }

  const handleTaskTypeSelection = (taskType: TaskTypes, checked: boolean) => {
    const updatedTaskTypes = taskTypeSelections.map(t =>
      t.taskType === taskType ? { taskType: t.taskType, checked: checked } : t
    )
    setTaskTypeSelections(updatedTaskTypes)
    if (props.onSummaryFilter) {
      props.onSummaryFilter({ taskTypes: updatedTaskTypes })
    }
  }

  const toggleTaglessSelection = () => {
    const value = !taglessSelection
    setTaglessSelection(value)
    const updatedTags = value ? tagSelections.map(t => ({ tag: t.tag, checked: false })) : tagSelections
    setTagSelections(updatedTags)
    if (props.onSummaryFilter) {
      props.onSummaryFilter({ tags: updatedTags, noTags: value })
    }
  }

  return (
    <PopMenu text='Filter tasks' icon={FilterIcon}>
      <Card>
        <div className='w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl'>
          <div className='py-4 px-4'>
            <div className='w-full pb-2 border-b text-sm text-left font-extrabold'>Date range</div>
            <div className='flex items-center pt-2'>
              <div className='flex py-3 grid-cols-2 place-items-center'>
                <div className='inline-flex'>
                  <span className='pr-2 pt-2'>From</span>
                  <TextInput
                    defaultValue={props.filters?.fromDate}
                    type='date'
                    maxLength={52}
                    onChange={(e) => props.onSummaryFilter && props.onSummaryFilter({ fromDate: e.target.value })}
                  />
                </div>
                <div className='inline-flex'>
                  <span className='mx-4 pt-2'>to</span>
                  <TextInput
                    defaultValue={props.filters?.toDate}
                    type='date'
                    maxLength={52}
                    onChange={(e) => props.onSummaryFilter && props.onSummaryFilter({ toDate: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className='w-full pt-12 pb-2 border-b text-sm text-left font-extrabold'>Tasks</div>
            <div className='flex grid grid-cols-3 place-items-start pt-2'>
              <Checkbox
                text={'Main Tasks'}
                defaultChecked
                onChange={(e) => handleTaskTypeSelection(TaskTypes.Parent, e)}
                checked={taskTypeSelections.find(t => t.taskType === TaskTypes.Parent)?.checked}
              />
              <Checkbox
                text={'Subtasks'}
                defaultChecked
                onChange={(e) => handleTaskTypeSelection(TaskTypes.SubTask, e)}
                checked={taskTypeSelections.find(t => t.taskType === TaskTypes.SubTask)?.checked}
              />
            </div>
            <div className='w-full pt-12 pb-2 border-b text-sm text-left font-extrabold'>Status</div>
            <div className='flex gap-4 pb-1 pt-1'>
              <Link text='Select all' underline onClick={() => handleSelectAllStatus(true)} />
              <Link text='Clear all' underline onClick={() => handleSelectAllStatus(false)} />
            </div>
            <div className='flex grid grid-cols-3 gap-2 place-items-start pt-2'>
              {props.filters?.taskStatus?.map((status, index) => {
                const mappedStatus = getMappedStatus(status.taskStatus.code ?? '')
                return (
                  <Checkbox
                    key={`taskFilterChckbxKey-${index}`}
                    text={mappedStatus.text}
                    defaultChecked={status.checked}
                    checked={status.checked}
                    onChange={(e) => handleStatusSelection(status, e)}
                  />
                )
              })}
            </div>
            <div className='w-full pt-12 pb-2 border-b text-sm text-left font-extrabold'>Tags</div>
            <div className='flex gap-4 pb-1 pt-1'>
              <Link text='Select all' underline onClick={() => handleSelectAllTags(true)} />
              <Link text='Clear all' underline onClick={() => handleSelectAllTags(false)} />
            </div>
            <div className='flex grid grid-cols-3 place-items-start pt-2'>
              {tagSelections?.map((t, index) => {
                return (
                  <Checkbox
                    key={`taskFilterTagChckbxKey-${index}`}
                    text={t.tag.name ?? ''}
                    defaultChecked={t.checked}
                    checked={t.checked}
                    onChange={e => handleTagSelection(t, e)}
                  />
                )
              })}
            </div>
            <div className='w-full mt-4 border-b text-sm text-left font-extrabold'></div>
            <div className='flex mt-2'>
              <Checkbox
                text='Only tasks without tags'
                checked={taglessSelection}
                onChange={() => toggleTaglessSelection()}
              />
            </div>
          </div>
        </div>
      </Card>
    </PopMenu>
  )
}
