import { PlusIcon } from '@heroicons/react/outline'
import { Link } from 'components/atoms/Link/Link'
import { TagAssignment } from 'entities/enums/TagAssignment'
import { SubTaskTemplateDetail, TagResponse } from 'generated/iTypes'
import { useActions } from 'presenter'
import React from 'react'
import { TaskTemplateCard } from '../TaskTemplateCard/TaskTemplateCard'

type TaskTemplateSubTasksProps = {
  subTasks?: SubTaskTemplateDetail[]
  parentTags?: TagResponse[]
  onChange?: (subTasks: SubTaskTemplateDetail[]) => void
  onShowEditor?: (mode: 'Create' | 'View', index?: number) => void
}
export const TaskTemplateSubTasks = (props: TaskTemplateSubTasksProps): React.ReactElement => {
  const { loadSubTaskTemplateDetailsAction, setIsCreateNewTaskFormDirty } = useActions()

  const handleCardClick = async (subTask: SubTaskTemplateDetail | undefined, index: number) => {
    await loadSubTaskTemplateDetailsAction({ taskId: subTask?.id, subTask: subTask ?? {} })
    if (props.onShowEditor) {
      props.onShowEditor('View', index)
    }
  }

  const handleAddNewSubTask = async () => {
    let parentTags = props.parentTags?.map<TagResponse>(t => ({
      id: t.id,
      assignmentType: TagAssignment.FromParent.valueOf(),
      name: t.name,
    }))
    await loadSubTaskTemplateDetailsAction({ taskId: undefined, subTask: { tags: parentTags } })
    if (props.onShowEditor) {
      props.onShowEditor('Create')
    }
    setIsCreateNewTaskFormDirty(true)
  }

  return (
    <div className=''>
      <Link
        text={'Add a subtask'}
        iconleft={PlusIcon}
        onClick={handleAddNewSubTask}
      />
      <div className='pb-8 mt-2 overflow-x-hidden'>
        {props.subTasks?.map((st, index) => (
          <div className='mt-2 cursor-pointer' key={index}>
            <TaskTemplateCard
              key={`dashboardTaskCard-${index}`}
              tags={st.tags}
              title={st.name}
              onClick={() => handleCardClick(st, index)}
            >
              {st.taskDetails}
            </TaskTemplateCard>
          </div>
        ))}
      </div>
    </div>
  )
}
