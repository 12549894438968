import { InformationCircleIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import { Link } from 'components/atoms/Link/Link'
import { Pill } from 'components/atoms/Pills/Pills'
import { ComboBoxItem } from 'generated/iTypes'
import { getMappedStatus } from 'helpers/tasksHelper'
import { useAppState } from 'presenter'
import React, { useEffect, useRef, useState } from 'react'

type StatusSelectorProps = {
  value?: ComboBoxItem
  onChange?: (user: ComboBoxItem) => void
  valid?: boolean
  errorMessage?: string
}
export const StatusSelector = (props: StatusSelectorProps): React.ReactElement => {
  const { taskStatus } = useAppState()
  const [toggleStatus, setToggleStatus] = useState<boolean>(false)
  const [selectedTaskStatus, setSelectedTaskStatus] = useState<ComboBoxItem>(props.value!)
  const selectorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    toggleStatus && selectorRef.current?.focus()
  }, [toggleStatus])

  const handleSelectTaskStatus = (taskStatus: ComboBoxItem) => {
    setSelectedTaskStatus(taskStatus)
    setToggleStatus(false)
    if (props.onChange) {
      props.onChange(taskStatus)
    }
  }

  return (
    <div tabIndex={2} ref={selectorRef} onBlur={() => setToggleStatus(false)}>
      <div className=''>
        {selectedTaskStatus
          ? (
            <span className='hover:cursor-pointer' onClick={() => setToggleStatus(!toggleStatus)}>
              <Pill
                text={selectedTaskStatus.itemText}
                status={getMappedStatus(selectedTaskStatus.code ?? '').status}
              />
            </span>
          )
          : (
            <div>
              <Link
                text='Add status'
                iconleft={toggleStatus ? MinusIcon : PlusIcon}
                onClick={() => setToggleStatus(!toggleStatus)}
              />
              {!props.valid && props.errorMessage !== ''
                ? (
                  <div>
                    <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {props.errorMessage}
                    </span>
                  </div>
                )
                : null}
            </div>
          )}
      </div>
      {toggleStatus
        && (
          <div className='absolute flex z-10'>
            <div className='block rounded-xl shadow-xl bg-white text-center w-36'>
              <div className='flex flex-col gap-6 inline py-4 px-4'>
                {taskStatus?.map((ts, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectTaskStatus(ts)}
                    className='hover:cursor-pointer'
                  >
                    <Pill
                      text={ts.itemText}
                      status={getMappedStatus(ts.code ?? '').status}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
    </div>
  )
}
