import { Card } from 'components/atoms/Card/Card'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import { Link } from 'components/atoms/Link/Link'
import { CheckBoxType } from 'entities/types/Generals'

interface FilterTaskCardProps {
  title: string
  data: CheckBoxType[]
  maxCols?: string
  onSelectedAll: () => void
  onDisSelectedAll: () => void
  onChange: (value: boolean, index: number) => void
}

export const FilterTaskCard = ({
  maxCols = '2',
  ...props
}: FilterTaskCardProps) => {
  return (
    <Card>
      <div className='text-center w-full'>
        <div className='flex py-3 px-4 justify-between'>
          <span className='font-extrabold'>{props.title}</span>
          <div className='flex gap-4'>
            <Link text='Select all' underline onClick={props.onSelectedAll} />
            <Link text='Clear all' underline onClick={props.onDisSelectedAll} />
          </div>
        </div>
        <div
          className={`pt-1 pl-4 gap-x-5 mb-3 text-left ${maxCols && `grid grid-cols-${maxCols}`}`}
        >
          {props.data.map((item, index) => (
            <div className='pb-6' key={`divFilterTaskCard-${index}`}>
              <Checkbox
                key={`ckboxFilterTaskCard-${index}`}
                text={item.obj.itemText ?? ''}
                checked={props.data[index]?.checked}
                onChange={(newValue) => {
                  props.onChange(newValue, index)
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Card>
  )
}
