import { LoopkUps } from 'entities/enums/LoopkUps'
import { TaskPageRequestParameters } from 'entities/types/Tasks'
import { ComboBoxItem } from 'generated/iTypes'
import { clearSideActiveScreen, clearTopActiveScreen } from 'helpers/siteHelpers'
import { formatDate } from 'helpers/tasksHelper'
import { ConflictError, ForbiddenError, InternalServerError, UnauthenticatedError } from 'presenter/errors'
import { toast } from 'react-toastify'
import { Context } from '..'
import { setInteractionsPage } from './interactions'
import { setTasksPage } from './tasks'

export async function defaultErrorHandler(context: Context, err: unknown) {
  if (err instanceof UnauthenticatedError) {
    await context.actions.refreshTokenAction()
  } else if (err instanceof ForbiddenError) {
    context.state.currentPage = 'Forbidden'
  } else if (err instanceof InternalServerError) {
    context.state.currentPage = 'Unexpected'
  } else if (err instanceof ConflictError) {
    toast.error(`Error: ${err.message}`)
  } else {
    context.state.currentSlideOut = 'None'
    context.state.currentModal = 'None'
    context.state.currentPage = 'Unexpected'
  }
}
export async function displayNotFoundAction(context: Context) {
  context.state.currentPage = 'NotFound'
}

export async function displayModalAction(
  context: Context,
  modalName:
    | 'AddNewContactModal'
    | 'EditAltContactMethodModal'
    | 'EditContactModal'
    | 'SlideOutWarningModal'
    | 'InteractionDetailsModalSG'
    | 'InteractionDetailsModal'
    | 'TaskCardDetailsModal'
    | 'CreateNewTaskModal'
    | 'CreateNewTaskTemplateModal'
    | 'TemplateSelectionModal'
    | 'EditInteractionDetailsModal'
    | 'None',
) {
  if (modalName === 'CreateNewTaskModal') {
    context.state.isCreateNewTaskFormDirty = false
    context.state.selectedSubTaskDetails = {}
    context.state.selectedTaskDetails = {}
    context.state.subTaskDirectSelect = false
  }
  if (modalName === 'AddNewContactModal' && context.state.currentModal === 'EditInteractionDetailsModal') {
    context.state.returnToEditInteractionModal = true
  }
  context.state.currentModal = modalName
  if (
    modalName === 'AddNewContactModal' || modalName === 'EditContactModal' || modalName === 'EditAltContactMethodModal'
  ) {
    context.state.personTypes = await context.effects.getLookUp(LoopkUps.PersonType)
  }
  if (modalName === 'None') context.state.returnToEditInteractionModal = false
}

export async function displaySlideOutAction(
  context: Context,
  slideOutName:
    | 'NewInteractionSlideOut'
    | 'None',
) {
  if (slideOutName === 'None') clearSideActiveScreen(context)
  if (slideOutName === 'NewInteractionSlideOut') context.state.newContactCreation = undefined
  context.state.currentSlideOut = slideOutName
}

export async function displayDashboardAction(context: Context) {
  try {
    context.state.currentPage = 'Loading'
    clearState(context)
    clearTopActiveScreen(context)
    context.state.users = await context.effects.getAllUsers()
    context.state.tags = await context.effects.getAllTags()
    context.state.taskStatus = await context.effects.getLookUp(LoopkUps.TaskStatus)
    context.state.taskStatistics = await context.effects.getTaskStatistics(
      context.state.authenticatedUser.userId,
      context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(','),
    )
    await setTasksPage(context, {
      pageNumber: 1,
      pageSize: 3,
      orderBy: 'dueDate',
      orderDirection: 'ASC',
      fromDate: formatDate(new Date().toString(), 'MM-DD-YYYY'),
      statusIds: context.state.taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
        ',',
      ),
      assignedToIds: context.state.authenticatedUser.userId?.toString(),
    })
    await setInteractionsPage(context, {
      pageNumber: 1,
      pageSize: 6,
      pmaContactIds: context.state.authenticatedUser.userId?.toString(),
    })
    context.state.contacts = await context.effects.getLookUp(LoopkUps.Contact)
    context.state.currentPage = 'Dashboard'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function redirectAction(context: Context, url: string) {
  context.effects.redirect(url)
}

export async function displayLawyerProfileAction(context: Context, lawyerId: number) {
  try {
    context.state.currentPage = 'Loading'

    context.state.officeClosures = await context.effects.getLookUp(LoopkUps.OfficeClosure)
    context.state.optedInStatuses = await context.effects.getLookUp(LoopkUps.OptedInTypes)
    const taskStatuses = await context.effects.getLookUp(LoopkUps.TaskStatus)
    const upcommingTaskStatuses = taskStatuses.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(
      ',',
    )
    const completedTaskStatus = taskStatuses.filter(item => item.itemText === 'Done').map(item => item.itemId).join(',')
    const pastDueTaskStatus = taskStatuses.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(',')

    context.state.selectedLawyer = await context.effects.getContactById(lawyerId)
    context.state.taskStatus = taskStatuses
    context.actions.loadLookUpsAction()
    var curr = new Date()

    context.state.tasksPastDuePaged = await context.effects.getTasksPastDuePage({
      pageNumber: 1,
      pageSize: context.state.maxAmountItemsPerPage,
      contactId: context.state.selectedLawyer.contactId,
      statusIds: pastDueTaskStatus,
      orderBy: 'dueDate',
      orderDirection: 'DESC',
    })

    await setInteractionsPage(context, {
      pageNumber: 1,
      pageSize: 8,
      contactId: lawyerId,
    })
    await setSelectedUpcomingTasks(context, {
      fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
      pageNumber: 1,
      pageSize: context.state.maxAmountItemsPerPage,
      contactId: lawyerId,
      statusIds: upcommingTaskStatuses,
      orderDirection: 'ASC',
    })
    await setSelectedCompletedTasks(context, {
      pageNumber: 1,
      pageSize: context.state.maxAmountItemsPerPage,
      contactId: lawyerId,
      statusIds: completedTaskStatus,
    })

    clearTopActiveScreen(context)
    context.state.currentPage = 'LawyerProfile'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
export async function displayOfficeClosureFormAction(context: Context) {
  try {
    context.state.currentPage = 'Loading'
    context.state.users = await context.effects.getAllUsers()
    context.state.selectedLawyerOfficeClosureForm = await context.effects.getContactOfficeClosureForm(
      context.state.selectedLawyer?.contactId ?? 0,
    )
    context.state.currentPage = 'OfficeClosureFormPage'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setSelectedUpcomingTasks(
  context: Context,
  value?: TaskPageRequestParameters,
) {
  try {
    const lawyerTasks = await context.actions.getTasksPageResponse(value)
    if (lawyerTasks) context.state.selectedUpcomingTasks = lawyerTasks
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setSelectedCompletedTasks(
  context: Context,
  value?: TaskPageRequestParameters,
) {
  try {
    const lawyerTasks = await context.actions.getTasksPageResponse(value)
    if (lawyerTasks) context.state.selectedCompletedTasks = lawyerTasks
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getLookUps(context: Context, lookUp: LoopkUps): Promise<ComboBoxItem[] | undefined> {
  try {
    return await context.effects.getLookUp(lookUp)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function loadUIConfigAction(context: Context) {
  try {
    context.state.uiConfig = await context.effects.getUIConfig()
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function clearState(context: Context) {
  try {
    context.state.selectedLawyer = undefined
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
