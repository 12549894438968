import { ScreenSize } from 'entities/enums/ScreenSizes'
import { SidebarIcons } from 'entities/enums/SidebarIcons'
import { TaskTypes } from 'entities/enums/TaskTypes'
import { LibNavOption } from 'entities/types/NavOption'
import { TaskSummaryFilters } from 'entities/types/Tasks'
import {
  ComboBoxItem,
  ContactResponse,
  InteractionResponse,
  InteractionResponsePagedResult,
  OfficeClosureForm,
  SubTaskTemplateDetail,
  TagResponse,
  TaskDetail,
  TaskResponse,
  TaskResponsePagedResult,
  TaskStatisticsResponse,
  TaskSummaryResponse,
  TaskTemplateDetail,
  UIConfig,
  User,
} from 'generated/iTypes'
import { derived } from 'overmind'

export type CoreState = {
  authenticatedUser: User
  currentPage:
    | 'Login'
    | 'ForgotPassword'
    | 'ResetPassword'
    | 'Dashboard'
    | 'EmailsDashboard'
    | 'ReportsDashboard'
    | 'TasksDashboard'
    | 'TaskTemplates'
    | 'LawyerProfile'
    | 'OfficeClosureFormPage'
    | 'Forbidden'
    | 'Loading'
    | 'NotFound'
    | 'StyleGuide'
    | 'Unexpected'
  publicPages: string[]
  currentModal:
    | 'AddNewContactModal'
    | 'EditAltContactMethodModal'
    | 'EditContactModal'
    | 'SlideOutWarningModal'
    | 'InteractionDetailsModalSG'
    | 'InteractionDetailsModal'
    | 'TaskCardDetailsModal'
    | 'CreateNewTaskModal'
    | 'CreateNewTaskTemplateModal'
    | 'TaskTemplateDetailsModal'
    | 'TemplateSelectionModal'
    | 'EditInteractionDetailsModal'
    | 'LoadingModal'
    | 'None'
  currentSlideOut:
    | 'CreateTaskSlideOut'
    | 'NewInteractionSlideOut'
    | 'None'
  sideNavOptions: LibNavOption[]
  topNavOptions: LibNavOption[]
  validationErrors: Map<string, string>
  interactionState: InteractionResponse
  areasOfAssistance: ComboBoxItem[]
  interactionTypes: ComboBoxItem[]
  contacts: ComboBoxItem[]
  loadingContacts: boolean
  personTypes: ComboBoxItem[]
  methodsOfContact: ComboBoxItem[]
  allTaskSummary: TaskSummaryResponse[]
  loadingTaskSummary: boolean
  loadingTaskDetails: boolean
  taskStatistics: TaskStatisticsResponse
  taskStatus: ComboBoxItem[]
  tasksPaged: TaskResponsePagedResult
  tasksPastDuePaged: TaskResponsePagedResult
  tasksFilters: TaskSummaryFilters
  selectedTask: TaskResponse
  selectedTaskDetails: TaskDetail
  selectedSubTaskDetails: TaskDetail
  allTaskTemplates: TaskTemplateDetail[]
  selectedTaskTemplateDetails: TaskTemplateDetail
  selectedSubTaskTemplateDetails: SubTaskTemplateDetail
  interactionsPaged: InteractionResponsePagedResult
  selectedLawyer?: ContactResponse
  selectedUpcomingTasks: TaskResponsePagedResult
  selectedCompletedTasks: TaskResponsePagedResult
  tags: TagResponse[]
  pmaContacts: ComboBoxItem[]
  autoSaving: boolean
  newContactCreation?: ContactResponse
  isCreateNewTaskFormDirty: boolean
  subTaskDirectSelect: boolean
  taskTypes: string[]
  regions: ComboBoxItem[]
  officeClosures: ComboBoxItem[]
  optedInStatuses: ComboBoxItem[]
  maxAmountItemsPerPage: number
  uiConfig: UIConfig | undefined
  selectedLawyerOfficeClosureForm: OfficeClosureForm
  pagedTasksArray: TaskResponsePagedResult[]
  resultCount: number
  returnToEditInteractionModal: boolean
}

export const sideNavOptions: LibNavOption[] = [
  { current: false, href: '/logInteraction', icon: SidebarIcons.Phone, name: 'LogInteraction' },
  { current: false, href: '/createTask', icon: SidebarIcons.CircleCheck, name: 'CreateTask' },
]

export const TopBarOptions: LibNavOption[] = [
  { name: 'Emails', href: '/emails', current: false, icon: SidebarIcons.CircleCheck },
  { name: 'Reports', href: '/reports', current: false, icon: SidebarIcons.CircleCheck },
  { name: 'Tasks', href: '/tasks', current: false, icon: SidebarIcons.CircleCheck },
]

function getNavOptions(): LibNavOption[] {
  return TopBarOptions
}

export const getMaxItemsPerPage = (): number => {
  const iWidth = window.innerWidth
  switch (true) {
    case (iWidth >= ScreenSize['5xl']):
      return 13
    case (iWidth >= ScreenSize['4xl']):
      return 9
    case (iWidth >= ScreenSize['3xl']):
      return 7
    case (iWidth >= ScreenSize['2xl']):
      return 5
    case (iWidth >= ScreenSize['1xl']):
      return 5
    case (iWidth >= ScreenSize.xl):
      return 4
    case (iWidth >= ScreenSize.lg):
      return 3
    case (iWidth >= ScreenSize.md):
      return 2
    case (iWidth >= ScreenSize.sm):
      return 2
    default:
      return 2
  }
}

export const coreState: CoreState = {
  authenticatedUser: {} as User,
  currentModal: 'None',
  currentPage: 'Dashboard',
  publicPages: ['Login', 'ForgotPassword', 'ResetPassword'],
  currentSlideOut: 'None',
  sideNavOptions: sideNavOptions,
  topNavOptions: derived((coreState: CoreState) => getNavOptions()),
  validationErrors: new Map<string, string>(),
  interactionState: {},
  interactionsPaged: {
    items: [],
  },
  tasksPaged: {
    items: [],
  },
  tasksPastDuePaged: {
    items: [],
  },
  areasOfAssistance: [],
  interactionTypes: [],
  contacts: [],
  methodsOfContact: [],
  selectedLawyer: {},
  selectedUpcomingTasks: {},
  selectedCompletedTasks: {},
  allTaskSummary: [],
  selectedTask: {},
  selectedTaskDetails: {},
  selectedSubTaskDetails: {},
  selectedTaskTemplateDetails: {},
  selectedSubTaskTemplateDetails: {},
  taskStatus: [],
  tags: [],
  loadingTaskSummary: false,
  loadingTaskDetails: false,
  tasksFilters: {},
  pmaContacts: [],
  taskStatistics: {
    totalTasksDueToday: 0,
    totalTasksDueWeek: 0,
    totalTasksPastDue: 0,
  },
  autoSaving: false,
  isCreateNewTaskFormDirty: false,
  allTaskTemplates: [],
  personTypes: [],
  loadingContacts: false,
  subTaskDirectSelect: false,
  taskTypes: [TaskTypes.Parent.valueOf(), TaskTypes.SubTask.valueOf()],
  regions: [],
  officeClosures: [],
  optedInStatuses: [],
  maxAmountItemsPerPage: getMaxItemsPerPage(),
  uiConfig: {},
  selectedLawyerOfficeClosureForm: {},
  pagedTasksArray: [],
  resultCount: 25,
  returnToEditInteractionModal: false,
}

export type UserState = {
  userEdit: {}
  userSearchString: string
  users: User[]
}

export const userState: UserState = {
  userEdit: {
    email: '',
    id: '',
    securityRoles: [],
    status: '',
  },
  userSearchString: '',
  users: [],
}
export type ClientState = {}

export const clientState: ClientState = {}
