import { ClipboardCheckIcon } from '@heroicons/react/outline'
import CalendarWarning from '../../../assets/icons/calendarWarning.svg'
import Timer from '../../../assets/icons/timer.svg'
import UserCollab from '../../../assets/icons/userCollaborate.svg'

export enum DashboardAlertsIcon {
  Timer,
  ClipBoard,
  UserCollab,
  CalendarWarning,
}

export interface DashboardNotiProps {
  line1?: string
  line2?: string
  value?: number
  icon?: DashboardAlertsIcon
}
export const DashboardAlerts = ({
  icon = DashboardAlertsIcon.Timer,
  ...props
}: DashboardNotiProps) => {
  return (
    <div className='flex grid-rows-2 grid-flow-col items-center'>
      <div className='flex shrink-0 rounded-full h-12 w-12 bg-primary-200  text-white items-center justify-center'>
        {icon === DashboardAlertsIcon.Timer && <img src={Timer} alt='icon' />}
        {icon === DashboardAlertsIcon.ClipBoard && <ClipboardCheckIcon className='h-8 w-8' />}
        {icon === DashboardAlertsIcon.UserCollab && <img src={UserCollab} alt='icon' />}
        {icon === DashboardAlertsIcon.CalendarWarning && <img src={CalendarWarning} alt='icon' />}
      </div>
      <h1 className='pl-4 pr-2 text-[48px]'>{props.value}</h1>
      <div className='grid grid-rows-2 font-semibold text-left font-semibold'>
        <span className='row-span-1 align-text-bottom'>
          {props.line1}
        </span>
        <span className='row-span-2 align-text-top'>
          {props.line2}
        </span>
      </div>
    </div>
  )
}
