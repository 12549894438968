import { MinusIcon, PlusIcon } from '@heroicons/react/outline'
import { Link } from 'components/atoms/Link/Link'
import { Tag } from 'components/atoms/Tag/Tag'
import TextInput from 'components/atoms/TextInput/TextInput'
import { TagAssignment } from 'entities/enums/TagAssignment'
import { ResponseWrapper } from 'entities/types/ResponseWrapper'
import { TagResponse } from 'generated/iTypes'
import { getMappedTagsColor } from 'helpers/tasksHelper'
import { showToast } from 'helpers/toastHelpers'
import { useActions, useAppState } from 'presenter'
import React, { FocusEvent, useEffect, useState } from 'react'

type TagSelectorProps = {
  value?: TagResponse[]
  onChange?: (tags: TagResponse[]) => void
}
export const TagSelector = (props: TagSelectorProps): React.ReactElement => {
  const { tags } = useAppState()
  const { addNewTagAction } = useActions()
  const [selectedTags, setSelectedTags] = useState<TagResponse[]>(props.value ?? [])
  const [toggleTags, setToggleTags] = useState<boolean>(false)
  const [currentTag, setCurrentTag] = useState<string>()

  useEffect(() => {
    setSelectedTags(props.value ?? [])
  }, [props.value])

  const handleOnBlur = (e: FocusEvent<HTMLDivElement, Element>) => {
    const currentTarget = e.currentTarget
    requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setToggleTags(false)
      }
    })
  }

  const handleEnterTag = async (tag: string) => {
    tag = tag.trim()
    let match = tags?.find(t => t.name !== undefined ? t.name.toLowerCase() === tag.toLowerCase() : false)
    if (!match) {
      try {
        let response = await addNewTagAction({ name: tag })
        let created = response as TagResponse
        if (created.id) {
          handleSelectTag(created)
        } else {
          showToast(response as ResponseWrapper)
        }
      } catch {}
    } else {
      handleSelectTag(match)
    }
  }

  const handleSelectTag = (tag: TagResponse) => {
    if (!selectedTags.map(t => t.name).includes(tag.name)) {
      let updatedTags = [...selectedTags, tag]
      setSelectedTags(updatedTags)
      if (props.onChange) {
        props.onChange(updatedTags)
      }
    }
    setToggleTags(false)
    setCurrentTag(undefined)
  }

  const handleRemoveTag = (tag: TagResponse) => {
    let updated = selectedTags.filter(t => t !== tag)
    setSelectedTags(updated)
    if (props.onChange) {
      props.onChange(updated)
    }
  }

  return (
    <div tabIndex={2} onBlur={handleOnBlur}>
      <div className='flex flex-wrap gap-0.5'>
        {selectedTags.map((t, index) => (
          <span key={index}>
            <Tag
              text={t.name}
              color={getMappedTagsColor(t.name ?? '')}
              onClose={(t.assignmentType === TagAssignment.FromParent.valueOf())
                ? undefined
                : (() => handleRemoveTag(t))}
            />
          </span>
        ))}
        <Link
          text='Add tag'
          iconleft={toggleTags ? MinusIcon : PlusIcon}
          onClick={() => setToggleTags(!toggleTags)}
        />
      </div>
      {toggleTags
        && (
          <div className='absolute flex w-full z-10'>
            <div className='block rounded-xl shadow-xl bg-white text-center w-48'>
              <div className='flex flex-col inline py-2'>
                <div className='px-2'>
                  <TextInput
                    placeholder='Add new tag'
                    hasAutoFocus={true}
                    maxLength={52}
                    onChange={(e) => setCurrentTag(e.target.value)}
                    onKeyDown={(e) => {
                      ;(currentTag && e.key === 'Enter') && handleEnterTag(currentTag)
                    }}
                  />
                </div>
                {tags?.filter(t =>
                  t.name?.toLowerCase().includes(currentTag?.toLowerCase() ?? '')
                  && !selectedTags.map(st => st.id).includes(t.id)
                ).slice(0, 5).map((tag, index) => (
                  <span
                    key={index}
                    onClick={() => handleSelectTag(tag)}
                    className='py-2 px-4 text-left text-textGrey hover:bg-bgLightBlue hover:cursor-pointer'
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
    </div>
  )
}
