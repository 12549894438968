import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/outline'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import { Link } from 'components/atoms/Link/Link'
import Select, { Option } from 'components/atoms/Select/Select'
import TextArea from 'components/atoms/TextArea/TextArea'
import TextInput from 'components/atoms/TextInput/TextInput'
import { LawyerAlternativeContact, OfficeClosureForm } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { AlternativeContact } from './AlternativeContact'
import { DownloadFormButton } from './DownloadFormButton'

export const OfficeClosureFormPage = () => {
  const {
    users,
    selectedLawyerOfficeClosureForm,
  } = useAppState()

  const [officeClosureForm, setOfficeClosureForm] = useState<OfficeClosureForm>(selectedLawyerOfficeClosureForm)
  const handleAlternateContactMethod = (alternateContactMethod: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        attorneyInformation: {
          ...officeClosureForm.content?.attorneyInformation,
          alternateContactMethod: alternateContactMethod,
        },
      },
    })
  }
  const handlePLFAssignTo = (user: Option) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        plfInformation: {
          ...officeClosureForm.content?.plfInformation,
          pmaId: user.key,
          pma: user.value,
        },
      },
    })
  }
  const hanldeReasonIsDead = (isDead: boolean) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        reasonForClosure: { ...officeClosureForm.content?.reasonForClosure, isDead: isDead },
      },
    })
  }
  const hanldeReasonIsIncapacity = (isIncapacity: boolean) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        reasonForClosure: { ...officeClosureForm.content?.reasonForClosure, isIncapacity: isIncapacity },
      },
    })
  }
  const hanldeReasonIsRetirement = (isRetirement: boolean) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        reasonForClosure: { ...officeClosureForm.content?.reasonForClosure, isRetirement: isRetirement },
      },
    })
  }
  const hanldeReasonIsDiscipline = (isDiscipline: boolean) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        reasonForClosure: { ...officeClosureForm.content?.reasonForClosure, isDiscipline: isDiscipline },
      },
    })
  }
  const hanldeReasonIsPersonal = (isPersonal: boolean) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        reasonForClosure: { ...officeClosureForm.content?.reasonForClosure, isPersonal: isPersonal },
      },
    })
  }
  const hanldeReasonOtherReason = (otherReason: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        reasonForClosure: { ...officeClosureForm.content?.reasonForClosure, otherReason: otherReason },
      },
    })
  }
  const hanldeNotes = (notes: string) => {
    setOfficeClosureForm({ ...officeClosureForm, content: { ...officeClosureForm.content, notes: notes } })
  }
  const hanldePLFClaimsAttorney = (claimsAttorney: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        plfInformation: { ...officeClosureForm.content?.plfInformation, claimsAttorney: claimsAttorney },
      },
    })
  }
  const hanldePLFOAAPCounselor = (oaapCounselor: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        plfInformation: { ...officeClosureForm.content?.plfInformation, oaapCounselor: oaapCounselor },
      },
    })
  }
  const hanldePLFDefensePanelMember = (defensePanelMember: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        plfInformation: { ...officeClosureForm.content?.plfInformation, defensePanelMember: defensePanelMember },
      },
    })
  }
  const hanldePLFDefensePanelMemberPhoneNumber = (phoneNumber: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        plfInformation: { ...officeClosureForm.content?.plfInformation, phoneNumber: phoneNumber },
      },
    })
  }
  const hanldePLFDefensePanelMemberEmail = (email: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        plfInformation: { ...officeClosureForm.content?.plfInformation, email: email },
      },
    })
  }
  const hanldePLFDefensePanelActiveFileComment = (activeFileComment: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, activeFileComment: activeFileComment },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileStoredWith = (closedFileStoredWith: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: {
          ...officeClosureForm.content?.filesInformation,
          closedFileStoredWith: closedFileStoredWith,
        },
      },
    })
  }
  const hanldePLFDefensePanelClosedFilePhoneNumber = (closedFilePhoneNumber: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: {
          ...officeClosureForm.content?.filesInformation,
          closedFilePhoneNumber: closedFilePhoneNumber,
        },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileEmail = (closedFileEmail: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, closedFileEmail: closedFileEmail },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileAddress1 = (closedFileAddress1: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, closedFileAddress1: closedFileAddress1 },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileCity = (closedFileCity: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, closedFileCity: closedFileCity },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileState = (closedFileState: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, closedFileState: closedFileState },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileZipCode = (closedFileZipCode: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, closedFileZipCode: closedFileZipCode },
      },
    })
  }
  const hanldePLFDefensePanelClosedFileAddress2 = (closedFileAddress2: string) => {
    setOfficeClosureForm({
      ...officeClosureForm,
      content: {
        ...officeClosureForm.content,
        filesInformation: { ...officeClosureForm.content?.filesInformation, closedFileAddress2: closedFileAddress2 },
      },
    })
  }
  const handleAlternativeContact = (alternativeContact?: LawyerAlternativeContact) => {
    let items = [...officeClosureForm.content?.lawyerAlternativeContactList ?? []]
    let newContactId = officeClosureForm.content?.lawyerAlternativeContactList?.length ?? 0
    alternativeContact = alternativeContact ?? { id: newContactId + 1 }
    let index = items.findIndex(item => item.id === alternativeContact?.id)
    if (index === -1) {
      items.push(alternativeContact)
    } else {
      items[index] = { ...alternativeContact }
    }
    setOfficeClosureForm({
      ...officeClosureForm,
      content: { ...officeClosureForm.content, lawyerAlternativeContactList: [...items] },
    })
  }
  const {
    displayLawyerProfileAction,
  } = useActions()

  return (
    <div className='-ml-8 -mr-8 text-primary-300'>
      <div className='h-[calc(100vh-80px)] overflow-auto flex-wrap bg-bgLightBlue'>
        <div className='border-b bg-white'>
          <div className='relative px-12 py-8 h-48'>
            <h1>Office Closure Form</h1>
            <div className='absolute top-0 right-0 mr-12 mt-12'>
              <DownloadFormButton officeClosureForm={officeClosureForm} />
            </div>
            <div className='flex justify-between'>
              <div className='mt-8'>
                <Link
                  text='Back to profile'
                  iconleft={ArrowLeftIcon}
                  secondary
                  onClick={() => {
                    displayLawyerProfileAction(officeClosureForm.contacId!)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='relative flex flex-row'></div>

        <div className='relative grow bg-white pt-10 px-6'>
          <div className='rounded-lg  bg-bgLightBlue py-8 px-8'>
            <div className=' rounded-lg bg-white py-8 px-8'>
              <div className='w-full pb-2'>
                <h3>Attorney information</h3>
              </div>
              <div className='w-full pb-1 grid grid-cols-5 mt-5'>
                <div className='col-span-3'>
                  <span className='font-bold text-sm mb-1'>Attorney Name</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>OSB No.</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>Status</span>
                </div>
                <div className='col-span-3'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.name}
                  </span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.barNumber}
                  </span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.status}
                  </span>
                </div>
              </div>
              <div className='w-full pb-1 grid grid-cols-5 mt-10'>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>Address</span>
                </div>
                <div className='col-span-2'>
                  <span className='font-bold text-sm mb-1'>Address line 2 (optional)</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>Email</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>Office phone number</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.address1}
                  </span>
                </div>
                <div className='col-span-2'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.address2}
                  </span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.email}
                  </span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.officeNumber}
                  </span>
                </div>
              </div>
              <div className='w-full pb-1 grid grid-cols-5 mt-10'>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>City</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>State</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-bold text-sm mb-1'>ZIP code</span>
                </div>
                <div className='col-span-2'>
                  <span className='font-bold text-sm mb-1'>Alternate contact method</span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.city}
                  </span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.state}
                  </span>
                </div>
                <div className='col-span-1'>
                  <span className='font-normaltext-sm mb-1'>
                    {officeClosureForm.content?.attorneyInformation?.zipCode}
                  </span>
                </div>
                <div className='col-span-2'>
                  <TextInput
                    value={officeClosureForm.content?.attorneyInformation?.alternateContactMethod}
                    onChange={(e) => {
                      handleAlternateContactMethod(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=' rounded-lg bg-white py-8 px-8 mt-5'>
              <div className='w-full pb-2'>
                <h3>Reason for closure</h3>
              </div>
              <div className='w-full pb-1 grid grid-cols-5 mt-5'>
                <div className='col-span-3'>
                  <div className='grid grid-cols-5'>
                    <Checkbox
                      text={'Death'}
                      defaultChecked={officeClosureForm.content?.reasonForClosure?.isDead}
                      fontWeight='font-bold'
                      onChange={hanldeReasonIsDead}
                    />
                    <Checkbox
                      text={'Incapacity'}
                      defaultChecked={officeClosureForm.content?.reasonForClosure?.isIncapacity}
                      fontWeight='font-bold'
                      onChange={hanldeReasonIsIncapacity}
                    />
                    <Checkbox
                      text={'Retirement'}
                      defaultChecked={officeClosureForm.content?.reasonForClosure?.isRetirement}
                      fontWeight='font-bold'
                      onChange={hanldeReasonIsRetirement}
                    />
                    <Checkbox
                      text={'Discipline'}
                      defaultChecked={officeClosureForm.content?.reasonForClosure?.isDiscipline}
                      fontWeight='font-bold'
                      onChange={hanldeReasonIsDiscipline}
                    />
                    <Checkbox
                      text={'Personal'}
                      defaultChecked={officeClosureForm.content?.reasonForClosure?.isPersonal}
                      fontWeight='font-bold'
                      onChange={hanldeReasonIsPersonal}
                    />
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='text-grey-100 font-extrabold'>Other reason</span>
                </div>
                <div className='col-span-3'></div>
                <div className='col-span-2'>
                  <TextArea
                    value={officeClosureForm.content?.reasonForClosure?.otherReason}
                    onChange={(e) => hanldeReasonOtherReason(e.target.value)}
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='relative grow bg-white px-6'>
          <div className='rounded-lg  py-8 px-8'>
            <div className=' rounded-lg bg-white py-8 px-8 border border-outlineLightBlue'>
              <div className='w-full pb-2'>
                <h3>PLF Information</h3>
              </div>
              <div className='w-full pb-1 grid grid-cols-4 mt-5 gap-x-4'>
                <div>
                  <span className='font-bold text-sm mb-1'>PMA</span>
                </div>
                <div>
                  <span className='font-bold text-sm mb-1'>Claims attorney</span>
                </div>
                <div>
                  <span className='font-bold text-sm mb-1'>OAAP counselor</span>
                </div>
                <div></div>
                <div>
                  <Select
                    label='Assign to'
                    default={{
                      key: officeClosureForm.content?.plfInformation?.pmaId ?? '',
                      value: officeClosureForm.content?.plfInformation?.pma ?? '',
                    }}
                    data={users?.map(u => {
                      return { key: u.userId?.toString() ?? '', value: u.firstName ?? '' + u.lastName ?? '' }
                    })}
                    onChange={handlePLFAssignTo}
                    selectedValueFontWeight='font-light'
                  />
                </div>
                <div>
                  <TextInput
                    value={officeClosureForm.content?.plfInformation?.claimsAttorney}
                    onChange={(e) => hanldePLFClaimsAttorney(e.target.value)}
                  />
                </div>
                <div>
                  <TextInput
                    value={officeClosureForm.content?.plfInformation?.oaapCounselor}
                    onChange={(e) => hanldePLFOAAPCounselor(e.target.value)}
                  />
                </div>
                <div></div>
              </div>
              <div className='w-full pb-1 grid grid-cols-4 mt-5 gap-x-4'>
                <div>
                  <span className='font-bold text-sm mb-1'>Defense panel member</span>
                </div>
                <div>
                  <span className='font-bold text-sm mb-1'>Defense panel member phone</span>
                </div>
                <div>
                  <span className='font-bold text-sm mb-1'>Defense panel member email</span>
                </div>
                <div></div>

                <div>
                  <TextInput
                    value={officeClosureForm.content?.plfInformation?.defensePanelMember}
                    onChange={(e) => hanldePLFDefensePanelMember(e.target.value)}
                  />
                </div>
                <div>
                  <TextInput
                    value={officeClosureForm.content?.plfInformation?.phoneNumber}
                    onChange={(e) => hanldePLFDefensePanelMemberPhoneNumber(e.target.value)}
                  />
                </div>
                <div>
                  <TextInput
                    value={officeClosureForm.content?.plfInformation?.email}
                    onChange={(e) => hanldePLFDefensePanelMemberEmail(e.target.value)}
                  />
                </div>
                <div></div>
              </div>
            </div>
            <div className=' rounded-lg bg-white py-8 px-8 mt-5 border border-outlineLightBlue'>
              <div className='w-full pb-2'>
                <h3>Lawyer alternative contact</h3>
              </div>
              {officeClosureForm.content?.lawyerAlternativeContactList?.map((item) => (
                <AlternativeContact
                  item={item}
                  onItemChange={(updatedItem) => handleAlternativeContact(updatedItem)}
                />
              ))}
              <div className='w-full pb-2 mt-5'>
                <Link
                  className='text-primary-100  font-bold'
                  text='Add new alternative contact'
                  iconleft={PlusIcon}
                  onClick={() => handleAlternativeContact()}
                />
              </div>
            </div>
            <div className=' rounded-lg bg-white py-8 px-8 mt-5 border border-outlineLightBlue'>
              <div className='w-full pb-2'>
                <h3>Notes</h3>
              </div>
              <div className='w-full pb-1 mt-5'>
                <TextArea
                  value={officeClosureForm.content?.notes}
                  onChange={(e) => hanldeNotes(e.target.value)}
                  rows={6}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='relative grow bg-white px-6'>
          <div className='rounded-lg  bg-bgLightBlue py-8 px-8 grid grid-cols-2 gap-x-5'>
            <div className='rounded-lg bg-white py-8 px-8'>
              <div className='w-full pb-2'>
                <h3>Active files information</h3>
              </div>
              <div className='w-full pb-1 mt-5'>
                <div>
                  <span className='font-bold text-sm mb-1'>Comment</span>
                </div>
                <div>
                  <TextArea
                    value={officeClosureForm.content?.filesInformation?.activeFileComment}
                    onChange={(e) => hanldePLFDefensePanelActiveFileComment(e.target.value)}
                    rows={8}
                  />
                </div>
              </div>
              <div className='w-full pb-2 py-8'>
                <h3>Closed files information</h3>
              </div>
              <div className='grid grid-cols-2 gap-x-4 py-4'>
                <span className='font-bold text-sm mb-1'>Stored with</span>
                <span className='font-bold text-sm mb-1'>Phone</span>
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileStoredWith}
                  onChange={(e) => hanldePLFDefensePanelClosedFileStoredWith(e.target.value)}
                />
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFilePhoneNumber}
                  onChange={(e) => hanldePLFDefensePanelClosedFilePhoneNumber(e.target.value)}
                />
              </div>
              <div className='w-full pb-2 py-4'>
                <span className='font-bold text-sm mb-1'>Email</span>
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileEmail}
                  onChange={(e) => hanldePLFDefensePanelClosedFileEmail(e.target.value)}
                />
              </div>
              <div className='grid grid-cols-2 gap-x-4 py-4'>
                <span className='font-bold text-sm mb-1'>Address</span>
                <span className='font-bold text-sm mb-1'>Address line 2</span>
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileAddress1}
                  onChange={(e) => hanldePLFDefensePanelClosedFileAddress1(e.target.value)}
                />
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileAddress2}
                  onChange={(e) => hanldePLFDefensePanelClosedFileAddress2(e.target.value)}
                />
              </div>
              <div className='grid grid-cols-3 gap-x-4 py-4'>
                <span className='font-bold text-sm mb-1'>City</span>
                <span className='font-bold text-sm mb-1'>State</span>
                <span className='font-bold text-sm mb-1'>ZIP Code</span>
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileCity}
                  onChange={(e) => hanldePLFDefensePanelClosedFileCity(e.target.value)}
                />
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileState}
                  onChange={(e) => hanldePLFDefensePanelClosedFileState(e.target.value)}
                />
                <TextInput
                  value={officeClosureForm.content?.filesInformation?.closedFileZipCode}
                  onChange={(e) => hanldePLFDefensePanelClosedFileZipCode(e.target.value)}
                />
              </div>
            </div>
            <div className=' rounded-lg bg-white py-8 px-8'>
              <div className='w-full pb-2'>
                <h3>Checklist</h3>
              </div>
              <div className='w-full pb-1 mt-5'>
                <div>
                  {officeClosureForm.content?.checklist?.filter(item => item.isAssigned).map((item, index) => (
                    <Checkbox
                      text={item.taskName ?? ''}
                      disabled={true}
                      defaultChecked={item.isChecked}
                      fontWeight='font-bold'
                      onChange={(e) => {}}
                    />
                  ))}
                </div>
              </div>
              <div className='w-full pb-2 mt-5'>
                <h3>Not assigned</h3>
              </div>
              <div className='w-full pb-1 mt-5'>
                <div>
                  {officeClosureForm.content?.checklist?.filter(item => !item.isAssigned).map((item, index) => (
                    <Checkbox
                      text={item.taskName ?? ''}
                      disabled={true}
                      grayOut={true}
                      defaultChecked={item.isChecked}
                      fontWeight='font-bold'
                      onChange={(e) => {}}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div></div>
            <div className='py-4 grid grid-cols-5 place-items-end'>
              <div className='col-span-3'></div>
              <div className='col-span-2'>
                <DownloadFormButton officeClosureForm={officeClosureForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
