import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ForgotPasswordRequest, LoginRequest, LoginResponse, ResetPasswordRequest, User } from 'generated/iTypes'
import { isAxiosError, throwException } from 'helpers/gatewayHelpers'
import { getUserToken } from 'helpers/userHelpers'

export async function loginRequest(body: LoginRequest): Promise<LoginResponse | undefined> {
  let url_ = '/api/User/login'
  url_ = url_.replace(/[?&]$/, '')

  const content_ = JSON.stringify(body)

  let options_: AxiosRequestConfig = {
    data: content_,
    method: 'POST',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain',
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<LoginResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      return Promise.reject(response)
    }
  })
}

export async function forgotPassword(body: ForgotPasswordRequest): Promise<any> {
  let url_ = '/api/User/forgotPassword'
  url_ = url_.replace(/[?&]$/, '')

  const content_ = JSON.stringify(body)

  let options_: AxiosRequestConfig = {
    data: content_,
    method: 'POST',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain',
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<any>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      return Promise.reject(response)
    }
  })
}

export async function resetPassword(body: ResetPasswordRequest): Promise<any> {
  let url_ = '/api/User/resetPassword'
  url_ = url_.replace(/[?&]$/, '')

  const content_ = JSON.stringify(body)

  let options_: AxiosRequestConfig = {
    data: content_,
    method: 'POST',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain',
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<any>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      return Promise.reject(response)
    }
  })
}

export async function validateResetPasswordToken(token: string): Promise<boolean> {
  let url_ = '/api/User/validateResetPasswordToken?token=' + token
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    data: '',
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }
  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<boolean>(true)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function checkIfTokenIsValid(): Promise<User> {
  let url_ = '/api/User/me'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    data: '',
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }
  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<User>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function refreshToken(body: LoginResponse): Promise<LoginResponse | undefined> {
  let url_ = '/api/User/refreshToken'
  url_ = url_.replace(/[?&]$/, '')

  const content_ = JSON.stringify(body)

  let options_: AxiosRequestConfig = {
    data: content_,
    method: 'POST',
    url: url_,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain',
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<LoginResponse>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      return Promise.reject(response)
    }
  })
}

export async function getUserById(id: string): Promise<User> {
  let url_ = '/api/User/getById?'
  if (id === null) throw new Error("The parameter 'id' cannot be null.")
  else if (id !== undefined) url_ += 'userId=' + encodeURIComponent('' + id) + '&'
  url_ = url_.replace(/[?&]$/, '')

  let options_: AxiosRequestConfig = {
    method: 'GET',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<User>(response)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}

export async function getAllUsers(): Promise<User[]> {
  let url_ = 'api/User/getAll'

  let options_: AxiosRequestConfig = {
    method: 'get',
    url: url_,
    headers: {
      'Authorization': 'Bearer ' + getUserToken().accessToken,
    },
  }

  return axios.create().request(options_).catch((_error: any) => {
    if (isAxiosError(_error) && _error.response) {
      return _error.response
    } else {
      throw _error
    }
  }).then((_response: AxiosResponse) => {
    const response = _response.data
    if (_response.status === 200) {
      return Promise.resolve<User[]>(response.items)
    } else if (_response.status !== 200 && _response.status !== 204) {
      const _responseText = response.data
      return throwException('An unexpected server error occurred.', _response.status, _responseText, _response.headers)
    }
  })
}
