import page from 'page'
import { Context } from './index'

export enum RouteNames {
  NewInteraction = 'LogInteraction',
  CreateTask = 'CreateTask',
  Emails = 'Emails',
  Reports = 'Reports',
  Tasks = 'Tasks',
}

export function initializeRouter(context: Context): void {
  page.base('')

  page('/', () => {
    context.actions.displayDashboardAction()
  })

  page('/login', () => {
    context.actions.displayLoginAction()
  })

  page('/forgotPassword', () => {
    context.actions.displayForgotPasswordAction()
  })

  page('/resetPassword', ({ params, querystring }) => {
    const qs = new URLSearchParams(querystring)
    context.actions.displayResetPasswordAction(qs.get('token') ?? '')
  })

  page('/emails', () => {
    context.actions.displayEmailsDashboardAction()
  })

  page('/reports', () => {
    context.actions.displayReportsDashboardAction()
  })

  page('/tasks', () => {
    context.actions.loadLookUpsAction()
    context.actions.displayTasksDashboardAction()
  })

  page('/tasksTemplates', () => {
    context.actions.displayTasksTemplatesDashboardAction()
  })

  page('*', () => {
    context.actions.displayNotFoundAction()
  })

  page.start()
}

export function redirect(url: string): void {
  page.redirect(url)
}
