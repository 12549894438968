import { LogoutIcon } from '@heroicons/react/outline'
import { Dropdown } from 'components/atoms/Dropdown/Dropdown'
import { LibNavOption } from 'entities/types/NavOption'
import { useActions, useAppState } from 'presenter'
import React, { useState } from 'react'
import plfLogo from '../../../assets/logos/plf_sm_logo 1.svg'
import { SearchInput } from '../SearchInput/SearchInput'

type TopbarProps = {
  userName: string
  children: React.ReactNode
  PlatformLogo?: React.ReactNode
  navOptions: LibNavOption[]
  hideSearchInput?: boolean
  hideDropDown?: boolean
}

export const Topbar = (props: TopbarProps): React.ReactElement => {
  const { contacts, loadingContacts, resultCount } = useAppState()
  const { redirectAction, logoutUser, displayLawyerProfileAction, updateContacts, showMoreContacts } = useActions()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showMoreCount, setShowMoreCount] = useState<number>(resultCount)
  const showMore = contacts ? showMoreCount < (contacts[0]?.totalCount ?? 0) : false
  const [fetchNewData, setFetchNewData] = useState<boolean>(false)

  const onSearchInputChange = (value: string) => {
    if (value.length > 1) {
      setShowMoreCount(resultCount)
      setSearchTerm(value)
      updateContacts(value)
    }
  }

  const onShowMore = async () => {
    setFetchNewData(true)
    setShowMoreCount((prevCount) => prevCount + 25)
    await showMoreContacts({ term: searchTerm, resultCount: showMoreCount + 25 })
    setFetchNewData(false)
  }

  return (
    <>
      {/* Navbar for mobile */}
      <nav className='md:hidden flex flex-row h-14 grow p-3 items-center justify-between'>
        <div className='flex items-center flex-row'>
          <div className='shrink-0' onClick={() => redirectAction('/')}>
            <img
              className='mx-auto cursor-pointer'
              src={plfLogo}
              alt='plf logo'
            />
          </div>
          {props.navOptions.map((item, index) => (
            <div
              key={index}
              className={`h-full w-full cursor-pointer 
              ${item.current && 'border-b-4 border-primary-200 text-primary-100'}`}
              onClick={() => redirectAction(item.href)}
            >
              <div key={index} className='px-10'>
                {item.name}
              </div>
            </div>
          ))}
          {!props.hideDropDown
            && (
              <div className='px-10'>
                <Dropdown
                  data={[{
                    icon: () => {
                      return <LogoutIcon className='mr-2 h-5 w-5' aria-hidden='true' />
                    },
                    name: 'Logout',
                    onClick: () => logoutUser(),
                  }]}
                />
              </div>
            )}
        </div>
      </nav>
      <nav className='hidden md:top-0 md:z-50 md:px-4 md:flex md:fixed md:w-full md:h-20 md:bg-white md:border-b md:justify-between'>
        <ul className='flex items-center'>
          <li className='shrink-0' onClick={() => redirectAction('/')}>
            <img
              className='mx-auto cursor-pointer'
              src={plfLogo}
              alt='plf logo'
            />
          </li>
          {!props.hideSearchInput
            && (
              <li className='flex pl-16'>
                <div className='flex flex-row grow relative w-auto w-96'>
                  <SearchInput
                    placeholder='Search lawyer name or bar number'
                    hideInputValue
                    onInputChange={(value) => onSearchInputChange(value)}
                    data={contacts}
                    onChange={(value) => {
                      displayLawyerProfileAction(Number(value.itemId))
                    }}
                    fetchingData={loadingContacts}
                    fetchingNewData={fetchNewData}
                    showMore={showMore}
                    onShowMore={onShowMore}
                  />
                </div>
              </li>
            )}
        </ul>
        <ul className='flex items-center px-4 font-semibold'>
          {props.navOptions.map((item, index) => (
            <div
              key={index}
              className={`h-full w-full cursor-pointer 
              ${item.current && 'border-b-4 border-primary-200 text-primary-100'}`}
              onClick={() => redirectAction(item.href)}
            >
              <li key={index} className='px-10 pt-7'>
                {item.name}
              </li>
            </div>
          ))}
          {!props.hideDropDown
            && (
              <li className='border-l border-outlineLightBlue px-10'>
                <Dropdown
                  label={props.userName}
                  data={[{
                    icon: () => {
                      return <LogoutIcon className='mr-2 h-5 w-5' aria-hidden='true' />
                    },
                    name: 'Logout',
                    onClick: () => logoutUser(),
                  }]}
                />
              </li>
            )}
        </ul>
      </nav>
      <div className='top-5'>
        {props.children}
      </div>
    </>
  )
}
