import { ListType } from 'generated/iTypes'

export const capitalizeFirstLetter = (value: string): string => {
  if (value.length > 0) {
    return value[0].toUpperCase() + value.slice(1)
  }
  return value.toUpperCase()
}

export const timeSince = (dateParam: string): string => {
  let dateString = dateParam
  if (!dateString.endsWith('Z')) {
    dateString += 'Z' // convert the input string to UTC
  }
  let today = new Date().toISOString()

  let seconds = Math.floor((Date.parse(today) - Date.parse(dateString)) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years ago'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months ago'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days ago'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes ago'
  }
  return Math.floor(seconds) + ' seconds ago'
}

export const getListTypeName = (list: ListType): string => {
  switch (list) {
    case ListType.Address:
      return 'Mailing List'
    case ListType.Email:
      return 'Email List'
  }
}

export const getYearsList = () => {
  var currentYear = new Date().getFullYear(), years = []
  let startYear = 1980
  while (startYear <= currentYear) {
    years.push(startYear++)
  }
  return years.reverse()
}

export const getDate = (date?: Date) => {
  return date != null ? new Date(date) : new Date()
}
