import { PlusIcon } from '@heroicons/react/outline'
import { Link } from 'components/atoms/Link/Link'
import { SubTaskDetail } from 'generated/iTypes'
import { formatDate, getMappedAvatarColor, getMappedStatus } from 'helpers/tasksHelper'
import { useActions } from 'presenter'
import React from 'react'
import { TaskCard } from '../TaskCard/TaskCard'

type TaskSubTasksProps = {
  subTasks?: SubTaskDetail[]
  onChange?: (subTasks: SubTaskDetail[]) => void
  onShowEditor?: (mode: 'Create' | 'View', index?: number) => void
}
export const TaskSubTasks = (props: TaskSubTasksProps): React.ReactElement => {
  const { loadSubTaskDetailsAction, setIsCreateNewTaskFormDirty } = useActions()

  const handleCardClick = async (subTask: SubTaskDetail | undefined, index: number) => {
    await loadSubTaskDetailsAction({ taskId: subTask?.id, subTask: subTask ?? {} })
    if (props.onShowEditor) {
      props.onShowEditor('View', index)
    }
  }

  const handleAddNewSubTask = async () => {
    await loadSubTaskDetailsAction({ taskId: undefined, subTask: {} })
    if (props.onShowEditor) {
      props.onShowEditor('Create')
    }
    setIsCreateNewTaskFormDirty(true)
  }

  return (
    <div className=''>
      <Link
        text={'Add a subtask'}
        iconleft={PlusIcon}
        onClick={handleAddNewSubTask}
      />
      <div className='pb-8 mt-2 overflow-x-hidden'>
        {props.subTasks?.map((st, index) => (
          <div className='mt-2' key={index}>
            <TaskCard
              key={`dashboardTaskCard-${index}`}
              initials={`${st.assignedTo?.firstName.slice(0, 1)}${st.assignedTo?.lastName.slice(0, 1)}`}
              color={getMappedAvatarColor(st.assignedTo?.firstName.slice(0, 1) ?? '')}
              date={formatDate(st.dueDate?.toString() ?? '', 'MM/DD/YY')}
              tags={st.tags}
              chatCount={st.comments?.length ?? 0}
              size='md'
              title={st.name}
              pillStatus={(getMappedStatus(st.taskStatus?.code ?? '')).status}
              pillText={(getMappedStatus(st.taskStatus?.code ?? '')).text}
              onClick={() => handleCardClick(st, index)}
            >
              {st.taskDetails}
            </TaskCard>
          </div>
        ))}
      </div>
    </div>
  )
}
