import { InformationCircleIcon } from '@heroicons/react/outline'
import { Link } from 'components/atoms/Link/Link'
import TextArea from 'components/atoms/TextArea/TextArea'
import { ComboBoxItem } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import React, { useState } from 'react'
import { SearchInput } from '../SearchInput/SearchInput'

type TaskDescriptionProps = {
  mode: 'View' | 'Create'
  contact?: ComboBoxItem
  taskDetails?: string
  onContactChange?: (contact: ComboBoxItem) => void
  onTaskDetailsChange?: (taskDetails: string) => void
  taskDetailsValid?: boolean
  taskDetailsErrorMessage?: string
  onSave?: () => void
}
export const TaskDescription = (props: TaskDescriptionProps): React.ReactElement => {
  const { contacts, resultCount, loadingContacts } = useAppState()
  const [selectedContact, setSelectedContact] = useState<ComboBoxItem>(props.contact ?? {})
  const [taskDetails, setTaskDetails] = useState<string>(props.taskDetails ?? '')
  const {
    displayModalAction,
    displayLawyerProfileAction,
    setIsCreateNewTaskFormDirty,
    updateContacts,
    showMoreContacts,
  } = useActions()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showMoreCount, setShowMoreCount] = useState<number>(resultCount)
  const showMore = contacts ? showMoreCount < (contacts[0]?.totalCount ?? 0) : false
  const [fetchNewData, setFetchNewData] = useState<boolean>(false)

  const handleSelectContact = (contact: ComboBoxItem) => {
    setSelectedContact(contact)
    if (props.onContactChange) {
      props.onContactChange(contact)
    }
    setIsCreateNewTaskFormDirty(true)
  }

  const handleTaskDetailsChange = (taskDetails: string) => {
    setTaskDetails(taskDetails)
    if (props.onTaskDetailsChange) {
      props.onTaskDetailsChange(taskDetails)
    }
    setIsCreateNewTaskFormDirty(true)
  }

  const handleSave = () => {
    if (props.onSave) {
      props.onSave()
    }
  }

  const contactOnClick = (contactId: number) => {
    displayLawyerProfileAction(contactId)
    displayModalAction('None')
  }

  const onSearchInputChange = (value: string) => {
    if (value.length > 1) {
      setShowMoreCount(resultCount)
      setSearchTerm(value)
      updateContacts(value)
    }
  }

  const onShowMore = async () => {
    setFetchNewData(true)
    setShowMoreCount((prevCount) => prevCount + 25)
    await showMoreContacts({ term: searchTerm, resultCount: showMoreCount + 25 })
    setFetchNewData(false)
  }

  return (
    <div className=''>
      <span className='font-bold font-bold'>Lawyer associated with task</span>
      <span className='text-secondary-200 font-bold'>&nbsp;(optional)</span>
      <div className='mt-2'>
        {(props.mode === 'View' && selectedContact.itemId)
          ? (
            <Link
              text={selectedContact.itemText}
              underline
              onClick={() => {
                contactOnClick(Number(selectedContact.itemId))
              }}
            />
          )
          : (
            <SearchInput
              value={selectedContact ?? {}}
              data={contacts}
              onChange={handleSelectContact}
              onInputChange={(value) => onSearchInputChange(value)}
              fetchingData={loadingContacts}
              fetchingNewData={fetchNewData}
              showMore={showMore}
              onShowMore={onShowMore}
            />
          )}
      </div>
      <div className='mt-5'>
        <TextArea
          value={taskDetails}
          placeholder='Description'
          rows={4}
          onChange={e => handleTaskDetailsChange(e.target.value)}
          onKeyDown={(e) => {
            ;(taskDetails && e.ctrlKey && e.key === 'Enter') && handleSave()
          }}
        />
        {!props.taskDetailsValid && props.taskDetailsErrorMessage !== ''
          ? (
            <div>
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                <InformationCircleIcon className='h-4 w-4 mr-1' />
                {props.taskDetailsErrorMessage}
              </span>
            </div>
          )
          : null}
      </div>
    </div>
  )
}
