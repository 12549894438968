import { ArrowRightIcon } from '@heroicons/react/outline'
import { Accordion } from 'components/atoms/Accordion/Accordion'
import { Link } from 'components/atoms/Link/Link'
import { TaskFilter } from 'components/molecules/PopFilters/TaskFilter'
import { UserFilter } from 'components/molecules/PopFilters/UserFilter'
import { TablePaginationComponent } from 'components/molecules/Table/TablePagination'
import { TaskCard } from 'components/molecules/TaskCard/TaskCard'
import { formatDate, getMappedAvatarColor, getMappedStatus } from 'helpers/tasksHelper'
import { useActions, useAppState } from 'presenter'
import { useEffect, useState } from 'react'
import { CreateNewTaskButton } from './CreateNewTask'

export const TasksDashboard = () => {
  const {
    allTaskSummary,
    users,
    loadingTaskSummary,
    tasksFilters,
    tasksPastDuePaged,
    authenticatedUser,
    maxAmountItemsPerPage,
    pagedTasksArray,
  } = useAppState()
  const {
    setTaskSummaryFilters,
    setUsersFilters,
    displayTaskDetailsModal,
    setTaskPastDuePage,
    redirectAction,
    fetchTasksPaginationAction,
  } = useActions()
  const [loadingTasks, setLoadingTasks] = useState<boolean[]>([])
  const [loadingPastDueTasks, setLoadingPastDueTasks] = useState<boolean>(false)

  useEffect(() => {
    setLoadingTasks(allTaskSummary.map(() => false))
    if (allTaskSummary[0]) fetchTasksPagination(0, 1, maxAmountItemsPerPage, allTaskSummary[0].dueDate ?? new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTaskSummary])

  const fetchTasksPagination = async (index: number, pageNumber: number, pageSize: number, date: Date) => {
    updateLoadingState(index, true)
    await fetchTasksPaginationAction({ index: index, pageNumber: pageNumber, pageSize: pageSize, date: date })
    updateLoadingState(index, false)
  }

  const updateLoadingState = (index: number, value: boolean) => {
    setLoadingTasks(prevState => {
      const newState = prevState.map((item, idx) => {
        if (idx === index) return item = value
        return item
      })
      return newState
    })
  }

  const NoResults = () => {
    return (
      <div className='flex flex-col items-center justify-center'>
        <div className='flex gap-2'>
          <span className='h-4 w-4 rounded-full bg-primary-100'></span>
          <span className='h-4 w-4 rounded-full bg-primary-200'></span>
          <span className='h-4 w-4 rounded-full bg-primary-100'></span>
        </div>
        <p className='font-semibold text-textGrey w-1/2 text-center mt-6'>
          No Tasks
        </p>
      </div>
    )
  }

  return (
    <div className='-ml-8 -mr-8 text-primary-300'>
      <div className='h-[calc(100vh-80px)] overflow-auto flex-wrap bg-bgLightBlue'>
        <div className='border-b bg-white'>
          <div className='relative px-12 py-8'>
            <h1>Tasks</h1>
            <div className='flex justify-between'>
              <div className='flex pt-6 gap-4'>
                <UserFilter data={users} onChange={setUsersFilters} default={[authenticatedUser]} />
                <TaskFilter filters={tasksFilters} onSummaryFilter={setTaskSummaryFilters} />
              </div>
              <div className='mt-8'>
                <Link
                  className='font-extrabold'
                  text='View task templates'
                  onClick={() => {
                    redirectAction('/tasksTemplates')
                  }}
                  secondary
                  iconright={ArrowRightIcon}
                />
              </div>
            </div>
            <CreateNewTaskButton />
          </div>
        </div>
        <div className='relative flex flex-row'>
          <div className='flex flex-col grow pt-4'>
            <div className='px-12 pt-4'>
              {(tasksPastDuePaged.items && tasksPastDuePaged.items.length > 0)
                && (
                  <div className='mb-6'>
                    <h3 className='mb-6 text-left'>Past due tasks</h3>
                    <div className='bg-white rounded-lg'>
                      <Accordion
                        loading={loadingPastDueTasks}
                        title={
                          <div className='flex gap-2'>
                            <span className='text-lg'>
                              Past due
                            </span>
                            <div className='ml-1 mt-0.5 h-6 w-6 rounded-full text-sm font-extrabold h-full grid place-items-center border-2 text-[#EB7100] border-[#EB7100]'>
                              {tasksPastDuePaged.totalItems}
                            </div>
                          </div>
                        }
                      >
                        <div className='pb-2 px-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-9 5xl:grid-cols-13 gap-y-5'>
                          {tasksPastDuePaged.items.map((task, index) => {
                            const mappedStatus = getMappedStatus(task.taskStatus?.code ?? '')
                            const mappedColor = getMappedAvatarColor(task.assignedTo?.firstName.slice(0, 1) ?? '')
                            return (
                              <div key={`dashboardTaskCardDiv-${index}`} className='cursor-pointer'>
                                <TaskCard
                                  onClick={() => displayTaskDetailsModal(task)}
                                  key={`dashboardTaskCard-${index}`}
                                  initials={`${task.assignedTo?.firstName.slice(0, 1)}${
                                    task.assignedTo?.lastName.slice(0, 1)
                                  }`}
                                  color={mappedColor}
                                  date={task.dueDate
                                    ? new Date(task.dueDate).toLocaleDateString(undefined, {
                                      day: 'numeric',
                                      month: 'numeric',
                                    })
                                    : ''}
                                  tags={task.tags}
                                  title={task.name}
                                  pillStatus={mappedStatus?.status}
                                  isPastDue={task.taskStatus?.isPastDue}
                                  pillText={mappedStatus?.text}
                                  subTasksCount={task.totalSubTasks}
                                  chatCount={task.totalComments}
                                  isSubTask={task.parentId !== undefined && task.parentId !== null}
                                >
                                  {task.taskDetails}
                                </TaskCard>
                              </div>
                            )
                          })}
                        </div>
                        <footer>
                          <TablePaginationComponent
                            rowsPerPage={maxAmountItemsPerPage}
                            rowCount={tasksPastDuePaged.totalItems ?? 0}
                            currentPage={tasksPastDuePaged.pageNumber ?? 0}
                            onChangePage={async (pageNumber) => {
                              setLoadingPastDueTasks(true)
                              await setTaskPastDuePage({
                                pageNumber: pageNumber,
                                pageSize: maxAmountItemsPerPage,
                                orderBy: 'dueDate',
                                orderDirection: 'DESC',
                                assignedToIds: tasksFilters.assignedToIds,
                                taskTypes: tasksFilters.taskTypes?.filter(t => t.checked).map(t => t.taskType).join(
                                  ',',
                                ),
                                taskStatus: tasksFilters.taskStatus?.filter(t => t.checked).map(t =>
                                  t.taskStatus.itemId
                                ).join(','),
                                tags: tasksFilters.tags?.filter(t => t.checked).map(t => t.tag.id).join(','),
                                noTags: tasksFilters.noTags,
                              })
                              setLoadingPastDueTasks(false)
                            }}
                            onChangeRowsPerPage={() => {}}
                          />
                        </footer>
                      </Accordion>
                    </div>
                  </div>
                )}
              <h3 className='mb-6 text-left'>Tasks</h3>
              {(allTaskSummary.length < 1 && !loadingTaskSummary) && <NoResults />}
              {loadingTaskSummary
                ? <div>Loading...</div>
                : (
                  <div className='grid gap-5 mb-6'>
                    {allTaskSummary.map((item, index) => (
                      <div key={`tasks-${item.dueDate}`} className='bg-white rounded-lg'>
                        <Accordion
                          onClick={() =>
                            fetchTasksPagination(index, 1, maxAmountItemsPerPage, item.dueDate ?? new Date())}
                          loading={loadingTasks[index]}
                          defaultOpen={index === 0}
                          title={
                            <div className='flex gap-2'>
                              <span className='text-lg'>
                                {item.dueDate && formatDate(item.dueDate.toString(), 'MM/DD/YY')}
                              </span>
                              <div className='ml-1 mt-0.5 h-6 w-6 rounded-full text-sm font-extrabold h-full grid place-items-center border-2 text-[#EB7100] border-[#EB7100]'>
                                {item.total}
                              </div>
                            </div>
                          }
                        >
                          <div className='pb-2 px-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-9 5xl:grid-cols-13 gap-y-5'>
                            {pagedTasksArray[index]?.items?.map((task, index) => {
                              const mappedStatus = getMappedStatus(task.taskStatus?.code ?? '')
                              const mappedColor = getMappedAvatarColor(task.assignedTo?.firstName.slice(0, 1) ?? '')
                              return (
                                <div key={`dashboardTaskCardDiv-${index}`} className='cursor-pointer'>
                                  <TaskCard
                                    onClick={() => displayTaskDetailsModal(task)}
                                    key={`dashboardTaskCard-${index}`}
                                    initials={`${task.assignedTo?.firstName.slice(0, 1)}${
                                      task.assignedTo?.lastName.slice(0, 1)
                                    }`}
                                    color={mappedColor}
                                    date={task.dueDate
                                      ? new Date(task.dueDate).toLocaleDateString(undefined, {
                                        day: 'numeric',
                                        month: 'numeric',
                                      })
                                      : ''}
                                    tags={task.tags}
                                    title={task.name}
                                    pillStatus={mappedStatus?.status}
                                    isPastDue={task.taskStatus?.isPastDue}
                                    pillText={mappedStatus?.text}
                                    subTasksCount={task.totalSubTasks}
                                    chatCount={task.totalComments}
                                    isSubTask={task.parentId !== undefined && task.parentId !== null}
                                  >
                                    {task.taskDetails}
                                  </TaskCard>
                                </div>
                              )
                            })}
                          </div>
                          <footer>
                            <TablePaginationComponent
                              rowsPerPage={maxAmountItemsPerPage}
                              rowCount={pagedTasksArray[index]?.totalItems ?? 0}
                              currentPage={pagedTasksArray[index]?.pageNumber ?? 0}
                              onChangePage={(pageNumber) =>
                                fetchTasksPagination(
                                  index,
                                  pageNumber,
                                  pagedTasksArray[index]?.pageSize ?? 0,
                                  item.dueDate ?? new Date(),
                                )}
                              onChangeRowsPerPage={() => {}}
                            />
                          </footer>
                        </Accordion>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
