import { ArrowDownIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import { OfficeClosureForm } from 'generated/iTypes'
import { useActions } from 'presenter'
import { useState } from 'react'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

type DownloadFormButtonProps = {
  officeClosureForm: OfficeClosureForm
}

export const DownloadFormButton = (props: DownloadFormButtonProps) => {
  const { downloadContactOfficeClosureFormAction } = useActions()
  const [toggle, setToggle] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<boolean>(false)

  const handledownloadContactOfficeClosureForm = async (isDownloadForm: boolean = false) => {
    setDownloading(true)
    await downloadContactOfficeClosureFormAction({ ...props.officeClosureForm, isDownloadForm: isDownloadForm })
    setDownloading(false)
  }
  return (
    <div>
      <Button className='w-60 h-12' disabled={downloading} onClick={() => setToggle(!toggle)}>
        {!downloading
          ? (
            'Save Form'
          )
          : <SpinnerSVG className='inline w-6 h-6 text-gray-200 animate-spin fill-primary-200' />}
      </Button>
      {toggle && (
        <div className='mt-2 flex w-full font-semibold'>
          <div className='block rounded-xl shadow-xl bg-white text-left w-full'>
            <div
              className='pl-5 pt-2 pb-2 hover:bg-bgLightBlue hover:cursor-pointer'
              onClick={() => {
                handledownloadContactOfficeClosureForm()
                setToggle(false)
              }}
            >
              Save
            </div>
            <div
              className='pl-5 pt-2 pb-2 hover:bg-bgLightBlue hover:cursor-pointer'
              onClick={() => {
                handledownloadContactOfficeClosureForm(true)
                setToggle(false)
              }}
            >
              <span className='flex items-center'>
                Save and Download&nbsp;&nbsp;<ArrowDownIcon className='w-4 h-4 mr-2' />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
