import { ChevronDownIcon, PlusIcon } from '@heroicons/react/outline'
import { Button } from 'components/atoms/Button/Button'
import { useActions } from 'presenter'
import { useState } from 'react'

export const CreateNewTaskButton = () => {
  const { displayModalAction } = useActions()
  const [toggle, setToggle] = useState<boolean>(false)

  return (
    <div className='absolute top-0 right-0 mr-12 mt-12'>
      <Button onClick={() => setToggle(!toggle)} className='w-60 h-12'>
        <PlusIcon className='w-4 h-4 mr-2' />
        Create a new task
        <ChevronDownIcon className='w-4 h-4 ml-2'></ChevronDownIcon>
      </Button>
      {toggle && (
        <div className='mt-2 flex w-full font-semibold'>
          <div className='block rounded-xl shadow-xl bg-white text-left w-full'>
            <div
              className='pl-5 pt-2 pb-2 hover:bg-bgLightBlue hover:cursor-pointer'
              onClick={() => {
                setToggle(false)
                displayModalAction('TemplateSelectionModal')
              }}
            >
              From task template
            </div>
            <div
              className='pl-5 pt-2 pb-2 hover:bg-bgLightBlue hover:cursor-pointer'
              onClick={() => {
                setToggle(false)
                displayModalAction('CreateNewTaskModal')
              }}
            >
              From scratch
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
