import React, { ChangeEventHandler, KeyboardEvent, forwardRef } from 'react'

type TextAreaProps = {
  id?: string
  defaultValue?: string
  hasAutoFocus?: boolean
  value?: string
  label?: string
  name?: string
  maxLength?: number
  placeholder?: string
  readOnly?: boolean
  rows?: number
  error?: boolean
  disabled?: boolean
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onKeyUp?: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void
}

const TextArea = (
  {
    defaultValue,
    hasAutoFocus,
    label,
    onBlur,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    rows = 3,
    error = false,
    ...rest
  }: TextAreaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
): React.ReactElement => {
  return (
    <div className='relative flex flex-row'>
      <textarea
        defaultValue={defaultValue}
        autoFocus={hasAutoFocus}
        className={`resize-none appearance-none border rounded-lg w-full py-2 px-3 text-primary-300 leading-tight focus:ring-blue-500 focus:outline-none focus:shadow-outline placeholder-gray-500 ${
          error && 'border-error'
        }`}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        disabled={rest.disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        id={rest.name}
        ref={ref}
        rows={rows}
        {...rest}
      />

      {label && rest.name && <label htmlFor={rest.name}>{label}</label>}
    </div>
  )
}

export default forwardRef(TextArea)
