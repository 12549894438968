import React, { ChangeEventHandler, KeyboardEvent, forwardRef } from 'react'

type TextInputProps = {
  id?: string
  defaultValue?: string
  hasAutoFocus?: boolean
  value?: string
  type?: string
  label?: string
  name?: string
  maxLength?: number
  placeholder?: string
  readOnly?: boolean
  error?: boolean
  disabled?: boolean
  remmovePadding?: boolean
  onBlur?: ChangeEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const TextInput = (
  {
    defaultValue,
    hasAutoFocus,
    label,
    onBlur,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    type,
    error = false,
    remmovePadding = false,
    ...rest
  }: TextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  return (
    <div className='flex flex-row grow relative'>
      <input
        defaultValue={defaultValue}
        type={type ? type : 'text'}
        autoFocus={hasAutoFocus}
        className={`appearance-none border rounded-lg w-full ${
          !remmovePadding ? 'py-3 px-3' : ''
        } text-primary-300 leading-tight focus:ring-blue-500 focus:outline-none focus:shadow-outline placeholder-gray-500 ${
          error && 'border-error'
        }`}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        disabled={rest.disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        id={rest.name}
        ref={ref}
        step='any'
        {...rest}
      />

      {label && rest.name && <label htmlFor={rest.name}>{label}</label>}
    </div>
  )
}

export default forwardRef(TextInput)
