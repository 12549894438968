import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, PencilAltIcon, PlusIcon } from '@heroicons/react/outline'
import { StarIcon } from '@heroicons/react/solid'
import { Accordion } from 'components/atoms/Accordion/Accordion'
import { Button } from 'components/atoms/Button/Button'
import { Card } from 'components/atoms/Card/Card'
import { Link } from 'components/atoms/Link/Link'
import Select from 'components/atoms/Select/Select'
import { OptedInSelector } from 'components/molecules/OptedInSelector/OptedInSelector'
import { InteractionFilter } from 'components/molecules/PopFilters/InteractionFilter'
import { EmptyTable } from 'components/molecules/Table/EmptyTable'
import Table, { TableColumn } from 'components/molecules/Table/Table'
import { TablePaginationComponent } from 'components/molecules/Table/TablePagination'
import { TaskCard } from 'components/molecules/TaskCard/TaskCard'
import { InteractionPageRequestParameters } from 'entities/types/Interactions'
import { OptedInSelection } from 'entities/types/Person'
import { ComboBoxItem, ContactUpdateRequest, InteractionResponse } from 'generated/iTypes'
import { formatDate, getMappedAvatarColor, getMappedStatus } from 'helpers/tasksHelper'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { Loading } from '../Loading'

export const LawyerProfile = () => {
  const {
    taskStatus,
    selectedLawyer,
    interactionsPaged,
    selectedUpcomingTasks,
    selectedCompletedTasks,
    tasksPastDuePaged,
    officeClosures,
    authenticatedUser,
    optedInStatuses,
    maxAmountItemsPerPage,
  } = useAppState()

  const [isShowMore, setIsShowMore] = useState<boolean>()
  const [isOfficeClosureForm, setIsOfficeClosureForm] = useState<boolean>()
  const [loadingInteraction, setLoadingInteraction] = useState(false)
  const [loadingUpcommingTasks, setLoadingUpcomingTasks] = useState(false)
  const [loadingCompletedTasks, setLoadingCompletedTasks] = useState(false)
  const [loadingPastDueTasks, setLoadingPastDueTasks] = useState<boolean>(false)
  const [upcommingTaskStatuses] = useState<string>(
    taskStatus.filter(item => item.itemText !== 'Done').map(item => item.itemId).join(','),
  )
  const [completedTaskStatus] = useState<string>(
    taskStatus.filter(item => item.itemText === 'Done').map(item => item.itemId).join(','),
  )
  const [interactionsFilter, setInteractionsFilter] = useState<InteractionPageRequestParameters>({
    pageSize: 8,
    pageNumber: 1,
    contactId: selectedLawyer?.contactId,
  })
  const [customOfficeClosureStyle, setCustomOfficeClosureStyle] = useState<string>()
  const setOfficeClosureStyle = (officeClosureId?: string) => {
    let officeClosure = officeClosures.find(item => item.itemId.toString() === officeClosureId)
    let officeClosureStyle = 'rounded-xl text-grey-100 font-extrabold py-1.5'
    setIsOfficeClosureForm(false)
    switch (officeClosure?.code) {
      case 'N/A':
        officeClosureStyle = `${officeClosureStyle} bg-[#EFF7FF] text-[#9EA8C0] `
        break
      case 'IP':
        officeClosureStyle = `${officeClosureStyle} bg-[#BBE2FF] text-[#0C487E]`
        setIsOfficeClosureForm(true)
        break
      case 'C':
        officeClosureStyle = `${officeClosureStyle} bg-[#C4ECD9] text-[#217765]`
        setIsOfficeClosureForm(true)
        break
      default:
        officeClosureStyle = `${officeClosureStyle} bg-[#EFF7FF] text-[#9EA8C0]`
        break
    }
    setCustomOfficeClosureStyle(officeClosureStyle)
  }
  const [selectedLawyerOfficeClosure, setSelectedLawyerOfficeClosure] = useState<ComboBoxItem>(() => {
    let officeClosure = officeClosures.find(item => item.code === selectedLawyer?.officeClosureCode)
      ?? officeClosures.find(item => item.code === 'N/A')
    setOfficeClosureStyle(officeClosure?.itemId.toString())
    return officeClosure ?? {}
  })

  const {
    displayCreateTaskAction,
    displayNewInteractionAction,
    setInteractionsPage,
    setSelectedUpcomingTasks,
    setSelectedCompletedTasks,
    displayTaskDetailsModal,
    loadInteractionDetailsAction,
    setTaskPastDuePage,
    updateContactAction,
    displayModalAction,
    displayOfficeClosureFormAction,
  } = useActions()

  const fetchUpcommingTasksPagination = async (pageNumber: number) => {
    setLoadingUpcomingTasks(true)
    let curr = new Date()
    await setSelectedUpcomingTasks({
      fromDate: formatDate(curr.toString(), 'YYYY-MM-DD'),
      pageNumber: (pageNumber),
      pageSize: maxAmountItemsPerPage,
      contactId: selectedLawyer?.contactId,
      statusIds: upcommingTaskStatuses,
      orderDirection: 'ASC',
    })
    setLoadingUpcomingTasks(false)
  }
  const fetchCompletedTasksPagination = async (pageNumber: number) => {
    setLoadingCompletedTasks(true)
    await setSelectedCompletedTasks({
      pageNumber: pageNumber,
      pageSize: maxAmountItemsPerPage,
      contactId: selectedLawyer?.contactId,
      statusIds: completedTaskStatus,
    })
    setLoadingCompletedTasks(false)
  }
  const handlePageChange = (pageNumber: number) => {
    setInteractionsFilter({ ...interactionsFilter, pageNumber: (pageNumber) })
    fetchInteractionsPage({ ...interactionsFilter, pageNumber: (pageNumber) })
  }
  const onInteractionFiltersChange = (
    filter: { pmaContactIds?: string; methodOfContactIds?: string; areaOfAssistanceIds?: string },
  ) => {
    const appliedFilters = {
      ...interactionsFilter,
      pageNumber: 1,
      pmaContactIds: filter.pmaContactIds,
      methodOfContactIds: filter.methodOfContactIds,
      areaOfAssistanceIds: filter.areaOfAssistanceIds,
    }
    setInteractionsFilter(appliedFilters)
    fetchInteractionsPage(appliedFilters)
  }
  const onOfficeClosureCodeChange = (officeClosureId?: string) => {
    let officeClosure = officeClosures.find(item => item.itemId.toString() === officeClosureId)
    let lawyerToUpdate = {
      contactId: selectedLawyer?.contactId,
      firstName: selectedLawyer?.firstName,
      officeClosureId: Number(officeClosureId),
      userId: authenticatedUser.userId,
    } as ContactUpdateRequest
    setOfficeClosureStyle(officeClosureId)
    setSelectedLawyerOfficeClosure(officeClosure ?? {})
    updateContactAction(lawyerToUpdate)
  }
  const tableColumns: TableColumn<InteractionResponse>[] = [
    {
      cell: (interaction) => (
        <button
          key={`dashboardInteractionBtn-${interaction.interactionId}`}
          className='underline font-semibold text-primary-100'
          onClick={() => loadInteractionDetailsAction(interaction ?? 0)}
        >
          {interaction.interactionDate
            ? formatDate(interaction.interactionDate.toString() ?? '', 'MM/DD/YY')
            : ''}
        </button>
      ),
      name: 'Date',
      selector: ({ interactionDate }) => interactionDate?.getDate() ?? '',
    },
    {
      name: 'PMA contact',
      selector: ({ user }) => user?.firstName + ' ' + user?.lastName ?? '',
    },
    {
      name: 'Method of contact',
      selector: ({ methodOfContact }) => methodOfContact ?? '',
    },
    {
      name: 'Area of assistance',
      selector: ({ areaOfAssistance }) => areaOfAssistance ?? '',
    },
  ]

  const handleOptedInSelections = (selections: OptedInSelection[]) => {
    const selectionItems = selections.filter(s => s.checked).map(s => s.optedIn)
    const lawyerToUpdate = {
      contactId: selectedLawyer?.contactId,
      firstName: selectedLawyer?.firstName,
      optedInItems: selectionItems,
    } as ContactUpdateRequest
    updateContactAction(lawyerToUpdate)
  }

  const fetchInteractionsPage = async (filters: InteractionPageRequestParameters) => {
    setLoadingInteraction(true)
    await setInteractionsPage(filters)
    setLoadingInteraction(false)
  }

  const formattedBirthDate = selectedLawyer?.birthDate && new Date(selectedLawyer.birthDate).toLocaleDateString()
  const formattedAdmitDate = selectedLawyer?.admitDate && new Date(selectedLawyer.admitDate).toLocaleDateString()

  return (
    <div className='-ml-8 -mr-8 text-primary-300'>
      <div className='h-[calc(100vh-80px)] overflow-auto flex-wrap bg-bgLightBlue'>
        <div className='border-b bg-white'>
          <div className='relative px-12 py-2'>
            <div className='w-full pb-2 grid grid-cols-2'>
              <div className='font-medium font-extrabold'>
                <h1 className=''>{`${selectedLawyer?.fullName}`}</h1>
              </div>
              <div className='py-4 grid grid-cols-5 place-items-end'>
                <div className='col-span-3'></div>
                <div className='col-span-2'>
                  <Button onClick={displayNewInteractionAction}>
                    <PlusIcon className='w-4 h-4 mr-2' />
                    Add new interaction
                  </Button>
                </div>
              </div>
              <div className='py-4 flex flex-nowrap items-center'>
                <div className='w-1/3 mr-2'>
                  <OptedInSelector
                    selectedOptions={optedInStatuses.map<OptedInSelection>(o => ({
                      checked: selectedLawyer?.optedInItems?.map(i => i.itemId).includes(o.itemId) ?? false,
                      optedIn: o,
                    }))}
                    onSelectionUpdated={handleOptedInSelections}
                  />
                </div>
                <div className='w-1/2 mr-2'>
                  <Select
                    hideBorder
                    disabled={!selectedLawyer?.officeClosureTaskId}
                    customStyle={customOfficeClosureStyle}
                    selectedValueFontWeight='font-normal'
                    label=''
                    default={{
                      key: selectedLawyerOfficeClosure.itemId.toString() ?? '',
                      value: selectedLawyerOfficeClosure.itemText ?? '',
                    }}
                    data={officeClosures?.map(item => {
                      return {
                        key: item.itemId?.toString() ?? '',
                        value: `Office closure status: ${item.itemText}` ?? '',
                      }
                    })}
                    onChange={(e) => {
                      onOfficeClosureCodeChange(e.key)
                    }}
                  />
                </div>
              </div>
              <div className='py-4 grid grid-cols-3 place-items-end'>
                <div className='col-span-2'></div>
                {isOfficeClosureForm
                  ? (
                    <div className='col-span-1'>
                      <Link
                        text=' View office closure form'
                        iconright={ArrowRightIcon}
                        secondary
                        onClick={() => {
                          displayOfficeClosureFormAction()
                        }}
                      />
                    </div>
                  )
                  : <div className='col-span-1'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='border-b bg-white'>
          <div className='relative'>
            <div className='px-12 py-6 grid grid-cols-6 gap-11'>
              <div className='col-span-2'>
                <div className='mb-6 flex justify-between'>
                  <h3>Profile</h3>
                  <Button
                    color='secondary'
                    size='sm'
                    onClick={() =>
                      selectedLawyer?.universeEID
                        ? displayModalAction('EditAltContactMethodModal')
                        : displayModalAction('EditContactModal')}
                  >
                    {`${(selectedLawyer?.altContactMethod || !selectedLawyer?.universeEID) ? 'Edit' : 'Add'}`}{' '}
                    contact info
                    <PencilAltIcon className='w-4 h-4 ml-2' />
                  </Button>
                </div>
                <div className='relative grow rounded-lg bg-white'>
                  <Card>
                    <div className='py-4 px-4'>
                      <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                        <span className='col-span-3 font-extrabold text-end'>Bar number:</span>
                        <span className='col-span-6 text-grey-100 font-normal text-left'>
                          {selectedLawyer?.barNumber}
                        </span>
                      </div>
                      <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                        <span className='col-span-3 font-extrabold text-end'>Address:</span>
                        <div className='col-span-6 text-grey-100 font-normal text-left'>
                          {`${selectedLawyer?.address1} ${selectedLawyer?.address2} ${
                            selectedLawyer?.city + (selectedLawyer?.state ? ', ' + selectedLawyer.state : '') + ' '
                            + selectedLawyer?.zipCode
                          }`}
                        </div>
                      </div>
                      <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                        <span className='col-span-3 font-extrabold text-end'>Phone:</span>
                        <span className='col-span-6 text-grey-100 font-normal text-left'>
                          {selectedLawyer?.phoneNumber}
                        </span>
                      </div>
                      <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                        <span className='col-span-3 font-extrabold text-end'>Email:</span>
                        <span className='col-span-6 text-grey-100 font-normal text-left'>{selectedLawyer?.email}</span>
                      </div>
                      {selectedLawyer?.altContactMethod
                        && (
                          <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                            <span className='col-span-3 font-extrabold text-end'>Alt. contact:</span>
                            <div className='flex col-span-6'>
                              <div className='truncate text-grey-100 font-normal text-left gap-2'>
                                {`${selectedLawyer?.altContactMethod}`}
                              </div>
                              <span className='pl-1'>
                                {selectedLawyer?.isAltContactPref && <StarIcon className='w-6 h-6 text-primary-100' />}
                              </span>
                            </div>
                          </div>
                        )}
                      <div>
                        {isShowMore
                          ? (
                            <div>
                              <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                                <span className='col-span-3 font-extrabold text-end'>Date of birth:</span>
                                <span className='col-span-6 text-grey-100 font-normal text-left'>
                                  {formattedBirthDate}
                                </span>
                              </div>
                              <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                                <span className='col-span-3 font-extrabold text-end'>Gender:</span>
                                <span className='col-span-6 text-grey-100 font-normal text-left'>
                                  {selectedLawyer?.gender}
                                </span>
                              </div>
                              <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                                <span className='col-span-3 font-extrabold text-end'>Status:</span>
                                <span className='col-span-6 text-grey-100 font-normal text-left'>
                                  {selectedLawyer?.status}
                                </span>
                              </div>
                              <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                                <span className='col-span-3 font-extrabold text-end'>Admit date:</span>
                                <span className='col-span-6 text-grey-100 font-normal text-left'>
                                  {formattedAdmitDate}
                                </span>
                              </div>
                              <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                                <span className='col-span-3 font-extrabold text-end'>Person type:</span>
                                <span className='col-span-6 text-grey-100 font-normal text-left'>
                                  {selectedLawyer?.personType?.name}
                                </span>
                              </div>
                              <div className='w-full pb-1 border-b grid grid-cols-9 mt-5 gap-6'>
                                <span className='col-span-3 font-extrabold text-end'>Law firm:</span>
                                <span className='col-span-6 text-grey-100 font-normal text-left'>
                                  {selectedLawyer?.lawFirm}
                                </span>
                              </div>
                              <div className='w-full pb-2 border-b grid grid-cols-3 mt-6 place-items-center'>
                                <span className='font-extrabold text-sm text-rigth w-9/12 mb-4'>
                                  Attorneys per firm
                                </span>
                                <span className='font-extrabold text-sm text-rigth w-9/12 mb-4'>
                                  Malpractice claims
                                </span>
                                <span className='font-extrabold text-sm text-rigth w-9/12 mb-4'>Suspense claims</span>
                                <span
                                  className={'h-12 w-12 p-1 rounded-full text-2xl font-extrabold h-full grid place-items-center text-white bg-primary-300'}
                                >
                                  {selectedLawyer?.attorneysPerFirm}
                                </span>
                                <span
                                  className={'h-12 w-12 p-1 rounded-full text-2xl font-extrabold h-full grid place-items-center text-white bg-primary-300'}
                                >
                                  {selectedLawyer?.malpracticeClaims}
                                </span>
                                <span
                                  className={'h-12 w-12 p-1 rounded-full text-2xl font-extrabold h-full grid place-items-center text-white bg-primary-300'}
                                >
                                  {selectedLawyer?.suspenseClaims}
                                </span>
                              </div>
                            </div>
                          )
                          : <div></div>}
                      </div>
                      <div className='w-full pb-2 mt-5 grid grid-cols-1 place-items-center'>
                        {isShowMore
                          ? (
                            <Link
                              text='Show less'
                              iconright={ArrowUpIcon}
                              secondary
                              onClick={() => setIsShowMore(false)}
                            />
                          )
                          : (
                            <Link
                              text='Show more'
                              iconright={ArrowDownIcon}
                              secondary
                              onClick={() => setIsShowMore(true)}
                            />
                          )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className='col-span-4'>
                <div className='flex gap-4'>
                  <h3 className='mb-6'>Interactions with {`${selectedLawyer?.firstName}`}</h3>
                  <InteractionFilter onFiltersChange={onInteractionFiltersChange} />
                </div>
                <div className='relative grow rounded-xl bg-white border border-outlineLightBlue '>
                  <Table
                    className='h-full'
                    hideShadow
                    columns={tableColumns}
                    data={interactionsPaged.items ?? []}
                    noDataComponent={
                      <EmptyTable
                        content={`You haven't recorded any recent interactions. Click the button to log a new interaction.`}
                        buttonText={'Add new interaction'}
                        onClick={displayNewInteractionAction}
                      />
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={interactionsPaged.totalItems}
                    progressPending={loadingInteraction}
                    progressComponent={
                      <div className='h-[440px] grid place-items-center'>
                        <Loading />
                      </div>
                    }
                    onChangePage={handlePageChange}
                    paginationPerPage={interactionsFilter.pageSize}
                    paginationComponent={TablePaginationComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='relative flex flex-row'>
          <div className='flex flex-col grow pt-4'>
            <div className='px-12 pt-4'>
              {(tasksPastDuePaged.items && tasksPastDuePaged.items.length > 0)
                && (
                  <div className='mb-6'>
                    <div className='bg-white rounded-lg'>
                      <Accordion
                        loading={loadingPastDueTasks}
                        defaultOpen
                        title={
                          <div className='flex gap-2'>
                            <span className='text-lg'>
                              Past due
                            </span>
                            <div className='ml-1 mt-0.5 h-6 w-6 rounded-full text-sm font-extrabold h-full grid place-items-center border-2 text-[#EB7100] border-[#EB7100]'>
                              {tasksPastDuePaged.totalItems}
                            </div>
                          </div>
                        }
                      >
                        <div className='pb-2 px-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-9 5xl:grid-cols-13 gap-y-5'>
                          {tasksPastDuePaged.items.map((task, index) => {
                            const mappedStatus = getMappedStatus(task.taskStatus?.code ?? '')
                            const mappedColor = getMappedAvatarColor(task.assignedTo?.firstName.slice(0, 1) ?? '')
                            return (
                              <div key={`dashboardTaskCardDiv-${index}`} className='cursor-pointer'>
                                <TaskCard
                                  onClick={() => displayTaskDetailsModal(task)}
                                  key={`dashboardTaskCard-${index}`}
                                  initials={`${task.assignedTo?.firstName.slice(0, 1)}${
                                    task.assignedTo?.lastName.slice(0, 1)
                                  }`}
                                  color={mappedColor}
                                  date={task.dueDate
                                    ? new Date(task.dueDate).toLocaleDateString(undefined, {
                                      day: 'numeric',
                                      month: 'numeric',
                                    })
                                    : ''}
                                  tags={task.tags}
                                  title={task.name}
                                  pillStatus={mappedStatus?.status}
                                  isPastDue={task.taskStatus?.isPastDue}
                                  pillText={mappedStatus?.text}
                                  subTasksCount={task.totalSubTasks}
                                  chatCount={task.totalComments}
                                  isSubTask={task.parentId !== null}
                                >
                                  {task.taskDetails}
                                </TaskCard>
                              </div>
                            )
                          })}
                        </div>
                        <footer>
                          <TablePaginationComponent
                            rowsPerPage={maxAmountItemsPerPage}
                            rowCount={tasksPastDuePaged.totalItems ?? 0}
                            currentPage={tasksPastDuePaged.pageNumber ?? 0}
                            onChangePage={async (pageNumber) => {
                              setLoadingPastDueTasks(true)
                              await setTaskPastDuePage({
                                pageNumber: pageNumber,
                                pageSize: maxAmountItemsPerPage,
                                orderBy: 'dueDate',
                                orderDirection: 'DESC',
                                contactId: selectedLawyer?.contactId,
                              })
                              setLoadingPastDueTasks(false)
                            }}
                            onChangeRowsPerPage={() => {}}
                          />
                        </footer>
                      </Accordion>
                    </div>
                  </div>
                )}
              {selectedUpcomingTasks?.items && selectedUpcomingTasks?.items.length > 0
                ? (
                  <div className='bg-white rounded-lg mb-6'>
                    <Accordion
                      loading={loadingUpcommingTasks}
                      defaultOpen
                      title={
                        <span className='text-lg'>
                          Upcoming tasks
                        </span>
                      }
                    >
                      <div className='pb-2 px-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-9 5xl:grid-cols-13 gap-y-5'>
                        {selectedUpcomingTasks?.items?.map((task) => {
                          const mappedStatus = getMappedStatus(task.taskStatus?.code ?? '')
                          const mappedColor = getMappedAvatarColor(task.assignedTo?.firstName.slice(0, 1) ?? '')
                          return (
                            <div key={`dashboardTaskCardDiv-${task.taskId}`} className='cursor-pointer'>
                              <TaskCard
                                onClick={() => displayTaskDetailsModal({ ...task })}
                                key={`dashboardTaskCard-${task.taskId}`}
                                initials={`${task.assignedTo?.firstName.slice(0, 1)}${
                                  task.assignedTo?.lastName.slice(0, 1)
                                }`}
                                color={mappedColor}
                                date={task.dueDate
                                  ? new Date(task.dueDate).toLocaleDateString(undefined, {
                                    day: 'numeric',
                                    month: 'numeric',
                                  })
                                  : ''}
                                tags={task.tags}
                                title={task.name}
                                pillStatus={mappedStatus?.status}
                                pillText={mappedStatus?.text}
                                subTasksCount={task.totalSubTasks}
                                chatCount={task.totalComments}
                                isSubTask={task.parentId !== null}
                              >
                                {task.taskDetails}
                              </TaskCard>
                            </div>
                          )
                        })}
                      </div>
                      <footer>
                        <TablePaginationComponent
                          rowsPerPage={maxAmountItemsPerPage}
                          rowCount={selectedUpcomingTasks?.totalItems ?? 0}
                          currentPage={selectedUpcomingTasks?.pageNumber ?? 0}
                          onChangePage={(pageNumber) => fetchUpcommingTasksPagination(pageNumber)}
                          onChangeRowsPerPage={() => {}}
                        />
                      </footer>
                    </Accordion>
                  </div>
                )
                : (
                  <EmptyTable
                    content={`You don't have any upcoming tasks. Click the button to create a new task.`}
                    buttonText={'Create new task'}
                    onClick={displayCreateTaskAction}
                  />
                )}
              {selectedCompletedTasks?.items && selectedCompletedTasks?.items.length > 0
                && (
                  <div className='bg-white rounded-lg mb-6'>
                    <Accordion
                      loading={loadingCompletedTasks}
                      title={
                        <span className='text-lg'>
                          Completed tasks
                        </span>
                      }
                    >
                      <div className='pb-2 px-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-9 5xl:grid-cols-13 gap-y-5'>
                        {selectedCompletedTasks?.items?.map((task) => {
                          const mappedStatus = getMappedStatus(task.taskStatus?.code ?? '')
                          const mappedColor = getMappedAvatarColor(task.assignedTo?.firstName.slice(0, 1) ?? '')
                          return (
                            <div key={`dashboardTaskCardDiv-${task.taskId}`} className='cursor-pointer'>
                              <TaskCard
                                onClick={() => displayTaskDetailsModal({ ...task })}
                                key={`dashboardTaskCard-${task.taskId}`}
                                initials={`${task.assignedTo?.firstName.slice(0, 1)}${
                                  task.assignedTo?.lastName.slice(0, 1)
                                }`}
                                color={mappedColor}
                                date={task.dueDate
                                  ? new Date(task.dueDate).toLocaleDateString(undefined, {
                                    day: 'numeric',
                                    month: 'numeric',
                                  })
                                  : ''}
                                tags={task.tags}
                                title={task.name}
                                pillStatus={mappedStatus?.status}
                                pillText={mappedStatus?.text}
                                subTasksCount={task.totalSubTasks}
                                chatCount={task.totalComments}
                                isSubTask={task.parentId !== null}
                              >
                                {task.taskDetails}
                              </TaskCard>
                            </div>
                          )
                        })}
                      </div>
                      <footer>
                        <TablePaginationComponent
                          rowsPerPage={maxAmountItemsPerPage}
                          rowCount={selectedCompletedTasks?.totalItems ?? 0}
                          currentPage={selectedCompletedTasks?.pageNumber ?? 0}
                          onChangePage={(pageNumber) => fetchCompletedTasksPagination(pageNumber)}
                          onChangeRowsPerPage={() => {}}
                        />
                      </footer>
                    </Accordion>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
