import { InformationCircleIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import { Link } from 'components/atoms/Link/Link'
import Select, { Option } from 'components/atoms/Select/Select'
import { SlideOut } from 'components/atoms/SlideOut/SlideOut'
import { SlideOutFooter } from 'components/atoms/SlideOut/SlideOutFooter'
import TextArea from 'components/atoms/TextArea/TextArea'
import TextInput from 'components/atoms/TextInput/TextInput'
import { SearchInput } from 'components/molecules/SearchInput/SearchInput'
import { SubTasksSelector } from 'components/molecules/SubTasksSelector/SubTasksSelector'
import { TagSelector } from 'components/molecules/TagSelector/TagSelector'
import { TagAssignment } from 'entities/enums/TagAssignment'
import { InteractionRequest } from 'entities/types/Interactions'
import { SubTaskSelection } from 'entities/types/Tasks'
import { SubTaskDetail, TagResponse, TaskTemplateDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NewInteractionFormValidator } from 'validators/interactionFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const NewInteractionSlideOut = () => {
  const {
    contacts,
    loadingContacts,
    areasOfAssistance,
    interactionTypes,
    methodsOfContact,
    users,
    newContactCreation,
    allTaskTemplates,
    taskStatus,
    resultCount,
  } = useAppState()
  const { displaySlideOutAction, addNewInteractionAction, displayModalAction, updateContacts, showMoreContacts } =
    useActions()
  const [isCreateInteractionTask, setCreateInteractionTask] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [selectedSubTasks, setSelectedSubTasks] = useState<SubTaskSelection[]>()
  const [taskSelectedTags, setSelectedTags] = useState<TagResponse[]>()
  const [validate, setValidate] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<TaskTemplateDetail>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showMoreCount, setShowMoreCount] = useState<number>(resultCount)
  const showMore = contacts ? showMoreCount < (contacts[0]?.totalCount ?? 0) : false
  const [fetchNewDate, setFetchNewData] = useState<boolean>(false)

  const { formState: { errors, isDirty }, handleSubmit, register, setValue } = useForm({
    defaultValues: { createInteractionTask: false } as InteractionRequest,
    resolver: yupResolver(NewInteractionFormValidator),
  })

  const onSearchInputChange = (value: string) => {
    if (value.length > 1) {
      setShowMoreCount(resultCount)
      setSearchTerm(value)
      updateContacts(value)
    }
  }

  const onShowMore = async () => {
    setFetchNewData(true)
    setShowMoreCount((prevCount) => prevCount + 25)
    await showMoreContacts({ term: searchTerm, resultCount: showMoreCount + 25 })
    setFetchNewData(false)
  }

  const handleTemplateChange = (value: { key: string; value: string }) => {
    setValidate(false)
    let selectedTemplate = allTaskTemplates.find(template => template.id?.toString() === value.key)
    let templateTags = selectedTemplate?.tags?.map(t => ({
      ...t,
      assignmentType: TagAssignment.FromTemplate.valueOf(),
    }))
    setSelectedTemplate(selectedTemplate)
    setValue('task.name', value.key !== '' ? 'CLONE - ' + selectedTemplate?.name : '', {
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('task.taskDetails', value.key !== '' ? selectedTemplate?.taskDetails : undefined, {
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('task.templateId', value.key !== '' ? selectedTemplate?.id : undefined, {
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('task.tags', value.key !== '' ? templateTags : undefined, { shouldDirty: true, shouldValidate: true })
    setSelectedTags(value.key !== '' ? templateTags : undefined)
    setSelectedSubTasks(
      value.key !== '' ? selectedTemplate?.subTasks?.map(subTask => ({ subTask: subTask, checked: false })) : undefined,
    )
  }

  const handleSubTaskChange = (subTasks: SubTaskSelection[]) => {
    setSelectedSubTasks(subTasks)
    const toDoStatus = taskStatus.find(ts => ts.code === 'TD')
    const selectedSubTasks: SubTaskDetail[] = subTasks.filter(sts => sts.checked).map(sts => ({
      name: sts.subTask.name,
      taskDetails: sts.subTask.taskDetails,
      tags: sts.subTask.tags?.map(t => ({ ...t, assignmentType: TagAssignment.FromTemplate.valueOf() })),
      assignedTo: sts.assignedTo,
      dueDate: new Date(sts.dueDate ?? ''),
      taskStatus: toDoStatus,
    }))
    setValue('task.subTasks', selectedSubTasks, { shouldDirty: true, shouldValidate: true })
  }

  const handleCreateInteractionTaskChanged = (createInteractionTask: boolean) => {
    setCreateInteractionTask(createInteractionTask)
    setValue('createInteractionTask', createInteractionTask, { shouldDirty: true })
    setValue('task', createInteractionTask ? {} : undefined, { shouldDirty: true })
  }

  const handleAsignToChanged = (user: Option) => {
    let selectedUser = users.find(u => u.userId?.toString() === user.key)
    setValue('task.assignedTo', selectedUser, { shouldDirty: true, shouldValidate: true })
  }

  return (
    <SlideOut
      title='Log a new interaction'
      onClose={() => isDirty ? displayModalAction('SlideOutWarningModal') : displaySlideOutAction('None')}
    >
      <form
        onSubmit={handleSubmit(async (data) => {
          setSaving(true)
          await addNewInteractionAction(data)
          setSaving(false)
        })}
      >
        <>
          <div className='mb-4 mt-4'>
            <p className='font-bold text-sm mb-1'>Name</p>
            <div>
              <SearchInput
                addNewPerson
                fetchingData={loadingContacts}
                value={contacts.find(f => Number(f.itemId) === newContactCreation?.contactId)}
                data={contacts}
                onChange={(value) => {
                  if (value) {
                    setValue('contactId', Number(value.itemId), { shouldDirty: true, shouldValidate: true })
                  }
                }}
                onInputChange={(value) => onSearchInputChange(value)}
                fetchingNewData={fetchNewDate}
                showMore={showMore}
                onShowMore={onShowMore}
              />
            </div>
            <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
              {errors.contactId
                && (
                  <>
                    <InformationCircleIcon className='h-4 w-4 mr-1' />
                    {errors.contactId.message}
                  </>
                )}
            </span>
          </div>
          <div className='grid grid-cols-2 gap-x-4 gap-y-6'>
            <div>
              <p className='font-bold text-sm mb-1'>Date</p>
              <div className='relative'>
                <TextInput
                  type='date'
                  maxLength={52}
                  {...register('interactionDate')}
                />
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.interactionDate
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.interactionDate.message}
                      </>
                    )}
                </span>
              </div>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Method of contact</p>
              <Select
                label='Method of contact'
                data={methodsOfContact.map((item, idx) => {
                  return { key: item.itemId ?? idx, value: item.itemText ?? '' }
                })}
                onChange={(value) => {
                  setValue('methodOfContactId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.methodOfContactId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.methodOfContactId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Interaction type</p>
              <Select
                label='Interaction type'
                data={interactionTypes.map((item, idx) => {
                  return { key: item.itemId ?? idx, value: item.itemText ?? '' }
                })}
                onChange={(value) => {
                  setValue('interactionTypeId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.interactionTypeId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.interactionTypeId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Area of assistance</p>
              <Select
                label='Area of assistance'
                data={areasOfAssistance.map((item, idx) => {
                  return { key: item.itemId ?? idx, value: item.itemText ?? '' }
                })}
                onChange={(value) => {
                  setValue('areaOfAssistanceId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.areaOfAssistanceId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.areaOfAssistanceId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Cell number</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                {...register('cellNumber')}
                placeholder='Cell number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.cellNumber
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.cellNumber.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Other email address</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                placeholder='Other email'
                {...register('otherEmailAddress')}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.otherEmailAddress
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.otherEmailAddress.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4 mt-4'>
            <div>
              <p className='font-bold text-sm'>Notes</p>
              <TextArea
                {...register('notes')}
                rows={8}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.notes
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.notes.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4 mt-4'>
            {!isCreateInteractionTask
              ? (
                <Link
                  className='text-primary-100  font-bold'
                  text='Create a task for this interaction'
                  iconleft={PlusCircleIcon}
                  onClick={() => handleCreateInteractionTaskChanged(true)}
                />
              )
              : <div></div>}
          </div>
          {isCreateInteractionTask
            ? (
              <div>
                <div className='grid grid-cols-7 gap-x-4 gap-y-6 pb-8'>
                  <div className='text-lg font-extrabold text-primary-300 col-span-2'>Create a task</div>
                  <div className='col-span-2 text-xs mt-2'>
                    <Link
                      text='Remove task'
                      underline
                      onClick={() => handleCreateInteractionTaskChanged(false)}
                    />
                  </div>
                </div>
                <div className='mb-4 mt-4'>
                  <p className='font-bold text-sm'>Task name</p>
                  <TextInput
                    {...register('task.name')}
                    placeholder='Task name'
                  />
                </div>
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.task?.name
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.task.name.message}
                      </>
                    )}
                </span>
                <div className='grid grid-cols-2 gap-x-4'>
                  <div>
                    <p className='font-bold text-sm mb-1'>Due date</p>
                    <div className='relative'>
                      <TextInput
                        type='date'
                        maxLength={52}
                        {...register('task.dueDate')}
                      />
                    </div>
                  </div>
                  <div>
                    <p className='font-bold text-sm mb-1'>Assign to</p>
                    <Select
                      label='Assign to'
                      data={users?.map(u => {
                        return { key: u.userId?.toString() ?? '', value: u.firstName ?? '' + u.lastName ?? '' }
                      })}
                      onChange={handleAsignToChanged}
                      selectedValueFontWeight='font-light'
                    />
                  </div>
                  <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                    {errors.task?.dueDate
                      && (
                        <>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          {errors.task.dueDate.message}
                        </>
                      )}
                  </span>
                  <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                    {errors.task?.assignedTo
                      && (
                        <>
                          <InformationCircleIcon className='h-4 w-4 mr-1' />
                          {errors.task.assignedTo.message}
                        </>
                      )}
                  </span>
                </div>
                <div>
                  <div className='mb-4 mt-4'>
                    <span className='font-bold text-sm'>Task template</span>
                    <span className='text-secondary-200'>&nbsp;(optional)</span>
                    <Select
                      label='Task template'
                      data={allTaskTemplates?.map(item => {
                        return { key: item.id?.toString() ?? '', value: item.name ?? '' }
                      })}
                      default={{
                        key: selectedTemplate?.id?.toString() ?? '',
                        value: selectedTemplate?.name ?? '',
                      }}
                      onChange={handleTemplateChange}
                    />
                  </div>
                  <div className='relative h-full space-y-4 pr-2'>
                    <h4>Subtasks to include:</h4>
                    <SubTasksSelector
                      subTasksSelection={selectedSubTasks}
                      onChange={handleSubTaskChange}
                      validate={validate}
                    />
                  </div>
                  <div className='mb-4 mt-4'>
                    <span className='font-bold text-sm'>Add a tag</span>
                    <span className='text-secondary-200'>&nbsp;(optional)</span>
                    <TagSelector
                      value={taskSelectedTags}
                      onChange={(tag) => setValue('task.tags', tag, { shouldDirty: true, shouldValidate: true })}
                    />
                  </div>
                  <div className='mb-4 mt-4'>
                    <div>
                      <p className='font-bold text-sm'>Task details</p>
                      <TextArea
                        {...register('task.taskDetails')}
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.task?.taskDetails
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.task.taskDetails.message}
                      </>
                    )}
                </span>
              </div>
            )
            : <div></div>}
          <SlideOutFooter>
            <Button className='mr-2 h-10 w-1/2' type='submit' disabled={saving}>
              {saving
                ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                : (
                  <span>
                    Save
                  </span>
                )}
            </Button>
          </SlideOutFooter>
        </>
      </form>
    </SlideOut>
  )
}
