import { AvatarColors } from 'entities/enums/AvatarColors'
import { PillStatus } from 'entities/enums/PillStatus'
import { TagColor } from 'entities/enums/TagsColors'
import { TaskSummaryFilters } from 'entities/types/Tasks'
import { TaskDetail, TaskResponse, TaskSummaryResponse } from 'generated/iTypes'
import { getDate } from './stringHelpers'

export const getMappedStatus = (statusCode: string) => {
  switch (statusCode) {
    case 'BL':
      return { status: PillStatus.Blocked, text: 'Blocked' }
    case 'DL':
      return { status: PillStatus.Delayed, text: 'Delayed' }
    case 'DN':
      return { status: PillStatus.Done, text: 'Done' }
    case 'IP':
      return { status: PillStatus.InProgress, text: 'In Progress' }
    case 'TD':
      return { status: PillStatus.ToDo, text: 'To Do' }
    default:
      return { status: PillStatus.InProgress, text: 'In Progress' }
  }
}

export const getMappedAvatarColor = (text: string) => {
  const chartCode = text.toUpperCase().charCodeAt(0)
  switch (true) {
    case (chartCode >= 65 && chartCode <= 73):
      return AvatarColors.Blue
    case (chartCode >= 74 && chartCode <= 81):
      return AvatarColors.Red
    case (chartCode >= 82 && chartCode <= 90):
      return AvatarColors.Yellow
    default:
      return AvatarColors.Blue
  }
}

export const getMappedTagsColor = (text: string) => {
  const chartCode = text.toUpperCase().charCodeAt(0)
  switch (true) {
    case (chartCode >= 65 && chartCode <= 71):
      return TagColor.Blue
    case (chartCode >= 72 && chartCode <= 78):
      return TagColor.Green
    case (chartCode >= 79 && chartCode <= 85):
      return TagColor.Purple
    case (chartCode >= 86 && chartCode <= 90):
      return TagColor.Yellow
    default:
      return TagColor.Blue
  }
}

type FormatType = 'MM-DD-YYYY' | 'YYYY-MM-DD' | 'MM/DD/YY' | 'MM/DD'

export const formatDate = (input: string, format: FormatType = 'MM-DD-YYYY'): string => {
  if (!input || input === '') {
    return '-'
  }
  const date = new Date(input)
  // invalid date string input
  if (isNaN(date.getTime())) {
    return '-'
  }

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  switch (format) {
    case 'MM-DD-YYYY':
      return `${month}-${day}-${year}`
    case 'MM/DD/YY':
      return `${month}/${day}/${year.toString().slice(-2)}`
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`
    case 'MM/DD':
      return `${month}/${day}`
    default:
      return `${month}.${day}.${year}`
  }
}

export const filterTaskSummary = (value: TaskSummaryResponse[], filters?: TaskSummaryFilters) => {
  if (filters?.toDate === undefined) {
    return value.slice(0, 5)
  }
  return value
}

function isObject(object: any) {
  return object !== null && typeof object === 'object'
}

export const deepEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2))
      || (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}

export const mapTaskResponse = (task: TaskDetail, previous: TaskResponse): TaskResponse => {
  return {
    taskId: task.id,
    assignedTo: task.assignedTo,
    dueDate: task.dueDate,
    name: task.name,
    taskDetails: task.taskDetails,
    taskStatus: {
      code: task.taskStatus?.code,
      name: task.taskStatus?.itemText,
      taskStatusId: task.taskStatus?.itemId,
      isPastDue: previous.taskStatus?.isPastDue,
    },
    tags: task.tags,
    totalComments: task.comments?.length,
    totalSubTasks: task.subTasks?.length,
    parentId: previous.parentId,
  } as TaskResponse
}

export const sortTasks = (array?: TaskResponse[]) => {
  return array?.sort((postA, postB) => {
    if (getDate(postA.dueDate) < (getDate(postB.dueDate))) {
      return 1
    } else if (getDate(postA.dueDate) > getDate(postB.dueDate)) {
      return -1
    }
    return 0
  })
}
