import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import { SlideOut } from 'components/atoms/SlideOut/SlideOut'
import { SlideOutFooter } from 'components/atoms/SlideOut/SlideOutFooter'
import { CreateTask } from 'components/molecules/CreateTask/CreateTask'
import { TaskDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { NewTaskFormValidator } from 'validators/taskFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const CreateTaskSlideOut = () => {
  const { authenticatedUser } = useAppState()
  const { displaySlideOutAction, displayModalAction, addNewTaskAction } = useActions()
  const [saving, setSaving] = useState<boolean>(false)
  const methods = useForm({
    defaultValues: { subTasks: [], createdBy: authenticatedUser.userId } as TaskDetail,
    resolver: yupResolver(NewTaskFormValidator),
  })
  useEffect(() => {}, [methods.formState.isDirty])
  return (
    <SlideOut
      title='Create a task'
      onClose={() =>
        methods.formState.isDirty ? displayModalAction('SlideOutWarningModal') : displaySlideOutAction('None')}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (data) => {
            setSaving(true)
            await addNewTaskAction(data)
            setSaving(false)
          })}
        >
          <>
            <CreateTask />
            <SlideOutFooter>
              <Button className='mr-2 h-10 w-1/2' type='submit' disabled={saving}>
                {saving
                  ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                  : (
                    <span>
                      Save
                    </span>
                  )}
              </Button>
            </SlideOutFooter>
          </>
        </form>
      </FormProvider>
    </SlideOut>
  )
}
