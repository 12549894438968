import { AvatarColors } from 'entities/enums/AvatarColors'

export interface AvatarProps {
  color?: AvatarColors
  initials?: string
}

export const Avatar = ({
  color = AvatarColors.Blue,
  ...props
}: AvatarProps) => {
  return (
    <div
      className={`h-9 w-9 p-1 rounded-full text-sm font-extrabold h-full grid place-items-center
        ${color === AvatarColors.Blue && 'bg-[#CDDCFF]'}
        ${color === AvatarColors.Yellow && 'bg-[#FFF0C5]'}
        ${color === AvatarColors.Red && 'bg-[#FFDFE0]'}
        `}
    >
      {props.initials?.toUpperCase()}
    </div>
  )
}
