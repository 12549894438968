import * as Yup from 'yup'

export const NewTaskFormValidator = Yup.object().shape({
  name: Yup.string().required('Task name is a required field'),
  dueDate: Yup.string().required('Due Date is a required field'),
  assignedTo: Yup.object().required('Assign To is required field'),
  taskDetails: Yup.string().required('Task details are a required field'),
  subTasks: Yup.array(
    Yup.object({
      dueDate: Yup.date().required().required('Due Date is a required field'),
      assignedTo: Yup.object().required('Assign To is required field'),
    }),
  )
    .notRequired()
    .default(undefined),
})
