import { Button } from 'components/atoms/Button/Button'
import { Modal } from 'components/molecules'
import { useActions, useAppState } from 'presenter'
import React from 'react'

export function SlideOutWarningModal() {
  const { currentModal } = useAppState()
  const { displayModalAction, displaySlideOutAction } = useActions()

  const onClose = async (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    e.stopPropagation()
    displayModalAction('None')
    displaySlideOutAction('None')
  }

  const onCancel = () => displayModalAction('None')

  return (
    <Modal
      isOpen={currentModal === 'SlideOutWarningModal'}
      onClose={onCancel}
      hideXIcon
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          Are you sure you want to leave this page?
        </div>
        <div className='text-primary-300 text-base font-normal text-center mb-6'>
          Your work will not be saved.
        </div>
        <div className='flex flex-row'>
          <Button className='mx-2 px-7' rounded='lg' color='secondary' onClick={onCancel}>No</Button>
          <Button className='mx-2 px-7' onClick={onClose}>Yes</Button>
        </div>
      </div>
    </Modal>
  )
}
