import { InformationCircleIcon } from '@heroicons/react/outline'
import Select, { Option } from 'components/atoms/Select/Select'
import TextArea from 'components/atoms/TextArea/TextArea'
import TextInput from 'components/atoms/TextInput/TextInput'
import { SearchInput } from 'components/molecules/SearchInput/SearchInput'
import { TagSelector } from 'components/molecules/TagSelector/TagSelector'
import { TagAssignment } from 'entities/enums/TagAssignment'
import { SubTaskSelection } from 'entities/types/Tasks'
import { SubTaskDetail, TagResponse, TaskDetail, TaskTemplateDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { SubTasksSelector } from '../SubTasksSelector/SubTasksSelector'

export const CreateTask = () => {
  const { formState: { errors }, register, setValue, getValues } = useFormContext<TaskDetail>()
  const {
    users,
    contacts,
    allTaskTemplates,
    loadingContacts,
    newContactCreation,
    taskStatus,
    resultCount,
  } = useAppState()
  const [selectedSubTasks, setSelectedSubTasks] = useState<SubTaskSelection[]>()
  const [taskSelectedTags, setSelectedTags] = useState<TagResponse[]>()
  const [validate, setValidate] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<TaskTemplateDetail>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showMoreCount, setShowMoreCount] = useState<number>(resultCount)
  const [fetchNewDate, setFetchNewData] = useState<boolean>(false)
  const { updateContacts, showMoreContacts } = useActions()

  const showMore = contacts ? showMoreCount < (contacts[0]?.totalCount ?? 0) : false

  const handleAsignToChanged = (user: Option) => {
    let selectedUser = users.find(u => u.userId?.toString() === user.key)
    setValue('assignedTo', selectedUser, { shouldDirty: true, shouldValidate: true })
  }

  const handleTagsChanged = (selectedTags: TagResponse[]) => {
    setSelectedTags(selectedTags)
    setValue('tags', selectedTags, { shouldDirty: true, shouldValidate: true })
  }

  const onSearchInputChange = (value: string) => {
    if (value.length > 1) {
      setShowMoreCount(resultCount)
      setSearchTerm(value)
      updateContacts(value)
    }
  }

  const onShowMore = async () => {
    setFetchNewData(true)
    setShowMoreCount((prevCount) => prevCount + 25)
    await showMoreContacts({ term: searchTerm, resultCount: showMoreCount + 25 })
    setFetchNewData(false)
  }

  useEffect(() => {
    setValidate(errors.subTasks !== undefined)
  }, [errors])

  const handleTemplateChange = (value: { key: string; value: string }) => {
    setValidate(false)
    let selectedTemplate = allTaskTemplates.find(template => template.id?.toString() === value.key)
    let templateTags = selectedTemplate?.tags?.map(t => ({
      ...t,
      assignmentType: TagAssignment.FromTemplate.valueOf(),
    }))
    setSelectedTemplate(selectedTemplate)
    setValue('name', value.key !== '' ? 'CLONE - ' + selectedTemplate?.name : '', {
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('taskDetails', value.key !== '' ? selectedTemplate?.taskDetails : undefined, {
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('templateId', value.key !== '' ? selectedTemplate?.id : undefined, {
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('tags', value.key !== '' ? templateTags : undefined, { shouldDirty: true, shouldValidate: true })
    setSelectedTags(value.key !== '' ? templateTags : undefined)
    setSelectedSubTasks(
      value.key !== '' ? selectedTemplate?.subTasks?.map(subTask => ({ subTask: subTask, checked: false })) : undefined,
    )
  }

  const handleSubTaskChange = (subTasks: SubTaskSelection[]) => {
    setSelectedSubTasks(subTasks)
    const toDoStatus = taskStatus.find(ts => ts.code === 'TD')
    const selectedSubTasks: SubTaskDetail[] = subTasks.filter(sts => sts.checked).map(sts => ({
      name: sts.subTask.name,
      taskDetails: sts.subTask.taskDetails,
      tags: sts.subTask.tags?.map(t => ({ ...t, assignmentType: TagAssignment.FromTemplate.valueOf() })),
      assignedTo: sts.assignedTo,
      dueDate: new Date(sts.dueDate ?? ''),
      taskStatus: toDoStatus,
    }))
    setValue('subTasks', selectedSubTasks, { shouldDirty: true, shouldValidate: true })
  }

  return (
    <div>
      <div className='mb-4 mt-4'>
        <p className='font-bold text-sm'>Task name</p>
        <TextInput
          {...register('name')}
          placeholder='Task Name'
        />
        <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
          {errors.name
            && (
              <>
                <InformationCircleIcon className='h-4 w-4 mr-1' />
                {errors.name.message}
              </>
            )}
        </span>
      </div>
      <div className='grid grid-cols-2 gap-x-4'>
        <div>
          <p className='font-bold text-sm mb-1'>Due date</p>
          <div className='relative'>
            <TextInput
              type='date'
              maxLength={52}
              {...register('dueDate')}
            />
          </div>
        </div>
        <div>
          <p className='font-bold text-sm mb-1'>Assign to</p>
          <Select
            label='Assign to'
            data={users?.map(u => {
              return { key: u.userId?.toString() ?? '', value: u.firstName ?? '' + u.lastName ?? '' }
            })}
            default={{
              key: users?.find(u => u.userId === getValues('assignedTo.userId'))?.userId?.toString() ?? '',
              value: users?.find(u => u.userId === getValues('assignedTo.userId'))?.firstName ?? '',
            }}
            onChange={handleAsignToChanged}
          />
        </div>
        <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
          {errors.dueDate
            && (
              <>
                <InformationCircleIcon className='h-4 w-4 mr-1' />
                {errors.dueDate.message}
              </>
            )}
        </span>
        <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
          {errors.assignedTo
            && (
              <>
                <InformationCircleIcon className='h-4 w-4 mr-1' />
                {errors.assignedTo.message}
              </>
            )}
        </span>
      </div>
      <div className='mb-4 mt-4'>
        <span className='font-bold text-sm'>Lawyer associated with task</span>
        <span className='text-secondary-200'>&nbsp;(optional)</span>
        <SearchInput
          addNewPerson
          fetchingData={loadingContacts}
          value={contacts.find(f => Number(f.itemId) === newContactCreation?.contactId)}
          data={contacts}
          onChange={(value) => {
            setValue('contact', value, { shouldDirty: true, shouldValidate: true })
          }}
          onInputChange={(value) => onSearchInputChange(value)}
          fetchingNewData={fetchNewDate}
          showMore={showMore}
          onShowMore={onShowMore}
        />
      </div>
      <div className='mb-4 mt-4'>
        <span className='font-bold text-sm'>Task template</span>
        <span className='text-secondary-200'>&nbsp;(optional)</span>
        <Select
          label='Task template'
          data={allTaskTemplates?.map(item => {
            return { key: item.id?.toString() ?? '', value: item.name ?? '' }
          })}
          default={{
            key: selectedTemplate?.id?.toString() ?? '',
            value: selectedTemplate?.name ?? '',
          }}
          onChange={handleTemplateChange}
        />
      </div>
      <div className='relative h-full space-y-4 pr-2'>
        <h4>Subtasks to include:</h4>
        <SubTasksSelector
          subTasksSelection={selectedSubTasks}
          onChange={handleSubTaskChange}
          validate={validate}
        />
      </div>
      <div className='mb-4 mt-4'>
        <span className='font-bold text-sm'>Add a tag</span>
        <span className='text-secondary-200'>&nbsp;(optional)</span>
        <TagSelector
          value={taskSelectedTags}
          onChange={handleTagsChanged}
        />
      </div>
      <div className='mb-4 mt-4'>
        <div>
          <p className='font-bold text-sm'>Task details</p>
          <TextArea
            {...register('taskDetails')}
            rows={4}
          />
        </div>
        <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
          {errors.taskDetails
            && (
              <>
                <InformationCircleIcon className='h-4 w-4 mr-1' />
                {errors.taskDetails.message}
              </>
            )}
        </span>
      </div>
    </div>
  )
}
