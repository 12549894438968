import { CheckCircleIcon, PhoneIncomingIcon } from '@heroicons/react/outline'
import { SidebarIcons } from 'entities/enums/SidebarIcons'
import { LibNavOption } from 'entities/types/NavOption'
import { useActions } from 'presenter'
import React from 'react'

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

type SidebarProps = {
  avatar: string
  name: {
    first: string
    last: string
  }
  navOptions: LibNavOption[]
  children: React.ReactNode
  PlatformLogo?: React.ReactNode
}

export function Sidebar({ PlatformLogo, ...props }: SidebarProps): React.ReactElement {
  const { displayNewInteractionAction, displayCreateTaskAction } = useActions()

  type CallbackMapping = {
    [key: string]: () => Promise<void>
  }

  const callbackMappings: CallbackMapping = {
    LogInteraction: displayNewInteractionAction,
    CreateTask: displayCreateTaskAction,
  }

  const handleNavOptionClick = (name: string) => {
    const callback = callbackMappings[name]
    callback && callback()
  }

  return (
    <>
      <div>
        <div className='hidden md:flex md:w-20 md:flex-col md:fixed md:inset-y-0 md:top-20 z-50'>
          <div className='flex-1 flex flex-col min-h-0 bg-primary-100'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
              <nav className='mt-5 flex-1'>
                <div className='border-t'>
                  {props.navOptions.map((item) => (
                    <li
                      key={item.name}
                      onClick={() => handleNavOptionClick(item.name)}
                      className={classNames(
                        (item.current)
                          ? 'menu-item-active'
                          : 'menu-item',
                        'group flex items-center text-center py-4 text-sm font-medium flex-col cursor-pointer',
                      )}
                    >
                      {item.icon === SidebarIcons.Phone && <PhoneIncomingIcon className='h-7 w-7 text-white' />}
                      {item.icon === SidebarIcons.CircleCheck && <CheckCircleIcon className='h-7 w-7 text-white' />}
                    </li>
                  ))}
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className='md:pl-20 flex flex-col flex-1'>
          <main className='flex-1'>
            <div className='md:pt-20 py-6 mx-auto px-4 sm:px-6 md:px-8 min-h-screen h-screen'>
              {props.children}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
