import { InformationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import TextInput from 'components/atoms/TextInput/TextInput'
import { ForgotPasswordRequest } from 'generated/iTypes'
import { useActions } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ForgotPasswordFormValidator } from 'validators/usersFormValidator'

export const ForgotPassword = () => {
  const { forgotPasswordAction } = useActions()
  const { formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: {} as ForgotPasswordRequest,
    resolver: yupResolver(ForgotPasswordFormValidator),
  })
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className='h-screen bg-bgLightBlue'>
      <div className='flex flex-col items-center justify-center text-primary-300 px-8 py-8 mx-auto md:h-screen lg:py-0 '>
        <div className='w-full bg-white rounded-2xl shadow-xl md:mt-0 sm:max-w-md xl:p-0'>
          <div className='mx-6 my-20 space-y-4 md:space-y-6 sm:p-8'>
            <h1>Forgot Password</h1>
            <div>
              Enter the email address associated with your account to recover your password
            </div>
            <form
              className='space-y-4 md:space-y-6'
              onSubmit={handleSubmit(async (data) => {
                setLoading(true)
                const result = await forgotPasswordAction(data)
                if (result !== true) {
                  setLoading(false)
                }
              })}
            >
              <div>
                <span className='block mb-2 text-primary-300 text-sm font-bold'>Email address</span>
                <TextInput {...register('emailAddress')} />
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.emailAddress
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.emailAddress.message}
                      </>
                    )}
                </span>
              </div>
              <div className='flex items-center justify-items-end pt-2'>
                <div className='flex justify-end w-full'>
                  <Button className='h-12 items-center w-2/3' type='submit' disabled={loading}>
                    Send recovery email
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
