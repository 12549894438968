export enum LoopkUps {
  AreaOfAssistance = 'getAreaOfAssistance',
  InteractionType = 'getInteractionType',
  Contact = 'getContact',
  MethodOfContact = 'getMethodOfContact',
  PMAContacts = 'getPMAContact',
  TaskStatus = 'getTaskStatus',
  PersonType = 'getPersonType',
  Region = 'getRegion',
  PLFTypes = 'getPlfTypes',
  OptedInTypes = 'getOptedInTypes',
  PLFStatus = 'getPlfStatus',
  States = 'getStates',
  OregonCounties = 'getOregonCounties',
  OfficeClosure = 'getOfficeClosure',
}
