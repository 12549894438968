import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import ReactModal from 'react-modal'

type ModalProps = {
  children: React.ReactNode
  isOpen: boolean
  onClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void
  contentStyle?: {
    backgroundColor?: string
    width?: string
    padding?: string
  }
  overlayColor?: string
  shouldCloseOnEsc?: boolean
  hideXIcon?: boolean
}

export function Modal(props: ModalProps): React.ReactElement {
  const modalStyles = {
    content: {
      backgroundColor: props.contentStyle?.backgroundColor || '#FFFFFF',
      border: 'none',
      borderRadius: 8,
      bottom: 'auto',
      left: '50%',
      marginRight: '-50%',
      overflow: 'visible',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: props.contentStyle?.width || 'max-content',
    },
    overlay: {
      backgroundColor: props.overlayColor || 'rgba(20, 53, 69, 0.5)',
      zIndex: 150,
    },
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={props.contentStyle?.padding
        ? {
          ...modalStyles,
          content: { ...modalStyles.content, padding: props.contentStyle.padding },
        }
        : modalStyles}
      ariaHideApp={false}
      shouldCloseOnEsc={props.shouldCloseOnEsc}
    >
      <div className='relative'>
        {!props.hideXIcon
          && (
            <div className='absolute right-0 z-40'>
              <button
                onClick={props.onClose}
                className='rounded-full bg-primary-300 w-8 h-8 ml-4 text-center text-white'
              >
                <XIcon className='flex w-6 inline-block ml-auto mr-auto' />
              </button>
            </div>
          )}
        {props.children}
      </div>
    </ReactModal>
  )
}
