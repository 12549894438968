import { InformationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { states } from 'assets/states'
import { Button } from 'components/atoms/Button/Button'
import Select from 'components/atoms/Select/Select'
import TextInput from 'components/atoms/TextInput/TextInput'
import { Modal } from 'components/molecules'
import { ContactInsertRequest } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NewContactFormValidator } from 'validators/contactFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'
import { ModalLeaveWarning } from './ModalLeaveWarning'

export const AddNewContactModal = () => {
  const { currentModal, personTypes, regions } = useAppState()
  const { displayModalAction, addNewContactAction } = useActions()
  const [savingContact, setSavingContact] = useState<boolean>(false)
  const [closing, setClosing] = useState<boolean>(false)

  const { formState: { errors, isDirty }, handleSubmit, register, setValue } = useForm({
    defaultValues: {} as ContactInsertRequest,
    resolver: yupResolver(NewContactFormValidator),
  })

  const onClose = async () => {
    setClosing(true)
    if (!isDirty) displayModalAction('None')
  }

  const handleSave = async (payload: ContactInsertRequest) => {
    setSavingContact(true)
    await addNewContactAction(payload)
    setSavingContact(false)
  }

  return (
    <Modal
      isOpen={currentModal === 'AddNewContactModal'}
      onClose={onClose}
      contentStyle={{
        width: '646px',
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <div className='h-full text-primary-300 overflow-x-hidden'>
          <div className='pt-2 font-extrabold text-primary-300 text-xl font-semibold mb-2 text-left'>
            Add a new person
          </div>
          <div className='mt-6 grid grid-cols-2 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>Name</p>
              <TextInput
                {...register('firstName')}
                hasAutoFocus
                placeholder='Name'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.firstName
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.firstName.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Person type</p>
              <Select
                label='Person type'
                data={personTypes.map(item => {
                  return { key: item.itemId!, value: item.itemText! }
                })}
                onChange={(value) => {
                  setValue('personTypeId', Number(value.key), { shouldDirty: true, shouldValidate: true })
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.personTypeId
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.personTypeId.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Bar number</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                {...register('barNumber')}
                placeholder='Bar number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.barNumber
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.barNumber.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <span className='font-bold text-sm mb-1'>Law firm</span>
              <span className='text-secondary-200'>&nbsp;(optional)</span>
              <TextInput
                {...register('lawFirm')}
                placeholder='Law firm'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.lawFirm
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.lawFirm.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Address 1</p>
              <TextInput
                {...register('address1')}
                placeholder='Address 1'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.address1
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.address1.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Address 2</p>
              <TextInput
                {...register('address2')}
                placeholder='Address 2'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.address2
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.address2.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='mt-6 grid grid-cols-3 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>City</p>
              <TextInput
                {...register('city')}
                placeholder='City'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.city
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.city.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>State</p>
              <Select
                label='State'
                data={states.map((item, idx) => {
                  return { key: idx.toString(), value: item }
                })}
                onChange={(value) => {
                  setValue('state', value.value, { shouldDirty: true, shouldValidate: true })
                }}
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.state
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.state.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>ZIP</p>
              <TextInput
                {...register('zipCode')}
                placeholder='ZIP'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.zipCode
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.zipCode.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div>
            <p className='font-bold text-sm mb-2'>Region</p>
            <Select
              label='Region'
              data={regions.map((item, idx) => {
                return { key: item.itemId ?? idx, value: item.itemText ?? '' }
              })}
              onChange={(value) => {
                setValue('regionId', Number(value.key), { shouldDirty: true, shouldValidate: true })
              }}
            />
            <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
              {errors.regionId
                && (
                  <>
                    <InformationCircleIcon className='h-4 w-4 mr-1' />
                    {errors.regionId.message}
                  </>
                )}
            </span>
          </div>
          <div className='mt-6 grid grid-cols-2 gap-4'>
            <div>
              <p className='font-bold text-sm mb-1'>Email address</p>
              <TextInput
                {...register('email')}
                placeholder='Email'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.email
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.email.message}
                    </>
                  )}
              </span>
            </div>
            <div>
              <p className='font-bold text-sm mb-1'>Phone number</p>
              <TextInput
                {...register('phoneNumber')}
                placeholder='Phone number'
              />
              <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                {errors.phoneNumber
                  && (
                    <>
                      <InformationCircleIcon className='h-4 w-4 mr-1' />
                      {errors.phoneNumber.message}
                    </>
                  )}
              </span>
            </div>
          </div>
          <div className='flex justify-end'>
            <Button className='mt-6 mr-2 h-10 w-1/2' type='submit' disabled={savingContact}>
              {savingContact
                ? <SpinnerSVG className='inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-primary-200' />
                : (
                  <span>
                    Save Person
                  </span>
                )}
            </Button>
          </div>
        </div>
      </form>
      <ModalLeaveWarning
        onClickNo={() => setClosing(false)}
        onClickYes={() => displayModalAction('None')}
        show={isDirty && closing}
      />
    </Modal>
  )
}
