import { Context } from '../presenter'

export function setSideActiveScreen(context: Context, screenName: string): void {
  const screenIndex = context.state.sideNavOptions.findIndex(screen => screen.name === screenName)

  if (screenIndex >= 0) {
    const sideNavOptions = [...context.state.sideNavOptions]
    sideNavOptions.forEach((screen, i) => screen.current = i === screenIndex)
    context.state.sideNavOptions = sideNavOptions
  }
}

export function clearSideActiveScreen(context: Context): void {
  const sideNavOptions = [...context.state.sideNavOptions]
  sideNavOptions.forEach((screen) => screen.current = false)
  context.state.sideNavOptions = sideNavOptions
}

export function setTopActiveScreen(context: Context, screenName: string): void {
  const screenIndex = context.state.topNavOptions.findIndex(screen => screen.name === screenName)

  if (screenIndex >= 0) {
    const topNavOptions = [...context.state.topNavOptions]
    topNavOptions.forEach((screen, i) => screen.current = i === screenIndex)
    context.state.topNavOptions = topNavOptions
  }
}

export function clearTopActiveScreen(context: Context): void {
  const topNavOptions = [...context.state.topNavOptions]
  topNavOptions.forEach((screen) => screen.current = false)
  context.state.topNavOptions = topNavOptions
}
