import * as Yup from 'yup'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const NewInteractionFormValidator = Yup.object().shape({
  contactId: Yup.number().moreThan(0).required('The contact is a required field'),
  interactionDate: Yup.string().required('Date is a required field'),
  methodOfContactId: Yup.number().required('The Method Of Contact is a required field'),
  interactionTypeId: Yup.number().required('The Interaction Type is a required field'),
  areaOfAssistanceId: Yup.number().required('The Area Of Assistance is a required field'),
  cellNumber: Yup.string().nullable().notRequired().matches(phoneRegExp, {
    message: 'Phone number is not valid',
    excludeEmptyString: true,
  }),
  notes: Yup.string().required('The Interaction Notes are a required field'),
  createInteractionTask: Yup.boolean(),
  task: Yup.object().when('createInteractionTask', {
    is: true,
    then: Yup.object().shape({
      name: Yup.string().required('Task name is a required field'),
      dueDate: Yup.string().required('Due Date is a required field'),
      assignedTo: Yup.object().required('Assign To is required field'),
      taskDetails: Yup.string().required('Task details are a required field'),
    }),
  }),
})

export const EditInteractionFormValidator = Yup.object().shape({
  contactId: Yup.number().moreThan(0).required('The contact is a required field'),
  interactionDate: Yup.string().required('Date is a required field'),
  methodOfContactId: Yup.number().required('The Method Of Contact is a required field').moreThan(
    0,
    'Select a method of contact',
  ),
  interactionTypeId: Yup.number().required('The Interaction Type is a required field').moreThan(
    0,
    'Select an interaction type',
  ),
  areaOfAssistanceId: Yup.number().required('The Area Of Assistance is a required field').moreThan(
    0,
    'Select an area of assitance',
  ),
  cellNumber: Yup.string().nullable().notRequired().matches(phoneRegExp, {
    message: 'Phone number is not valid',
    excludeEmptyString: true,
  }),
  notes: Yup.string().required('The Interaction Notes are a required field'),
  createInteractionTask: Yup.boolean(),
  task: Yup.object().when('createInteractionTask', {
    is: true,
    then: Yup.object().shape({
      name: Yup.string().required('Task name is a required field'),
      dueDate: Yup.string().required('Due Date is a required field'),
      assignedTo: Yup.object().required('Assign To is required field'),
      taskDetails: Yup.string().required('Task details are a required field'),
    }),
  }),
})
