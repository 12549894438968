import { Modal } from 'components/molecules'
import { useActions, useAppState } from 'presenter'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const LoadingModal = () => {
  const { currentModal } = useAppState()
  const { displayModalAction } = useActions()

  const onClose = async () => {
    displayModalAction('None')
  }

  return (
    <Modal
      isOpen={currentModal === 'LoadingModal'}
      onClose={onClose}
      shouldCloseOnEsc
      contentStyle={{
        width: '646px',
      }}
    >
      <div className='h-[400px]'>
        <div className='h-full flex flex-col items-center justify-center mx-auto'>
          <SpinnerSVG className='inline mr-2 w-5 h-5 text-gray-200 animate-spin fill-primary-200' />
        </div>
      </div>
    </Modal>
  )
}
