import { InformationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/atoms/Button/Button'
import { Link } from 'components/atoms/Link/Link'
import TextInput from 'components/atoms/TextInput/TextInput'
import { LoginRequest } from 'generated/iTypes'
import { useActions } from 'presenter'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoginFormValidator } from 'validators/usersFormValidator'
import { ReactComponent as SpinnerSVG } from '../../../assets/icons/spinner.svg'

export const Login = () => {
  const { loginUser, redirectAction } = useActions()
  const { formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: {} as LoginRequest,
    resolver: yupResolver(LoginFormValidator),
  })
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className='h-screen bg-bgLightBlue'>
      <div className='flex flex-col items-center justify-center text-primary-300 px-8 py-8 mx-auto md:h-screen lg:py-0 '>
        <div className='w-full bg-white rounded-2xl shadow-xl md:mt-0 sm:max-w-md xl:p-0'>
          <div className='mx-6 my-20 space-y-4 md:space-y-6 sm:p-8'>
            <h1>Sign in</h1>
            <form
              className='space-y-4 md:space-y-6'
              onSubmit={handleSubmit(async (data) => {
                setLoading(true)
                await loginUser(data)
                setLoading(false)
              })}
            >
              <div>
                <label className='block mb-2 text-primary-300 text-sm font-bold'>Username</label>
                <TextInput {...register('userLogin')} />
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.userLogin
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.userLogin.message}
                      </>
                    )}
                </span>
              </div>
              <div>
                <label className='block mb-2 text-primary-300 text-sm font-bold'>Password</label>
                <TextInput type='password' {...register('password')} />
                <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
                  {errors.password
                    && (
                      <>
                        <InformationCircleIcon className='h-4 w-4 mr-1' />
                        {errors.password.message}
                      </>
                    )}
                </span>
              </div>
              <div className='flex items-center justify-between pt-2'>
                <div className='pt-6'>
                  <Link
                    text='I forgot my password'
                    underline
                    onClick={() => redirectAction('/forgotPassword')}
                  />
                </div>
                <div className='flex justify-end'>
                  <Button className='w-28 h-12 items-center' type='submit' disabled={loading}>
                    {!loading
                      ? 'Sign in'
                      : <SpinnerSVG className='inline w-6 h-6 text-gray-200 animate-spin fill-primary-200' />}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
