import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  CalendarIcon,
  ChatIcon,
  CheckCircleIcon,
  ClipboardCheckIcon,
  FilterIcon,
  InformationCircleIcon,
  MinusIcon,
  PhoneIncomingIcon,
  PlusCircleIcon,
  PlusIcon,
  SearchIcon,
  UserAddIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon, PencilIcon } from '@heroicons/react/solid'
import { Accordion } from 'components/atoms/Accordion/Accordion'
import { Button } from 'components/atoms/Button/Button'
import { Card } from 'components/atoms/Card/Card'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import { Link } from 'components/atoms/Link/Link'
import { Pill } from 'components/atoms/Pills/Pills'
import Select from 'components/atoms/Select/Select'
import { Spinner } from 'components/atoms/Spinner/Spinner'

import { Tag } from 'components/atoms/Tag/Tag'
import TextInput from 'components/atoms/TextInput/TextInput'
import { DashboardAlerts, DashboardAlertsIcon } from 'components/molecules'
import { SearchInput } from 'components/molecules/SearchInput/SearchInput'
import { SelectAsignee } from 'components/molecules/SelectAssignee/SelectAssignee'
import Table from 'components/molecules/Table/Table'
import { TaskCard } from 'components/molecules/TaskCard/TaskCard'
import { AvatarColors } from 'entities/enums/AvatarColors'
import { PillStatus } from 'entities/enums/PillStatus'
import { TagColor } from 'entities/enums/TagsColors'
import { InteractionResponse } from 'generated/iTypes'
import { useActions } from 'presenter'
import { useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { PaginationComponent } from 'react-data-table-component/dist/src/DataTable/types'

export const StyleGuide = () => {
  const { loadInteractionDetailsAction, displayModalAction } = useActions()
  const [toggle, setToggle] = useState<boolean>(false)
  const [tags, setTags] = useState<string[]>(['Office Closure', 'CLE', 'Personal'])
  const [currentTag, setCurrentTag] = useState<string>()
  const [fieldsCount, setFieldsCount] = useState<number>(6)
  const [statusSelectAll, setStatusSelectAll] = useState<boolean>(false)
  const [tagsSelectAll, setTagsSelectAll] = useState<boolean>(false)
  const [selectedInteractionId, setSelectedInteractionId] = useState<number>(1)

  const paginationComponent: PaginationComponent = () => {
    return (
      <div className='flex px-2 py-3 pl-48 content-center'>
        <Link
          text='Show More'
          iconright={ArrowDownIcon}
          secondary
          onClick={() => setFieldsCount(prev => prev + prev)}
        />
      </div>
    )
  }

  return (
    <div className='p-4 text-hot-300 pb-96'>
      <h1>Style Guide</h1>
      <hr />
      {/* ------------- Typography ------------- */}
      <h2>Typography</h2>
      <div className='max-w-sm rounded overflow-hidden space-y-2 mb-5'>
        <h1>H1 text</h1>
        <h2>H2 text</h2>
        <h3>H3 text</h3>
        <h4>H4 text</h4>
        <p className='font-bold'>Bold text.</p>
        <p className='font-bold italic'>Bold Italic text.</p>
        <p className='font-medium'>
          Semi bold weight text (relative to the parent element).
        </p>
        <p className='font-medium italic'>
          Italic semi bold weight text (relative to the parent element).
        </p>
        <p>Normal weight text.</p>
        <p className='italic'>Italic Normal weight text.</p>
        <p className='font-light'>Light weight text.</p>
        <p className='text-primary-300 font-light italic'>Italic Light weight text.</p>
        <p className='text-primary-300 font-light italic'>
          Italic Lighter weight text (relative to the parent element).
        </p>
        <p>Paragraph text, no classes</p>
        <p>
          <i>Italic text, no classes</i>
        </p>
      </div>
      {/* ------------- Colors ------------- */}
      <h2>Colors</h2>
      <div className='rounded overflow-hidden space-y-2'>
        <h3>Primary</h3>
        <div>
          <div className='font-bold px-2 py-3 w-full text-white bg-primary-100'>
            Primary 1
          </div>
          <div className='font-bold px-2 py-3 text-white bg-primary-200'>
            Primary 2
          </div>
          <div className='font-bold px-2 py-3 text-white bg-primary-300'>
            Primary 3
          </div>
        </div>
        <h3>Secondary</h3>
        <div className='font-bold px-2 py-3 w-full text-white bg-secondary-100'>
          Secondary Green
        </div>
        <div className='font-bold px-2 py-3 text-white bg-secondary-200'>
          Secondary Ligh Navy
        </div>
        <h3>Background colors / Text</h3>
        <div>
          <div className='font-bold px-2 py-3 text-white bg-black'>Black</div>
          <div className='font-bold px-2 py-3 text-black bg-white'>White</div>
          <div className='font-bold px-2 py-3 text-white bg-textGrey'>
            Text Grey
          </div>
          <div className='font-bold px-2 py-3 text-white bg-outlineLightBlue'>
            Outline Light Blue
          </div>
          <div className='font-bold px-2 py-3 text-black bg-bgLightBlue'>
            BG Light Blue
          </div>
          <div className='font-bold px-2 py-3 text-black bg-lightGrey'>
            Light Grey
          </div>
          <div className='font-bold px-2 py-3 text-white bg-gOverLay'>
            Overlay
          </div>
        </div>
        <h3>Terciary colors</h3>
        <div>
          <div className='font-bold px-2 py-3 text-white bg-tertiary-100'>
            To Do Purple
          </div>
          <div className='font-bold px-2 py-3 text-white bg-tertiary-200'>
            Blocked Orange
          </div>
          <div className='font-bold px-2 py-3 text-white bg-tertiary-300'>
            Delayed Yellow
          </div>
          <div className='font-bold px-2 py-3 text-white bg-tertiary-400'>
            Done Green
          </div>
          <div className='font-bold px-2 py-3 text-white bg-tertiary-500'>
            Past Due Red
          </div>
        </div>
      </div>
      {/* ------------- Buttons ------------- */}
      <h2 className='mb-6'>Buttons</h2>
      <div className=''>
        <div className='grid grid-cols-3 gap-4'>
          <div className=''>
            <p>Primary</p>
            <Button>Primary</Button>
          </div>
          <div className=''>
            <p>Secondary</p>
            <Button color='secondary'>Secondary</Button>
          </div>
          <div className=''>
            <p>Icon Left</p>
            <Button>
              <ArrowLeftIcon className='w-4 h-4 mr-2' />
              Button
            </Button>
          </div>
          <div className=''>
            <p>Primary Disabled</p>
            <Button disabled>Primary Disabled</Button>
          </div>
          <div className='...'>
            <p>Secondary Disabled</p>
            <Button color='secondary' disabled>Secondary Disabled</Button>
          </div>
          <div className='...'>
            <p>Icon Right</p>
            <Button className='mb-5'>
              Button
              <ArrowRightIcon className='w-4 h-4 ml-2' />
            </Button>
            <p>Icon Right & Left</p>
            <Button>
              <ArrowLeftIcon className='w-4 h-4 mr-2' />
              Button
              <ArrowRightIcon className='w-4 h-4 ml-2' />
            </Button>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          <div className='...'>
            <h2 className='mb-6'>Links</h2>
            <p>Add Tag Link</p>
            <Link text='Add tag' iconleft={PlusIcon} />
            <br />
            <p>Icon Link Right</p>
            <Link text='Add tag' iconright={ArrowRightIcon} secondary />
            <br />
            <p>Icon Link Left</p>
            <Link text='Add tag' iconleft={ArrowLeftIcon} secondary />
            <br />
            <p>Text Link, Primary</p>
            <p>
              <Link text='Link' underline />
            </p>
            <br />
            <p>Text Link, Secondary</p>
            <p>
              <Link text='Link' underline secondary />
            </p>
            <br />
            <p>Expand</p>
            <p>
              <Link text='Show More' iconright={ArrowDownIcon} secondary />
            </p>
            <br />
            <p>Collapse</p>
            <Link text='Show Less' iconright={ArrowUpIcon} secondary />
            <br />
          </div>
          <div className='...'>
            <h2>Sizes</h2>
            <p>Large</p>
            <Button size='lg'>
              Button
            </Button>
            <br />
            <p>Medium</p>
            <Button size='md'>
              Button
            </Button>
            <br />
            <p>Small</p>
            <Button size='sm'>
              Button
            </Button>
            <br />
          </div>
          <div className='...'>
            <h2>Misc. Buttons</h2>
            <p>Delete Task Button</p>
            <button className='rounded-md py-2 px-6 text-base font-bold shadow md:shadow-lg"'>
              Delete Task
            </button>
            <p>Close Modal</p>
            <button className='rounded-full bg-primary-300 w-12 h-12 mt-4 ml-4 text-center text-white'>
              <XIcon className='w-7 inline ml-auto mr-auto' />
            </button>
          </div>
        </div>

        {/* ------------- Fields ------------- */}
        <h2 className='mb-6 mt-5'>Forms</h2>
        <h3 className='mb-6'>Fields</h3>
        <div className='grid grid-cols-2 gap-4 w-1/2'>
          <div>
            <p className='font-bold'>Label</p>
            <TextInput
              placeholder='Input'
              maxLength={52}
            />
          </div>
          <div>
            <p className='font-bold'>Helper Text</p>
            <TextInput
              placeholder='Input'
              maxLength={52}
            />
            <span className='flex flex-grow justify-start mt-2 text-primary-300 font-medium text-xs'>
              <InformationCircleIcon className='h-4 w-4 mr-1' /> This is a callout
            </span>
          </div>
          <div>
            <p className='font-bold'>Filled</p>
            <TextInput
              defaultValue='Input'
              maxLength={52}
            />
          </div>
          <div>
            <p className='font-bold'>Error</p>
            <TextInput
              defaultValue='Input'
              error
              maxLength={52}
            />
            <span className='flex flex-grow justify-start mt-2 text-error font-medium text-xs'>
              <InformationCircleIcon className='h-4 w-4 mr-1' /> This is an error
            </span>
          </div>
          <div>
            <p className='font-bold'>Disabled</p>
            <TextInput
              defaultValue='Input'
              disabled
              maxLength={52}
            />
            <br />
            <p className='font-bold'>Filled with icon</p>
            <Select
              label='Input'
              // default={{ key: '1', value: 'input' }}
              data={[{ key: '1', value: '1' }, { key: '2', value: '2' }, { key: '3', value: '3' }]}
              onChange={() => {}}
            />
          </div>
          <div>
            <p className='font-bold'>Active</p>
            <TextInput
              defaultValue='Input'
              hasAutoFocus
              maxLength={52}
            />
          </div>
          <div>
            <p className='font-bold'>Date Range</p>
            <div date-rangepicker className='flex items-center w-full'>
              <div className='relative'>
                <input
                  type='date'
                  className='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                />
              </div>
              <span className='mx-4 text-gray-500'>to</span>
              <div className='relative'>
                <input
                  type='date'
                  className='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                />
              </div>
            </div>
          </div>
        </div>
        <h3 className='mb-6'>Checkboxes</h3>
        <div className='grid grid-cols-2 w-1/2'>
          <div>
            <Checkbox text={''} defaultChecked onChange={(e) => console.log(e)} />
            <br />
            <br />
            <Checkbox text={''} onChange={(e) => console.log(e)} />
          </div>
          <div>
            <Checkbox text={'Checked'} defaultChecked onChange={(e) => console.log(e)} />
            <br />
            <br />
            <Checkbox text={'Unchecked'} onChange={(e) => console.log(e)} />
          </div>
        </div>
      </div>
      {/* ------------- Components - Small ------------- */}
      <h2 className='mb-6 mt-5'>Components - Small</h2>
      <h3 className='mb-6'>Icons</h3>
      <div className='grid grid-cols-7 gap-4 w-1/2 text-primary-100 mb-7'>
        <UserAddIcon className='w-7 h-7 mr-2' />
        <FilterIcon className='w-7 h-7 mr-2' />
        <SearchIcon className='w-7 h-7 mr-2' />
        <ChatIcon className='w-7 h-7 mr-2' />
        <ArrowLeftIcon className='w-7 h-7 mr-2' />
        <ArrowRightIcon className='w-7 h-7 mr-2' />
        <PhoneIncomingIcon className='w-7 h-7 mr-2' />
        <InformationCircleIcon className='w-7 h-7 mr-2' />
        <PlusCircleIcon className='w-7 h-7 mr-2' />
        <CalendarIcon className='w-7 h-7 mr-2' />
        <PencilIcon className='w-7 h-7 mr-2' />
        <CheckCircleIcon className='w-7 h-7 mr-2' />
        <ChevronDownIcon className='w-7 h-7 mr-2' />
        <ClipboardCheckIcon className='w-7 h-7 mr-2' />
      </div>
      <h3 className='mb-6'>Tags</h3>
      <div className='mb-6 w-1/4'>
        <Tag text='Tag' color={TagColor.Green} onClose={() => console.log('close')} />{' '}
        <Tag text='Tag' color={TagColor.Green} />{' '}
        <Tag text='Tag' color={TagColor.Blue} onClose={() => console.log('close')} />{' '}
        <Tag text='Tag' color={TagColor.Blue} />{' '}
        <Tag text='Tag' color={TagColor.Yellow} onClose={() => console.log('close')} />{' '}
        <Tag text='Tag' color={TagColor.Yellow} />{' '}
        <Tag text='Tag' color={TagColor.Purple} onClose={() => console.log('close')} />{' '}
        <Tag text='Tag' color={TagColor.Purple} />
      </div>
      <h3 className='mb-6'>Status Pills</h3>
      <div className='mb-6 grid grid-cols-6 gap-4 w-1/3'>
        <Pill text='To Do' status={PillStatus.ToDo} />
        <Pill text='Blocked' status={PillStatus.Blocked} />
        <Pill text='Delayed' status={PillStatus.Delayed} />
        <Pill text='Done' status={PillStatus.Done} />
        <Pill text='In Progress' status={PillStatus.InProgress} />
      </div>
      <h3 className='mb-6'>Dashboard</h3>
      <div className='mb-6 grid grid-cols-3 gap-4'>
        <DashboardAlerts line1='tasks' line2='due today' value={2} />
        <DashboardAlerts line1='tasks due' line2='this week' value={2} icon={DashboardAlertsIcon.ClipBoard} />
        <DashboardAlerts
          line1='new interactions'
          line2='logged this week'
          value={2}
          icon={DashboardAlertsIcon.UserCollab}
        />
      </div>
      <h3 className='mb-6'>Tasks</h3>
      <div className='mb-6 grid grid-cols-3 gap-4'>
        <div>
          Task Card, small
          <TaskCard
            initials='ML'
            date='08/22'
            title='This is a task name'
            chatCount={9}
          >
            This is more information about this specific task. If there is too much info, this happens
          </TaskCard>
        </div>
        <div>
          Select Assignee
          <SelectAsignee
            initials={[{ initials: 'ML', name: 'Monica Logan' }, {
              initials: 'HD',
              name: 'Hong Dao',
              color: AvatarColors.Yellow,
            }, { initials: 'RE', name: 'Rachel Edwards', color: AvatarColors.Red }]}
          />
        </div>
        <div>
          Change or Add Tag
          <Link text='Add tag' iconleft={toggle ? MinusIcon : PlusIcon} onClick={() => setToggle(!toggle)} />
          {toggle
            && (
              <div className='flex w-full'>
                <div className='block rounded-xl shadow-xl bg-white text-center w-48'>
                  <div className='flex flex-col gap-6 inline py-4 px-4'>
                    <TextInput
                      placeholder='Add new tag'
                      maxLength={52}
                      onChange={(e) => setCurrentTag(e.target.value)}
                      onKeyDown={(e) => {
                        ;(currentTag && e.key === 'Enter') && setTags(previous => [...previous, currentTag])
                      }}
                    />
                    {tags.map((tag, index) => <span key={index} className='text-left text-textGrey'>{tag}</span>)}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {/* ------------- Components - Small ------------- */}
      <h2 className='mb-6 mt-5'>Components - Medium</h2>
      <h3 className='mb-6'>Task Card - Large</h3>
      <div className='mb-6 w-1/2'>
        <TaskCard
          initials={'ML'}
          date={'08/22'}
          size='md'
          title='This is a very long task name to show length'
          pillStatus={PillStatus.InProgress}
          chatCount={2}
          tags={[{ name: 'Office Closure' }, { name: 'test' }]}
          tagColor={TagColor.Green}
        >
          Schedule a meeting with Scott Stevenson sometime next week. If the details are too long, then it will roll
          into ellipses
        </TaskCard>
      </div>
      <h3 className='mb-6'>Search</h3>
      <div className='w-1/2 mb-48'>
        <SearchInput
          data={[
            { itemId: 1, itemText: 'Scott Stevenson   |   #850209   |   Lawyer   |   Portland, OR' },
            { itemId: 2, itemText: 'Scott David | Law Student | Eugene,' },
            { itemId: 3, itemText: ' Scott Smith | #850209 | Lawyer | Salem, OR' },
          ]}
        />
      </div>
      <h3 className='mb-6'>Recent Interactions</h3>
      <div className='w-[499px] mb-6'>
        <Table
          className='w-[499px]'
          columns={tableColumns}
          data={tableData.sort((a, b) => b.interactionDate.getTime() - a.interactionDate.getTime()).slice(
            0,
            fieldsCount,
          )}
          pagination
          paginationPerPage={tableData.length}
          paginationComponent={paginationComponent}
        />
      </div>
      <h3 className='mb-6'>Interaction Details Modal</h3>
      <div className='w-1/2 mb-6'>
        <Button onClick={() => displayModalAction('InteractionDetailsModalSG')}>Open Modal</Button>
      </div>
      <h3 className='mb-6'>Task Filter</h3>
      <div className='w-1/2'>
        {/* Task Filter */}
        <Card>
          <div className='w-[477px]'>
            <div className='py-4 px-4'>
              <div className='w-full pb-2 border-b text-sm text-left font-extrabold'>Date Range</div>
              <div date-rangepicker className='flex items-center pt-2'>
                From
                <div className='pl-2 relative'>
                  <input
                    type='date'
                    className='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5'
                  />
                </div>
                <span className='mx-4'>to</span>
                <div className='relative'>
                  <input
                    type='date'
                    className='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5'
                  />
                </div>
              </div>
              <div className='w-full pt-12 pb-2 border-b text-sm text-left font-extrabold'>Tasks</div>
              <div className='flex grid grid-cols-3 place-items-start pt-2'>
                <Checkbox text={'Main Tasks'} defaultChecked onChange={(e) => console.log(e)} />
                <Checkbox text={'Subtasks'} defaultChecked onChange={(e) => console.log(e)} />
              </div>
              <div className='w-full pt-12 pb-2 border-b text-sm text-left font-extrabold'>Status</div>
              <div className='flex gap-4 pb-1 pt-1'>
                <Link text='Select All' underline onClick={() => setStatusSelectAll(true)} />
                <Link text='Clear All' underline onClick={() => setStatusSelectAll(false)} />
              </div>
              <div className='flex grid grid-cols-3 gap-2 place-items-start pt-2'>
                <Checkbox text={'To Do'} checked={statusSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'Blocked'} checked={statusSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'Delayed'} checked={statusSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'In Progress'} checked={statusSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'Done'} checked={statusSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'Past Due'} checked={statusSelectAll} onChange={(e) => console.log(e)} />
              </div>
              <div className='w-full pt-12 pb-2 border-b text-sm text-left font-extrabold'>Tags</div>
              <div className='flex gap-4 pb-1 pt-1'>
                <Link text='Select All' underline onClick={() => setTagsSelectAll(true)} />
                <Link text='Clear All' underline onClick={() => setTagsSelectAll(false)} />
              </div>
              <div className='flex grid grid-cols-3 place-items-start pt-2'>
                <Checkbox text={'To Do'} checked={tagsSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'Blocked'} checked={tagsSelectAll} onChange={(e) => console.log(e)} />
                <Checkbox text={'Delayed'} checked={tagsSelectAll} onChange={(e) => console.log(e)} />
              </div>
            </div>
          </div>
        </Card>
        {/* End Task Filter */}
      </div>
      <h3 className='mb-6 mt-6'>Task View - Collapsed</h3>
      <div className='w-10/12'>
        <Accordion
          title={
            <div className='flex gap-2'>
              08/24/22<div className='flex h-5 w-5 p-1 rounded-full text-sm font-bold items-center border text-tertiary-300 border-tertiary-300'>
                5
              </div>
            </div>
          }
        >
          <div className='pl-6 flex py-3 grid-cols-5 place-items-center'>
            <TaskCard
              initials='ML'
              date='08/22'
              title='This is a task name'
              chatCount={9}
            >
              This is more information about this specific task. If there is too much info, this happens
            </TaskCard>
            <TaskCard
              initials='ML'
              date='08/22'
              title='This is a task name'
              chatCount={9}
              pillStatus={PillStatus.Done}
              pillText='Done'
            >
              This is more information about this specific task. If there is too much info, this happens
            </TaskCard>
            <TaskCard
              initials='ML'
              date='08/22'
              title='This is a task name'
              chatCount={9}
              pillStatus={PillStatus.Blocked}
              pillText='Blocked'
            >
              This is more information about this specific task. If there is too much info, this happens
            </TaskCard>
            <TaskCard
              initials='ML'
              date='08/22'
              title='This is a task name'
              chatCount={9}
              pillStatus={PillStatus.ToDo}
              pillText='To Do'
            >
              This is more information about this specific task. If there is too much info, this happens
            </TaskCard>
            <TaskCard
              initials='ML'
              date='08/22'
              title='This is a task name'
              chatCount={9}
              pillStatus={PillStatus.Delayed}
              pillText='Delayed'
            >
              This is more information about this specific task. If there is too much info, this happens
            </TaskCard>
          </div>
        </Accordion>
      </div>
      <h3 className='mb-6 mt-6'>Loading</h3>
      <div className='w-1/2'>
        <Spinner />
      </div>
      <h3 className='mb-6'>Interaction Details Modal With Axios</h3>
      <div className='w-24'>
        <p className='font-bold'>InteractionId</p>
        <TextInput
          placeholder='Input'
          value={selectedInteractionId.toString()}
          maxLength={5}
          type='number'
          onChange={(e) => setSelectedInteractionId(Number(e.target.value))}
        />
      </div>
      <Button onClick={() => loadInteractionDetailsAction({})}>Open Modal</Button>
    </div>
  )
}

const tableData = [
  { interactionDate: new Date(), contact: { firstName: 'Scott Stevenson' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'Jen Ramone' }, contactMethod: 'Video Call' },
  { interactionDate: new Date(), contact: { firstName: 'David Robinson' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'Sean Daly' }, contactMethod: 'Video Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese' }, contactMethod: 'Phone Call' },
  { interactionDate: new Date(), contact: { firstName: 'James Reese last' }, contactMethod: 'Phone Call' },
]

const tableColumns: TableColumn<InteractionResponse>[] = [
  {
    cell: ({ interactionDate }) => (
      <button
        className='underline text-primary-100'
        onClick={() => console.log(interactionDate)}
      >
        {interactionDate?.getDate() ?? ''}
      </button>
    ),
    name: 'Date',
    selector: ({ interactionDate }) => interactionDate?.getDate() ?? '',
  },
  {
    name: 'Contact Name',
    selector: ({ contact }) => contact?.firstName ?? '',
  },
  {
    name: 'Contact Method',
    selector: ({ methodOfContact }) => methodOfContact ?? '',
  },
]
