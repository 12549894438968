import { CheckCircleIcon } from '@heroicons/react/outline'
import { Modal } from 'components/molecules'
import { TemplateSelector } from 'components/molecules/TemplateSelector/TemplateSelector'
import { TagAssignment } from 'entities/enums/TagAssignment'
import { SubTaskSelection } from 'entities/types/Tasks'
import { TaskTemplateDetail } from 'generated/iTypes'
import { useActions, useAppState } from 'presenter'
import { useState } from 'react'
import { ModalLeaveWarning } from './ModalLeaveWarning'

export const TemplateSelectionModal = () => {
  const { currentModal, isCreateNewTaskFormDirty, taskStatus } = useAppState()
  const { displayModalAction, setIsCreateNewTaskFormDirty, displayNewTaskFromTemplateModal } = useActions()
  const [closing, setClosing] = useState<boolean>(false)

  const onClose = async () => {
    setClosing(true)
    if (isCreateNewTaskFormDirty) return setIsCreateNewTaskFormDirty(true)
    displayModalAction('None')
  }

  const onCancel = () => displayModalAction('None')

  const handleTemplateConfigured = (template: TaskTemplateDetail, subTasks: SubTaskSelection[]) => {
    const toDoStatus = taskStatus.find(ts => ts.code === 'TD')
    // Create the base task here
    const newTask = {
      name: 'CLONE - ' + template.name,
      templateId: template.id,
      taskDetails: template.taskDetails,
      tags: template.tags?.map(t => ({ ...t, assignmentType: TagAssignment.FromTemplate.valueOf() })),
      subTasks: subTasks.filter(sts => sts.checked).map(sts => ({
        name: sts.subTask.name,
        templateId: sts.subTask.id,
        taskDetails: sts.subTask.taskDetails,
        tags: sts.subTask.tags?.map(t => ({ ...t, assignmentType: TagAssignment.FromTemplate.valueOf() })),
        assignedTo: sts.assignedTo,
        dueDate: new Date(sts.dueDate ?? ''),
        taskStatus: toDoStatus,
      })),
    }
    displayNewTaskFromTemplateModal(newTask)
  }

  return (
    <Modal
      isOpen={currentModal === 'TemplateSelectionModal'}
      shouldCloseOnEsc
      onClose={onClose}
      contentStyle={{
        width: '550px',
      }}
    >
      <div className='h-[calc(100vh-80px)] p-2'>
        <div className='flex items-center mb-4'>
          <CheckCircleIcon className='h-6 w-6' />&nbsp;
          <h3>Create new task</h3>
        </div>
        <TemplateSelector
          onConfigured={handleTemplateConfigured}
        />
      </div>
      <ModalLeaveWarning
        onClickNo={() => setClosing(false)}
        onClickYes={onCancel}
        show={isCreateNewTaskFormDirty && closing}
      />
    </Modal>
  )
}
