import React from 'react'

export function NotFound() {
  return (
    <div className='flex flex-col items-center justify-center pt-20'>
      <div className='text-2xl text-textGrey w-1/2 text-center mt-6 mb-4'>
        Page not found
      </div>
      <div className='flex gap-1 mb-4'>
        <span className='h-4 w-4 rounded-full bg-primary-100'></span>
        <span className='h-4 w-4 rounded-full bg-primary-200'></span>
        <span className='h-4 w-4 rounded-full bg-primary-100'></span>
      </div>
    </div>
  )
}
