import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useState } from 'react'

export type Option = {
  key: string
  value: string
  enum?: any
}

export type SelectProps = {
  data: Option[]
  default?: Option
  label: string
  onChange: (value: Option) => void
  disabled?: boolean
  expFilter?: boolean
  error?: boolean
  selectedValueFontWeight?: string
  customStyle?: string
  hideBorder?: boolean
  useSelected?: boolean
}

export default function Select({
  error = false,
  selectedValueFontWeight = 'font-light',
  ...props
}: SelectProps) {
  const [selected, setSelected] = useState<Option>(
    props.data.find(option => option.key === props.default?.key) ?? { key: '', value: props.label },
  )
  useEffect(() => {
    setSelected(props.data.find(option => option.key === props.default?.key) ?? { key: '', value: props.label })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.default])

  const onChange = (value: Option) => {
    let newValue: Option = value === selected ? { key: '', value: props.label } : value
    setSelected(newValue)
    props.onChange(newValue)
  }

  return (
    <div className={`w-full rounded-lg ${!props.hideBorder && 'border border-secondary-200'}`}>
      <Listbox value={selected} onChange={onChange} disabled={props.disabled}>
        <div className='relative'>
          <Listbox.Button
            name='select'
            className={`relative w-full pl-2 pr-10 text-left cursor-default focus:outline-none sm:text-xs ${
              props.customStyle
                ? props.customStyle
                : ' bg-white rounded-lg focus:text-gray-700 focus:bg-white focus:border-blue-600 py-2.5'
            }`}
          >
            <span
              className={`block truncate ${selectedValueFontWeight} text-base ${
                selected.key === '' && 'text-primary-400'
              }`}
            >
              {selected.value}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <ChevronDownIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-100 rounded-lg shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50'>
              {props.data.map((option) => (
                <Listbox.Option
                  key={option.key}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? 'text-white bg-primary-300' : 'text-gray-900'
                    }`}
                  value={option}
                >
                  {({ selected }) => (
                    <Fragment>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.value}
                      </span>
                      {selected
                        ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-cta-200'>
                            <CheckIcon className='w-5 h-5' aria-hidden='true' />
                          </span>
                        )
                        : null}
                    </Fragment>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
