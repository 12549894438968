import { Transition } from '@headlessui/react'
import { FilterTaskCard } from 'components/molecules/TaskCard/FilterTaskCard'
import { LoopkUps } from 'entities/enums/LoopkUps'
import { EmailOrAddressFormRequest } from 'entities/types/Emails'
import { CheckBoxType } from 'entities/types/Generals'
import { useActions } from 'presenter'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

export const EmailsStateFilter = () => {
  const { getLookUps } = useActions()
  const { setValue } = useFormContext<EmailOrAddressFormRequest>()
  const [show, setShow] = useState<boolean>(false)
  const [state, setState] = useState<CheckBoxType[]>()

  useEffect(() => {
    const getMappedObject = async () => {
      const states = await getLookUps(LoopkUps.States)
      setState(states?.map((item) => {
        return { checked: false, obj: item } as CheckBoxType
      }))
      setShow(true)
    }
    getMappedObject()
  }, [getLookUps])

  const onChange = (newValue: boolean, index: number) => {
    const arr = state?.map((pItem, pIdx) => {
      if (pIdx === index) {
        return { ...pItem, checked: newValue }
      }
      return pItem
    })

    setState(arr)
    if (arr) setValue('obj.state', arr?.filter(f => f.checked).map((item) => item.obj.itemText ?? ''))
  }

  const onSelectAll = (selectedAll: boolean) => {
    const arr = state?.map((item) => {
      return { ...item, checked: selectedAll }
    })

    setState(arr)
    if (arr) setValue('obj.state', arr.filter(f => f.checked).map((item) => item.obj.itemText ?? ''))
  }
  return (
    <Transition
      show={show}
      enter='transition ease-out duration-200'
      enterFrom='opacity-0 translate-y-0'
      enterTo='opacity-100 translate-y-1'
      leave='transition ease-in duration-150'
      leaveFrom='opacity-100 translate-y-1'
      leaveTo='opacity-0 translate-y-0'
    >
      <FilterTaskCard
        title={'State'}
        maxCols='1'
        data={state ?? []}
        onChange={onChange}
        onSelectedAll={() => onSelectAll(true)}
        onDisSelectedAll={() => onSelectAll(false)}
      />
    </Transition>
  )
}
