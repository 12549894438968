import { ChevronDownIcon } from '@heroicons/react/outline'
import { Card } from 'components/atoms/Card/Card'
import { Checkbox } from 'components/atoms/Checkbox/Checkbox'
import { Link } from 'components/atoms/Link/Link'
import { PopMenu } from 'components/atoms/PopMenu/PopMenu'
import { OptedInSelection } from 'entities/types/Person'
import { useState } from 'react'

export interface OptedInSelectorProps {
  onSelectionUpdated?: (selectedOptions: OptedInSelection[]) => void
  selectedOptions?: OptedInSelection[]
}

export const OptedInSelector = (props: OptedInSelectorProps) => {
  const [optedInSelections, setOptedInSelections] = useState<OptedInSelection[]>(props.selectedOptions ?? [])

  const handleSelectAll = (checked: boolean) => {
    const updated = optedInSelections.map(t => ({ optedIn: t.optedIn, checked: checked }))
    setOptedInSelections(updated)
    if (props.onSelectionUpdated) {
      props.onSelectionUpdated(updated)
    }
  }

  const handleStatusSelection = (optedIn: OptedInSelection, checked: boolean) => {
    const updated = optedInSelections.map(t =>
      t.optedIn.itemId === optedIn.optedIn.itemId ? { optedIn: t.optedIn, checked: checked } : t
    )
    setOptedInSelections(updated)
    if (props.onSelectionUpdated) {
      props.onSelectionUpdated(updated)
    }
  }

  return (
    <PopMenu text='Opted in/out status' icon={ChevronDownIcon}>
      <Card>
        <div className='w-full'>
          <div className='py-4 px-4'>
            <div className='flex gap-4 pb-1 pt-1'>
              <Link text='Select all' underline onClick={() => handleSelectAll(true)} />
              <Link text='Clear all' underline onClick={() => handleSelectAll(false)} />
            </div>
            <div className='text-left'>
              {optedInSelections?.map((option, index) => {
                return (
                  <div key={`optedInCheck-${index}`} className='mt-2'>
                    <Checkbox
                      key={`optedInCheck-${index}`}
                      text={option.optedIn.itemText ?? ''}
                      checked={option.checked}
                      onChange={(e) => handleStatusSelection(option, e)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Card>
    </PopMenu>
  )
}
